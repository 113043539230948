import { Text } from 'tusk-ui'
import logoMini from '../../../assets/logoMini.png'
import Theme from '../../../theme'
import { PropsWithMobile, User } from '../../../types'
import { copyTextToClipboard } from '../../../util'
import Box from '../../core/Layout/Box'
import Paper from '../../core/Layout/Paper'
import InfoIcon from '../../icons/InfoIcon'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import { useState } from 'react'

interface Props extends User, PropsWithMobile {
    showCopy?: boolean
    hideTooltip?: boolean
    expiredGiftMember?: boolean
    onAddCard?: () => void
}
const MemberCard = ({
    firstName,
    lastName,
    code,
    mobile,
    mobileSmall,
    tiny,
    showCopy,
    expiredGiftMember,
    onAddCard,
    hideTooltip
}: Props) => {
    const [copied, setCopied] = useState(false)
    return (
        <Box
            maxWidth={`calc(100vw - 40px)`}
            position='relative'
            cursor='pointer'
            onClick={() => {
                copyTextToClipboard(`CNC-${code || '123456'}`)
                setCopied(true)
            }}
        >
            <Paper
                disableShadow
                overflow='hidden'
                position='relative'
                pr={6}
                pl={6}
                pb={4}
                pt={4}
                width={340}
                height={tiny ? 175 : mobileSmall ? 190 : mobile ? 200 : 220}
                maxWidth={`calc(100% - ${Theme.spacing(10)}px)`}
                backgroundColor='Darkest-Blue'
                darkShadow
                opacity={expiredGiftMember ? 0.4 : 1}
            >
                <Box position='relative' zIndex={1}>
                    <Text variant='b3' className='text-snow'>
                        {firstName ? `${firstName} ${lastName}` : 'Your name here'}
                    </Text>
                    <Box pt={1} display='flex'>
                        <Text variant='h3' className='text-snow'>
                            CNC-{code || ''}
                        </Text>
                        {!hideTooltip && (
                            <Box pl={2} flexCenter>
                                <Tooltip
                                    id='jeff to provide copy'
                                    text={`Copy and paste your code at checkout at any of our brand partners to save when shopping sustainably. For in-store redemptions, just show your card to the cashier at checkout!`}
                                    maxWidth='300px'
                                >
                                    <InfoIcon color='White' />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {showCopy ? (
                        <Box display='flex'>
                            {expiredGiftMember ? (
                                <Text variant={'l2'} className='text-snow underline' onClick={onAddCard}>
                                    Add Credit Card
                                </Text>
                            ) : (
                                <Text variant={'l2'} className='text-snow underline'>
                                    {copied ? 'Code Copied' : 'Copy code'}
                                </Text>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
                <Box position='absolute' bottom={20} left={30}>
                    <img src={logoMini} alt='Mammoth Climate Logo' height={32} width={32} />
                </Box>
                <Box
                    position='absolute'
                    left='20%'
                    bottom={0}
                    right={0}
                    top={0}
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                    zIndex={0}
                >
                    <svg width='100%' viewBox='0 0 479 239' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M238.2 256.1C219.7 230.7 208.8 199.4 208.8 165.6C208.8 80.5 277.8 11.5 362.9 11.5C448 11.5 517 80.5 517 165.6C517 200.6 505.3 232.8 485.7 258.7L238.2 256.1Z'
                            fill='#F8CE46'
                        />
                        <path
                            d='M476.7 165.6C476.7 102.8 425.8 51.8 362.9 51.8C300 51.8 249.1 102.7 249.1 165.6C249.1 228.5 305.7 258.7 368.5 258.7C431.3 258.7 476.7 228.4 476.7 165.6Z'
                            fill='#F6B940'
                        />
                        <path
                            d='M-31.9 270.6C3.79998 233 115.7 122 157.3 91.1C160.2 89 165.1 86.2 168.4 84.8C170.7 83.9 180.5 90 186.3 93.9C255.3 140.2 314.9 197.9 362.1 266.2L38.7 268.4L-31.9 270.6Z'
                            fill={Theme.Colors['Middle-Blue']}
                        />
                        <path
                            d='M169.2 84.5C164.4 82.4 164.6 123 183.6 143.8C205.2 167.4 215.3 198.3 199.3 226.3C187.3 247.3 162.1 256.4 142.1 267.8L256 267L299.8 189.8C299.8 189.6 224.6 108.7 169.2 84.5Z'
                            fill={Theme.Colors['Blue']}
                        />
                        <path d='M57.6 239.1L83.9 172.3L108.3 240.3L57.6 239.1Z' fill={Theme.Colors['Blue']} />
                        <path d='M90.8 239L117.1 165.7L141.5 240.3L90.8 239Z' fill={Theme.Colors['Blue']} />
                        <path d='M162 238.9L188.3 159.1L212.7 240.3L162 238.9Z' fill={Theme.Colors['Blue']} />
                        <path d='M134.8 239.2L154.4 179.7L172.5 240.3L134.8 239.2Z' fill={Theme.Colors['Blue']} />
                        <path
                            d='M533.3 268.8C440 272.1 338.5 273.3 171.3 267.4C280.2 186.6 408.8 61.4 533.4 -11L533.3 268.8Z'
                            fill={Theme.Colors['Middle-Blue']}
                        />
                        <path
                            d='M531.9 349.1C531.7 351.2 459.4 340.7 454.3 339.9C415.8 334.1 377.8 328.2 340.5 318.6C326 314.9 286.5 306.9 290.8 290.1C292.5 283.7 301.1 281.6 305.6 276.8C319 262.7 281.3 256 270.1 254.6C238.7 250.6 193.8 247.6 155.6 248.4C145.5 248.6 -32 239.3 -32 240.6L38.5 237.7L533.2 238.1C533.3 238 531.9 349.1 531.9 349.1Z'
                            fill={Theme.Colors['Blue']}
                        />
                        <path
                            d='M230 237.6C235.8 232.4 243 228.8 250.5 227C253.3 226.3 256.4 226 259.2 226.7C262 227.5 264.6 229.6 265.2 232.5C265.1 226.6 267 220.3 271.5 216.5C276 212.7 283.4 212 287.8 216C291.5 219.3 292.3 224.7 292.9 229.6C297.1 226.7 302.7 226.2 307.4 228.2C312 230.2 315.5 234.7 316.2 239.7'
                            fill={Theme.Colors['Blue']}
                        />
                        <path
                            d='M326.7 237.9C330.1 232.1 338 229.6 344.1 232.3C345.4 232.9 346.7 233.7 348.2 233.6C349.9 233.5 351.2 232.3 352.6 231.3C356.2 228.8 361.1 228.3 365.1 230C367.3 230.9 369.6 232.5 371.9 231.9C373.6 231.5 374.8 230 375.9 228.7C381.1 222.6 388 217.5 395.9 216.5C403.9 215.6 412.7 219.9 415 227.6C416.3 224.6 420 223.2 423.3 223.6C426.6 224 429.5 225.6 432.4 227.1C441.9 232 452 235.5 462.5 237.5'
                            fill={Theme.Colors['Blue']}
                        />
                        <path
                            d='M223.7 238.8C227.8 237.3 232.5 237.8 236.6 236.3C237.8 235.9 238.9 235.1 240.1 234.9C243.4 234.2 246.6 236.7 250 237.1C252.7 237.4 255.4 236.4 258 237C259.4 237.3 260.6 238.1 262 238.3C264.4 238.7 266.6 237.4 268.9 236.7C274.1 235.1 280.1 236.7 283.8 240.6'
                            fill={Theme.Colors['Middle-Blue']}
                        />
                        <path
                            d='M361.8 238.4C361.6 237.2 362.4 235.8 363.5 235.4C364.6 235 366.1 235.4 366.9 236.4C368.6 235.6 370.6 235.5 372.3 236.1C372.9 236.3 373.4 236.6 374 236.5C374.6 236.5 375.2 236.1 375.8 235.8C378.6 234 381.5 232.3 384.3 230.5C386 229.5 387.9 228.4 389.8 228.8C392.3 229.3 393.6 232 394.6 234.4C395.3 232.6 398 233 399.6 234.2C401.1 235.4 402.6 237.1 404.5 236.9C406.2 236.7 407.7 235.1 409.3 235.8C410.3 236.3 410.7 237.5 411.7 237.8C412.4 238 413.2 237.7 414 237.6C414.8 237.5 415.7 238.1 415.5 238.8'
                            fill={Theme.Colors['Middle-Blue']}
                        />
                        <path
                            d='M293 241.1C294.8 237.8 298.3 235.4 302 234.8C305.7 234.3 309.7 235.6 312.4 238.2C313.7 236.6 315.9 236.2 317.9 236.7C319.8 237.2 321.5 239.3 323.2 240.5'
                            fill={Theme.Colors['Middle-Blue']}
                        />
                    </svg>
                </Box>
            </Paper>
        </Box>
    )
}

export default MemberCard
