import { matchRoutes } from 'react-router-dom'
import { orgRoutes, withoutNav } from './navigationConstants'

export const isRoute = (location: string, arr: string[]) => {
    const match = matchRoutes(
        arr.map((l: string) => ({ path: l })),
        location
    )
    return !!match && !!match[0]?.route
}

export const isOrgRoute = (location: string) => isRoute(location, orgRoutes)
export const isWithoutNavRoute = (location: string) => isRoute(location, withoutNav)
