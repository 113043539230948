import { forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { Text, Link, Icon } from 'tusk-ui'
import { LinkProps } from '../Link/Link'

export const textLinkVariants = cva('', {
    variants: {
        variant: {
            h5: '',
            h6: '',
            h7: '',
            l1: '',
            l2: '',
            l3: '',
            b3: '',
            b4: ''
        },
        linkVariant: {
            standard: 'hover:underline transition',
            underline: 'underline decoration-2 underline-offset-4',
            arrow: 'group inline-flex items-center gap-8 border-b'
        }
    },
    defaultVariants: {
        linkVariant: 'standard',
        variant: 'b3'
    }
})

export interface TextLinkProps extends LinkProps, VariantProps<typeof textLinkVariants> {}

const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>((props, ref) => {
    const { children, className, variant, linkVariant, ...restProps } = props

    return (
        <Text asChild variant={variant} className={cx(textLinkVariants({ variant, linkVariant }), className)}>
            <Link ref={ref} {...restProps}>
                {children}
                {linkVariant === 'arrow' && (
                    <Icon.ArrowRight
                        className='text-theme-base transition-transform group-hover:translate-x-[6px]'
                        size='sm'
                    />
                )}
            </Link>
        </Text>
    )
})

TextLink.displayName = 'TextLink'

export default TextLink
