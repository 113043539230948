import { Card, Container, Heading, Paragraph, Section, Image, Text, ButtonLink, Markdown, Carousel } from 'tusk-ui'
import { CMSButtonLink, CMSIcon, CMSImage, CMSSection } from 'content/content.types'
import { useTranslation } from 'react-i18next'

interface JobBoard extends CMSSection {
    benefitsHeading: string
    openRolesHeading: string
    benefits: {
        icon: CMSIcon
        title: string
        description: string
    }[]
    teamImage: CMSImage
    roles: {
        department: string
        positions: {
            title: string
            location: string
            applyUrl: string
        }[]
    }[]
    getInTouch: {
        heading: string
        subheading: string
        body: string
        cta: CMSButtonLink
    }
}

export default function JobBoard({
    heading,
    benefitsHeading,
    openRolesHeading,
    benefits,
    teamImage,
    roles,
    getInTouch,
    ...sectionProps
}: JobBoard) {
    const { t } = useTranslation()

    return (
        <Section {...sectionProps}>
            <Container>
                <div className='space-y-64'>
                    {heading && (
                        <Heading.H2 variant='h1'>
                            <Markdown.Heading markdown={heading} />
                        </Heading.H2>
                    )}
                    <Heading.H3 variant='h2'>{benefitsHeading}</Heading.H3>
                    <div className='grid grid-cols-3 gap-24 tablet:grid-cols-2 mobile:hidden'>
                        {benefits.map((benefit, index) => {
                            const IconComponent = benefit.icon
                            return (
                                <Card scheme='snow' key={index} className='space-y-16'>
                                    <div className='inline-block rounded-full bg-gum-100 p-16'>
                                        <IconComponent className='text-gum' />
                                    </div>
                                    <Heading.H4 variant='h7'>{benefit.title}</Heading.H4>
                                    <Paragraph>{benefit.description}</Paragraph>
                                </Card>
                            )
                        })}
                    </div>
                    <Carousel.Root className='hidden mobile:block'>
                        <Carousel.Content>
                            {benefits.map((benefit, index) => {
                                const IconComponent = benefit.icon
                                return (
                                    <Carousel.Item key={index}>
                                        <Card scheme='snow' key={index} className='h-full space-y-16'>
                                            <div className='inline-block rounded-full bg-gum-100 p-16'>
                                                <IconComponent className='text-gum' />
                                            </div>
                                            <Heading.H4 variant='h7'>{benefit.title}</Heading.H4>
                                            <Paragraph>{benefit.description}</Paragraph>
                                        </Card>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel.Content>
                        <Carousel.Indicators />
                    </Carousel.Root>
                </div>
                <Image src={teamImage.src} alt={teamImage.alt} className='my-120 aspect-[16/8] rounded-xl object-top' />
                <div className='space-y-64' id='open-roles'>
                    {roles.length > 0 && (
                        <>
                            <Heading.H3 variant='h2'>{openRolesHeading}</Heading.H3>
                            <div className='grid grid-cols-2 gap-120 tablet:grid-cols-1 tablet:gap-56'>
                                {roles.map((role, index) => {
                                    return (
                                        <div key={index}>
                                            <Heading.H4 variant='h5' className='mb-24'>
                                                {role.department}
                                            </Heading.H4>
                                            {role.positions?.map((position, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='flex items-center justify-between border-t border-night-100 p-16 last:border-b'
                                                    >
                                                        <div>
                                                            <Text variant='h7' className='mb-4'>
                                                                {position.title}
                                                            </Text>
                                                            <Text>{position.location}</Text>
                                                        </div>
                                                        <ButtonLink
                                                            variant='secondary'
                                                            size='sm'
                                                            target='_blank'
                                                            className='mobile:w-auto'
                                                            to={position.applyUrl}
                                                        >
                                                            {t('job_board.apply_btn')}
                                                        </ButtonLink>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                    <Card scheme='snow' className='space-y-40' padding='lg'>
                        <Heading.H2>{getInTouch.heading}</Heading.H2>
                        <div>
                            <Heading.H3 variant='h5' className='mb-8'>
                                {getInTouch.subheading}
                            </Heading.H3>
                            <Paragraph>{getInTouch.body}</Paragraph>
                        </div>
                        <ButtonLink to={getInTouch.cta.href} variant={getInTouch.cta.variant}>
                            {getInTouch.cta.text}
                        </ButtonLink>
                    </Card>
                </div>
            </Container>
        </Section>
    )
}
