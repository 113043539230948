import { Section, Container, Heading, Paragraph, Image, JumpLinkCard, Markdown, Carousel } from 'tusk-ui'
import { CMSSection, CMSJumpLink, CMSImage } from 'content/content.types'
import Autoplay from 'embla-carousel-autoplay'

interface BigTextHeroProps extends CMSSection {
    body: string
    gallery?: CMSImage[]
    anchorLink?: CMSJumpLink
}

export default function BigTextHero({ heading, body, gallery, anchorLink, ...sectionProps }: BigTextHeroProps) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container>
                <Heading.H1 variant='d1' className='mb-32 mobile:mb-24'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H1>
                <div className='flex items-center gap-40 mobile:flex-col'>
                    <Paragraph variant='b1' className='text-snow-300'>
                        {body}
                    </Paragraph>
                    {anchorLink && (
                        <JumpLinkCard
                            layout='horizontal'
                            to={anchorLink.href}
                            IconComponent={anchorLink.icon}
                            className='bg-bay-50 px-48 mobile:w-full'
                        >
                            {anchorLink.text}
                        </JumpLinkCard>
                    )}
                </div>
            </Container>
            {gallery && (
                <Container className='mt-64 pr-0 tablet:pr-0 mobile:pr-0'>
                    <Carousel.Root
                        plugins={[
                            Autoplay({
                                delay: 3000
                            })
                        ]}
                    >
                        <Carousel.Content>
                            {gallery.map((image, index) => (
                                <Carousel.Item key={index} className='basis-[40%]'>
                                    <Image className='rounded-xl' key={index} src={image.src} alt={image.alt} />
                                </Carousel.Item>
                            ))}
                        </Carousel.Content>
                    </Carousel.Root>
                </Container>
            )}
        </Section>
    )
}
