import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Section, Heading, Paragraph, ButtonLink, Image, Container, Markdown } from 'tusk-ui'
import { CMSButtonLink, CMSImage, CMSSection } from 'content/content.types'
import { useInterval } from 'react-use'
import { cx } from 'tusk-ui/utils'

interface HomepageHero extends CMSSection {
    subheading: string
    buttons: CMSButtonLink[]
    image: CMSImage
    cards: CMSImage[]
}

const SkeletonScreen = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' className='h-24 w-full' viewBox='0 0 221 20' fill='none'>
            <path
                d='M0 4C0 1.79086 1.79086 0 4 0H51.25C53.4591 0 55.25 1.79086 55.25 4C55.25 6.20914 53.4591 8 51.25 8H4C1.79086 8 0 6.20914 0 4Z'
                fill='#E3E1DF'
            />
            <path
                d='M0 16C0 13.7909 1.79086 12 4 12H217C219.209 12 221 13.7909 221 16C221 18.2091 219.209 20 217 20H4C1.79086 20 0 18.2091 0 16Z'
                fill='#E3E1DF'
            />
        </svg>
    )
}

export default function HomepageHero({ heading, subheading, buttons, image, cards, ...sectionProps }: HomepageHero) {
    const [items, setItems] = useState<CMSImage[]>(cards)

    useInterval(() => {
        setItems((currItems) => {
            const newItems = [...currItems]
            newItems.push(newItems.shift()!)
            return newItems
        })
    }, 5000)

    return (
        <Section py='sm' {...sectionProps}>
            <Container>
                <div className='grid grid-cols-2 gap-80 tablet:grid-cols-1 tablet:gap-48 mobile:gap-40'>
                    <div className=''>
                        <Heading.H1 variant='d2' className='mb-32 mobile:mb-24'>
                            <Markdown.Heading markdown={heading} />
                        </Heading.H1>
                        <Paragraph variant='b2' className='mb-48 text-snow-300 mobile:mb-32 mobile:text-base'>
                            {subheading}
                        </Paragraph>
                        <div className='flex gap-24 mobile:flex-col'>
                            {buttons.map((btn, i) => (
                                <ButtonLink key={i} to={btn.href} variant={btn.variant}>
                                    {btn.text}
                                </ButtonLink>
                            ))}
                        </div>
                    </div>
                    <div className='relative flex w-full items-center mobile:hidden'>
                        <div className='absolute h-full w-full pr-56 mobile:pr-0'>
                            <Image
                                className='aspect-1/1 h-full items-center rounded-xl mobile:min-h-[300px] mobile:max-w-none'
                                alt={image.alt}
                                src={image.src}
                            />
                        </div>
                        <div className='top-0 flex h-full min-h-[400px] w-full items-center justify-end tablet:my-48 mobile:justify-center'>
                            <div className=''>
                                <AnimatePresence mode='popLayout'>
                                    {items.map((card, i) => {
                                        const key = `${card.alt}${card.src}`
                                        const classes = cx('relative overflow-hidden rounded-xl bg-snow shadow')
                                        switch (i) {
                                            case 0: {
                                                return (
                                                    <motion.div
                                                        key={key}
                                                        layout
                                                        transition={{ type: 'tween', duration: 0.5 }}
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1.1, opacity: 1 }}
                                                        exit={{
                                                            y: -200,
                                                            opacity: 0,
                                                            scale: 0.9
                                                        }}
                                                        className={cx(classes, 'z-10 mb-[20px]')}
                                                    >
                                                        <Image alt={card.alt} src={card.src} />
                                                    </motion.div>
                                                )
                                            }
                                            case 1: {
                                                return (
                                                    <motion.div
                                                        key={key}
                                                        layout
                                                        transition={{ type: 'tween', duration: 0.5 }}
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        className={cx(classes, 'mb-8 h-[115px]')}
                                                    >
                                                        <Image alt={card.alt} src={card.src} />
                                                        <div className='absolute bottom-0 z-10 h-full w-full rounded-xl bg-gradient-to-t from-snow from-10% to-snow/10' />
                                                    </motion.div>
                                                )
                                            }
                                            case 2: {
                                                return (
                                                    <motion.div
                                                        key={key}
                                                        layout
                                                        transition={{ type: 'tween', duration: 0.5 }}
                                                        initial={{ y: 100, scale: 0.8, opacity: 0 }}
                                                        animate={{ y: 0, scale: 0.8, opacity: 1 }}
                                                        className={cx(classes, 'rounded-lg px-16 py-8')}
                                                    >
                                                        <SkeletonScreen />
                                                    </motion.div>
                                                )
                                            }
                                            default:
                                                return null
                                        }
                                    })}
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
