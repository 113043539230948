import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { Text } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export interface StatProps extends ComponentPropsWithoutRef<typeof Text> {
    value: number | string
    metric: string
    smallMetric?: boolean
}

const Stat = forwardRef<HTMLHeadingElement, StatProps>((props, ref) => {
    const { smallMetric, className, value, metric, variant = 'd1', ...restProps } = props
    return (
        <Text
            ref={ref}
            variant={variant}
            className={cx({ 'flex items-center': !!smallMetric }, className)}
            {...restProps}
        >
            {value}
            {smallMetric ? (
                <Text variant='b4' asChild className='ml-4 font-normal'>
                    <span>{metric}</span>
                </Text>
            ) : (
                metric
            )}
        </Text>
    )
})

export default Stat
