export enum Frequency {
    TwicePerWeek = 'TwicePerWeek',
    OncePerWeek = 'OncePerWeek',
    OnceEveryTwoWeeks = 'OnceEveryTwoWeeks',
    OnceEveryThreeWeeks = 'OnceEveryThreeWeeks',
    OnceEveryFourWeeks = 'OnceEveryFourWeeks'
}

export enum Time {
    '9:00 AM' = '9:00 AM',
    '10:00 AM' = '10:00 AM',
    '11:00 AM' = '11:00 AM',
    '12:00 PM' = '12:00 PM',
    '1:00 PM' = '1:00 PM',
    '2:00 PM' = '2:00 PM',
    '3:00 PM' = '3:00 PM',
    '4:00 PM' = '4:00 PM'
}

export enum WorkDay {
    Monday = 'Mon',
    Tuesday = 'Tue',
    Wednesday = 'Wed',
    Thursday = 'Thu',
    Friday = 'Fri'
}

export type FrequencySettings = {
    frequency: Frequency
    weekdays: WorkDay[]
    time: Time
    timezone: string
}

type OnboardingFrequencySettingsModel = {
    personalizedFrequencySettings: FrequencySettings
}

export default OnboardingFrequencySettingsModel
