import { ComponentPropsWithoutRef, ReactNode } from 'react'
import { CMSButtonLink, CMSImage } from 'content/content.types'
import { ContentSection } from './'
import { Card, IconList, Image, Heading, Paragraph } from 'tusk-ui'

function InfoCard({
    image,
    heading,
    description,
    items,
    children,
    primary
}: {
    image?: CMSImage
    heading: string
    description: string
    children?: ReactNode
    items?: ComponentPropsWithoutRef<typeof IconList>['items']
    primary?: boolean
}) {
    return (
        <Card padding='lg' scheme='night'>
            <div className='flex gap-96 tablet:flex-col tablet:gap-40'>
                {image && <Image src={image.src} alt={image.alt} className='mx-auto max-w-[200px] object-contain' />}
                <div className='flex flex-col justify-center'>
                    <div>
                        <Heading.H3 variant={primary ? 'h2' : 'h3'} className='mb-24'>
                            {heading}
                        </Heading.H3>
                        <Paragraph variant={primary ? 'b3' : 'b3'} className='mobile:text-lg'>
                            {description}
                        </Paragraph>
                    </div>
                    {children}
                    {items && <IconList items={items} className='mt-40' />}
                </div>
            </div>
        </Card>
    )
}

type InfoCardGrid = {
    heading: string
    cta: CMSButtonLink
    card: ComponentPropsWithoutRef<typeof InfoCard>
    subCards?: {
        heading: string
        description: string
    }[]
}

export default function InfoCardGrid({ heading, subCards, cta, card }: InfoCardGrid) {
    return (
        <ContentSection heading={heading} cta={cta}>
            <InfoCard primary {...card} />
            {subCards && (
                <div className='grid grid-cols-2 gap-64 mobile:grid-cols-1'>
                    {subCards.map((card, index) => (
                        <InfoCard key={index} heading={card.heading} description={card.description} />
                    ))}
                </div>
            )}
        </ContentSection>
    )
}
