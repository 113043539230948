import { Tooltip } from '../../index'
import styled from 'styled-components'
import { BrandValue as BrandValueType } from '../../../types'
import { useState } from 'react'
import Theme from '../../../theme'
import Box from '../../core/Layout/Box'
import { Text } from 'tusk-ui'
import { getEntityFilterKey } from '../../../util'

const Image = styled.img`
    object-fit: contain;
`
type BrandValueProps = {
    plus?: {
        count: number
        text: string
    }
    brandId?: string
    openTooltip?: () => void
    closeTooltip?: () => void
}

export function BrandValueCore({ attributes, plus, show }: BrandValueType & BrandValueProps & { show: boolean }) {
    return (
        <Box
            height={40}
            width={40}
            flexCenter
            borderRadius='50%'
            style={{
                backgroundColor: plus ? Theme.Colors['Pink'] : attributes?.Color,
                border: `2px solid ${show ? '#000' : plus ? Theme.Colors['Pink'] : attributes?.Color}`,
                transition: 'border 100ms ease-in-out'
            }}
        >
            {plus ? (
                <Box flexCenter>
                    <Text>+{plus.count}</Text>
                </Box>
            ) : (
                <Image
                    alt={attributes?.Icon?.data?.attributes?.alternativeText}
                    src={attributes?.Icon?.data?.attributes?.url}
                />
            )}
        </Box>
    )
}

function BrandValue({
    attributes,
    plus,
    id,
    brandId,
    openTooltip,
    closeTooltip,
    onClickBrandValue
}: BrandValueType & BrandValueProps & { onClickBrandValue?: () => void }) {
    const filterKey = getEntityFilterKey()
    const [show, setShow] = useState(false)
    const k = `${brandId}-${id}`
    return (
        <Box p={1} display='inline-flex' onClick={onClickBrandValue}>
            <Tooltip
                text={plus ? plus.text : attributes[filterKey]}
                id={k}
                key={k}
                onOpen={() => {
                    setShow(true)
                    if (openTooltip) openTooltip()
                }}
                onClose={() => {
                    setShow(false)
                    if (closeTooltip) closeTooltip()
                }}
            >
                <BrandValueCore attributes={attributes} plus={plus} id={id} brandId={brandId} show={show} />
            </Tooltip>
        </Box>
    )
}
export default BrandValue
