import { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Section, Container, Heading, Image, Paragraph, Markdown } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSImage, CMSSection } from 'content/content.types'
import { useWindowSize } from 'react-use'

interface ThreeCategoriesCardProps extends CMSSection {
    items: {
        title: string
        description: string
        image: CMSImage
    }[]
}

export default function ThreeCategoriesCard({ heading, items, ...sectionProps }: ThreeCategoriesCardProps) {
    const [activeIndex, setActiveIndex] = useState(0)
    const { width: _width } = useWindowSize()
    const gallery = useRef<HTMLDivElement | null>(null)
    const galleryH = gallery?.current?.offsetHeight || 0

    return (
        <Section scheme='river' {...sectionProps}>
            <Container>
                <div className='grid grid-cols-2 gap-120 tablet:gap-48 mobile:grid-cols-1'>
                    <div>
                        <div>
                            {heading && (
                                <Heading.H2 variant='h1' className='mb-40'>
                                    <Markdown.Heading markdown={heading} />
                                </Heading.H2>
                            )}
                        </div>
                        {items.map((item, index) => (
                            <div
                                key={`${item.title}${index}`}
                                className={cx('w-full border-b border-snow-300 py-40 last:border-none')}
                                onMouseOver={() => {
                                    if (activeIndex != index) {
                                        setActiveIndex(index)
                                    }
                                }}
                            >
                                <Heading.H3
                                    variant='h3'
                                    className={cx('mobile:[&_u]:decoration-transparent mb-16', {
                                        '[&_u]:decoration-transparent': activeIndex !== index
                                    })}
                                >
                                    <u>{item.title}</u>
                                </Heading.H3>
                                <Paragraph>{item.description}</Paragraph>
                            </div>
                        ))}
                    </div>
                    <div ref={gallery} className='aspect-2/3 overflow-hidden rounded-xl mobile:hidden'>
                        <motion.div animate={{ y: -(galleryH * activeIndex) }} transition={{ type: 'tween' }}>
                            {items.map((item) => (
                                <Image
                                    src={item.image.src}
                                    key={item.image.src}
                                    alt={item.image.alt}
                                    className='aspect-2/3 h-full'
                                />
                            ))}
                        </motion.div>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
