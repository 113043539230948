import { createContext, useContext, useState } from 'react'
import { PropsWithChildren, User } from '../types'

export type OrgOnboardingState = {
    note: string
    manualEmails: string[]
    csvEmails: string[] | null
    file?: File | null
    user: User
}
export const defaultState: OrgOnboardingState = {
    note: '',
    manualEmails: [''],
    csvEmails: [],
    file: undefined,
    user: {} as User
}

export const OrgOnboardingStateContext = createContext(defaultState)
export const OrgOnboardingDispatchContext = createContext((() => {}) as (user: OrgOnboardingState) => void)

export const useOrgOnboardingState = () => useContext(OrgOnboardingStateContext)
export const useSetOrgOnboardingState = () => useContext(OrgOnboardingDispatchContext)

export const OrgOnboardingProvider = (props: PropsWithChildren) => {
    const [orgOnboardingState, setOrgOnboardingState] = useState(defaultState)

    return (
        <OrgOnboardingStateContext.Provider value={orgOnboardingState}>
            <OrgOnboardingDispatchContext.Provider value={setOrgOnboardingState}>
                {props.children}
            </OrgOnboardingDispatchContext.Provider>
        </OrgOnboardingStateContext.Provider>
    )
}
