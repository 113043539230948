import Loader, { LoaderProps } from './Loader'
import { BoxProps, ThemeColors } from '../../../types'
import { useDevice } from '../../../contexts'
import Box from '../../core/Layout/Box'
import {
    BeatLoader as BeatL,
    ScaleLoader as ScaleL,
    BounceLoader as BounceL,
    ClipLoader as ClipL
} from 'react-spinners'
import Theme from '../../../theme'

export function BeatLoader({ color = 'Darkest-Blue', size = 10 }: Props) {
    return <BeatL size={size} color={Theme.Colors[color]} />
}
type Props = {
    color?: ThemeColors
    size?: number
}
export function ScaleLoader({ color = 'Dark-Purple' }: Props) {
    return <ScaleL color={Theme.Colors[color]} />
}

export function BounceLoader({ color = 'Middle-Blue', size = 51 }: Props) {
    return <BounceL size={size} color={Theme.Colors[color]} />
}

export function ClipLoader({ color = 'Middle-Blue', size = 51 }: Props) {
    return <ClipL size={size} color={Theme.Colors[color]} />
}

function Spinner(props: LoaderProps) {
    return <Loader {...props} />
}

function SpinnerPage(props: BoxProps) {
    const { height } = useDevice()
    return (
        <Box height={`${height}px`} flexCenter {...props}>
            <Loader />
        </Box>
    )
}

export { SpinnerPage }

export default Spinner
