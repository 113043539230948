import React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cx } from 'tusk-ui/utils'
import { Icon } from 'tusk-ui'
import './Accordion.css'

const AccordionRoot = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ className, ...props }, ref) => <AccordionPrimitive.Root ref={ref} className={cx('', className)} {...props} />)
AccordionRoot.displayName = AccordionPrimitive.Root.displayName

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => <AccordionPrimitive.Item ref={ref} className={cx(className)} {...props} />)
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className='flex'>
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cx('group flex flex-1 items-center justify-between py-24', className)}
            {...props}
        >
            {children}
            <Icon.ChevronDown className='transition-transform duration-200 group-data-[state=open]:rotate-180' />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={cx(
            'overflow-hidden pb-24 transition-all',
            'data-[state=closed]:accordion-animate-close data-[state=open]:accordion-animate-open',
            className
        )}
        {...props}
    >
        {children}
    </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { AccordionRoot as Root, AccordionItem as Item, AccordionTrigger as Trigger, AccordionContent as Content }
