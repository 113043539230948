import { Locations } from '../../../Locations'

export const orgRoutes = [
    Locations.OrgDashboard(),
    Locations.OrgEditCompany(),
    Locations.OrgTeamManagement(),
    Locations.OrgPayments(),
    Locations.TeamsInstructions(),
    Locations.ConnectChatApp(),
    Locations.Receipt(),
    Locations.AdminChallenges(),
    Locations.AdminChallenge()
]

export const withoutNav = [
    Locations.Checkout(),
    Locations.Calculator(),
    Locations.ReCalculateD2c(),
    Locations.Membership(),
    Locations.Plan(),
    Locations.UpdatePlan(),
    Locations.ClimateJourney(),
    Locations.SharedClimateJourney()
]

export const withoutFooter = [...withoutNav, Locations.OrgSustainabilitySurvey()]

export const funnelPathnames = [
    Locations.ReCalculateEntry(),
    Locations.UpdatePlan(),
    Locations.Calculator(),
    Locations.Membership(),
    Locations.Plan(),
    Locations.EmployeeInviteLink(),
    Locations.Checkout()
]
