import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Theme from '../../../theme'
import React from 'react'
import { Text } from 'tusk-ui'
import { useAnalytics } from '../../../analytics'
import { TextProps } from 'tusk-ui/components/Text/Text'

const LinkContainer = styled.div<{ disabled?: boolean }>`
    position: relative;
    display: inline-block;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

type LinkUnderlineProps = {
    disabled?: boolean
    white?: boolean
}
const LinkUnderline = styled.span<LinkUnderlineProps>`
    position: absolute;
    bottom: -3px;
    top: 0px;
    right: 0px;
    left: 0px;
    border-bottom: 2px solid ${({ white }) => (white ? '#fff' : '#000000')};
    transition: all 250ms ease-in-out;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    ${({ disabled }) =>
        !disabled
            ? `
                :hover {
                    right: -5px;
                    left: -5px;
                }

                :active {
                    right: -2.5px;
                    left: -2.5px;
                }
            `
            : ''}
`

const TabSquare = styled.div`
    width: 26px;
    height: 26px;
    margin-right: 15px;
    margin-bottom: 7px;
    background: ${Theme.Colors['Beige-2']};
`

interface CoreProps extends React.ButtonHTMLAttributes<HTMLAnchorElement> {
    tab?: boolean
    disableLine?: boolean
    target?: string
    white?: boolean
    variant?: TextProps['variant']
}

interface Props extends CoreProps {
    to?: string
}

export const LinkCore = ({ tab, children, disableLine, white, variant = 'l2', ...rest }: CoreProps) => {
    return (
        <LinkContainer {...(rest as JSX.IntrinsicAttributes)}>
            <Text variant={variant}>
                {tab && <TabSquare />}
                {children}
            </Text>
            {!disableLine && <LinkUnderline white={white} disabled={rest.disabled} />}
        </LinkContainer>
    )
}

const Link = ({ to, tab, disableLine, variant, white, ...rest }: Props) => {
    const analytics = useAnalytics()

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        analytics.track('Click', { Type: 'Link', Label: rest.children?.toString() || '' })
        if (rest.onClick) rest.onClick(e)
    }

    return to ? (
        <RouterLink to={to} {...rest} onClick={handleClick}>
            <LinkCore {...rest} tab={tab} disableLine={disableLine} variant={variant} white={white} />
        </RouterLink>
    ) : (
        <LinkCore {...rest} onClick={handleClick} tab={tab} disableLine={disableLine} variant={variant} white={white} />
    )
}
export default Link
