import { cx } from 'tusk-ui/utils'

export default function Spinner({ className }: { className?: string }) {
    return (
        <svg
            className={cx('h-32 w-32 animate-spin-slow', className)}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 200 200'
            fill='none'
        >
            <path
                opacity='0.0833333'
                d='M106 50.72C106 47.0086 103.314 44 100 44C96.6863 44 94 47.0086 94 50.72V61.28C94 64.9914 96.6863 68 100 68C103.314 68 106 64.9914 106 61.28V50.72Z'
                fill='#1B2231'
            />
            <path
                opacity='0.166667'
                d='M129.836 60.3223C131.692 57.1081 130.87 53.1594 128 51.5026C125.13 49.8457 121.3 51.1081 119.444 54.3223L114.164 63.4675C112.308 66.6816 113.13 70.6303 116 72.2872C118.87 73.944 122.7 72.6816 124.556 69.4675L129.836 60.3223Z'
                fill='#F8CE46'
            />
            <path
                opacity='0.25'
                d='M145.678 80.5561C148.892 78.7005 150.154 74.8697 148.497 72C146.841 69.1302 142.892 68.3082 139.678 70.1638L130.533 75.4438C127.318 77.2995 126.056 81.1302 127.713 84C129.37 86.8697 133.318 87.6918 136.533 85.8361L145.678 80.5561Z'
                fill='#F978C2'
            />
            <path
                opacity='0.333333'
                d='M149.28 106C152.991 106 156 103.314 156 100C156 96.6863 152.991 94 149.28 94H138.72C135.009 94 132 96.6863 132 100C132 103.314 135.009 106 138.72 106H149.28Z'
                fill='#D1D3D6'
            />
            <path
                opacity='0.416667'
                d='M139.678 129.836C142.892 131.692 146.841 130.87 148.497 128C150.154 125.13 148.892 121.3 145.678 119.444L136.533 114.164C133.318 112.308 129.37 113.13 127.713 116C126.056 118.87 127.318 122.7 130.533 124.556L139.678 129.836Z'
                fill='#FFF9F5'
            />
            <path
                opacity='0.5'
                d='M119.444 145.678C121.3 148.892 125.13 150.154 128 148.497C130.87 146.841 131.692 142.892 129.836 139.678L124.556 130.533C122.7 127.318 118.87 126.056 116 127.713C113.13 129.37 112.308 133.318 114.164 136.533L119.444 145.678Z'
                fill='#1B2231'
            />
            <path
                opacity='0.583334'
                d='M94 149.28C94 152.991 96.6863 156 100 156C103.314 156 106 152.991 106 149.28V138.72C106 135.009 103.314 132 100 132C96.6863 132 94 135.009 94 138.72V149.28Z'
                fill='#F8CE46'
            />
            <path
                opacity='0.666667'
                d='M70.1639 139.678C68.3082 142.892 69.1303 146.841 72 148.497C74.8698 150.154 78.7005 148.892 80.5562 145.678L85.8362 136.533C87.6918 133.318 86.8698 129.37 84 127.713C81.1303 126.056 77.2995 127.318 75.4439 130.533L70.1639 139.678Z'
                fill='#F978C2'
            />
            <path
                opacity='0.75'
                d='M54.3223 119.444C51.1081 121.3 49.8457 125.13 51.5026 128C53.1594 130.87 57.1081 131.692 60.3223 129.836L69.4675 124.556C72.6816 122.7 73.944 118.87 72.2872 116C70.6303 113.13 66.6816 112.308 63.4675 114.164L54.3223 119.444Z'
                fill='#D1D3D6'
            />
            <path
                opacity='0.833333'
                d='M50.72 94C47.0086 94 44 96.6863 44 100C44 103.314 47.0086 106 50.72 106H61.28C64.9914 106 68 103.314 68 100C68 96.6863 64.9914 94 61.28 94H50.72Z'
                fill='#FFF9F5'
            />
            <path
                opacity='0.916667'
                d='M60.3223 70.1639C57.1081 68.3082 53.1594 69.1303 51.5026 72C49.8457 74.8698 51.1081 78.7005 54.3223 80.5562L63.4675 85.8362C66.6816 87.6918 70.6303 86.8698 72.2872 84C73.944 81.1303 72.6816 77.2995 69.4675 75.4439L60.3223 70.1639Z'
                fill='#1B2231'
            />
            <path
                d='M80.5561 54.3223C78.7005 51.1081 74.8697 49.8457 72 51.5026C69.1302 53.1594 68.3082 57.1081 70.1638 60.3223L75.4438 69.4675C77.2995 72.6816 81.1302 73.944 84 72.2872C86.8697 70.6303 87.6918 66.6816 85.8361 63.4675L80.5561 54.3223Z'
                fill='#F8CE46'
            />
        </svg>
    )
}
