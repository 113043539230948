import { Section, Container, Heading, Paragraph, Image, Markdown } from 'tusk-ui'
import { CMSHeading, CMSImage, CMSSection } from 'content/content.types'

interface ProductHeroProps extends CMSSection {
    subHeading: CMSHeading
    body: string
    image: CMSImage
}

export default function ProductHero({ heading, subHeading, body, image, ...sectionProps }: ProductHeroProps) {
    return (
        <Section py='sm' className='overflow-hidden' {...sectionProps}>
            <Container>
                <div className='flex gap-40 tablet:flex-col mobile:gap-40'>
                    <div className='w-3/5 tablet:w-full'>
                        {subHeading && <Heading.H2 className='mb-32 mobile:mb-24'>{subHeading}</Heading.H2>}
                        <Heading.H1 variant='d1' className='mb-32 text-balance mobile:mb-24'>
                            <Markdown.Heading markdown={heading} />
                        </Heading.H1>
                        <Paragraph variant='b1' className='mb-48 text-snow-300 mobile:mb-32'>
                            {body}
                        </Paragraph>
                    </div>
                    <div className='relative w-2/5 tablet:hidden tablet:h-[500px]'>
                        <Image
                            className='absolute h-full w-[800px] max-w-none object-contain object-left drop-shadow-xl tablet:absolute'
                            alt={image.alt}
                            src={image.src}
                        />
                    </div>
                </div>
            </Container>
            <Container className='hidden gap-64 tablet:flex tablet:pr-0 mobile:flex-col mobile:pr-0'>
                <Image
                    className='ml-auto h-full max-w-[600px] grow object-contain object-right drop-shadow-xl'
                    alt={image.alt}
                    src={image.src}
                />
            </Container>
        </Section>
    )
}
