import { VariantProps, cva } from 'class-variance-authority'
import { ComponentPropsWithoutRef, ComponentType, forwardRef } from 'react'
import { Card, Text, Icon, Link, JumpLink } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

const jumpLinkVariants = cva('block', {
    variants: {
        size: {
            sm: 'px-16 py-8 rounded-lg',
            md: 'px-16 py-32'
        }
    },
    defaultVariants: {
        size: 'md'
    }
})

export interface JumpLinkProps extends ComponentPropsWithoutRef<typeof Link>, VariantProps<typeof jumpLinkVariants> {
    IconComponent?: ComponentType<ComponentPropsWithoutRef<typeof Icon.Base>>
    layout?: 'vertical' | 'horizontal'
    className?: string
    active?: boolean
}

const JumpLinkCard = forwardRef<HTMLAnchorElement, JumpLinkProps>((props, ref) => {
    const { children, className, layout = 'vertical', size, IconComponent, active, ...restProps } = props

    return (
        <Card
            asChild
            padding='flush'
            className={cx(
                'hover:dark-theme group bg-snow-100 transition-colors hover:bg-night',
                {
                    'bg-night-100': active
                },
                jumpLinkVariants({ size }),
                className
            )}
        >
            <JumpLink ref={ref} {...restProps}>
                <div className={cx('flex justify-center', { 'flex-col items-center': layout === 'vertical' })}>
                    {IconComponent && (
                        <IconComponent
                            size='lg'
                            className={cx('group-hover:text-sun', {
                                'mb-16': layout === 'vertical',
                                'mr-16': layout === 'horizontal'
                            })}
                        />
                    )}
                    <div className='flex items-center'>
                        <Text className='whitespace-nowrap underline-offset-4 group-hover:underline' variant='l1'>
                            {children}
                        </Text>
                        <Icon.ArrowDown className='ml-8 w-20 text-night-200 group-hover:text-snow' />
                    </div>
                </div>
            </JumpLink>
        </Card>
    )
})

export default JumpLinkCard
