import { GLOBAL_TAG_HANDLES } from './constants'
import { useGlobalContent } from 'mammoth/hooks/useContent'
import { useEffect, useRef, useState } from 'react'
import { Tag } from 'types'

export const useEventListener = (
    type: string,
    listener: (e: any) => void,
    dependencies: any[] = [],
    target: any = window
) =>
    useEffect(() => {
        target.addEventListener(type, listener)
        return () => target.removeEventListener(type, listener)
    }, [type, listener, target, ...dependencies])

export const useDebounce = (func: (...args: any[]) => void, ms = 1000) => {
    const timeoutRef = useRef<NodeJS.Timeout>()
    return (...args: any[]) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        return new Promise((res) => {
            timeoutRef.current = setTimeout(async () => {
                const result = await func(...args)
                res(result)
            }, ms)
        })
    }
}

export function useField(defaultValue = '' as string) {
    const [val, setVal] = useState(defaultValue)
    const [err, setErr] = useState('')
    return [val, setVal, err, setErr] as [
        string,
        React.Dispatch<React.SetStateAction<string>>,
        string,
        React.Dispatch<React.SetStateAction<string>>
    ]
}

type FileInfo = {
    name: string
    size: number
}

export const useUserUploadFileInfo = (userUploadUrl: string): FileInfo | null => {
    const [fileInfo, setFileInfo] = useState<FileInfo>()

    useEffect(() => {
        const url = userUploadUrl.replace('/user-uploads/', '/user-uploads/file-info/')

        fetch(url, { credentials: 'include' })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                return null
            })
            .then((fileInfo) => {
                setFileInfo(fileInfo)
            })
    }, [userUploadUrl])

    if (!fileInfo) {
        return null
    }

    return {
        name: fileInfo?.name,
        size: fileInfo?.size
    }
}

export const useTags = (includeCustom = true) => {
    const { content: tagContent } = useGlobalContent<Tag[]>('tags')
    const result = tagContent ?? []
    if (!includeCustom) {
        return result.filter((tag) => GLOBAL_TAG_HANDLES.includes(tag.id as (typeof GLOBAL_TAG_HANDLES)[number]))
    }
    return result
}
