import Theme from '../../theme'
import Box from '../core/Layout/Box'
import IconProps from './IconProps'

export type Direction = 'left' | 'right' | 'up' | 'down'

export const getRotation = (direction?: Direction) => {
    switch (direction) {
        case 'right':
            return 'rotate(180deg)'
        case 'up':
            return 'rotate(90deg)'
        case 'down':
            return 'rotate(270deg)'
        default:
            return ''
    }
}

export interface Props extends IconProps {
    direction?: Direction
}
const Arrow = ({ color = 'Ink', direction, width = '14' }: Props) => (
    <Box transform={getRotation(direction)} flexCenter>
        <svg width={width} viewBox='0 0 14 10' fill='none'>
            <path
                d='M13 5H1M1 5L5 1M1 5L5 9'
                stroke={Theme.Colors[color]}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    </Box>
)

export default Arrow
