import { QueryHookOptions, gql } from '@apollo/client'
import USER_FRAGMENT, { ORG_FRAGMENT, STRIPE_FRAGMENT } from './USER_FRAGMENT'
import { Organisation, StripeDetails, User } from '../types'
import { useCustomMutation, useCustomQuery } from '../index'

export const GET_USER = gql`
    query User {
        user {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`
const useUser = (options?: QueryHookOptions) => {
    const { data, ...rest } = useCustomQuery(GET_USER, options)
    return { data: data?.user as User | undefined, ...rest }
}

export const GET_STRIPE = gql`
    query Stripe {
        stripe {
            ...StripeFields
        }
    }
    ${STRIPE_FRAGMENT}
`

export const useStripe = () => {
    const { data, loading, error, refetch } = useCustomQuery(GET_STRIPE)
    return {
        data: data?.stripe as StripeDetails | undefined,
        loading,
        error,
        refetch
    }
}

const GET_USER_TOKEN = gql`
    query UserToken {
        userToken {
            value
        }
    }
`
export const useUserToken = () => {
    const { data, ...rest } = useCustomQuery(GET_USER_TOKEN)
    return { data: data?.userToken?.value as string, ...rest }
}

const GET_NUM_READ_CONTENT = gql`
    query NumReadContent {
        numReadContent
    }
`

export const useNumReadContent = () => {
    const { data, ...rest } = useCustomQuery(GET_NUM_READ_CONTENT)
    return { data: data?.numReadContent as number, ...rest }
}

const GET_NUM_CHALLENGES_COMPLETED = gql`
    query NumChallengesCompleted {
        numChallengesCompleted
    }
`

export const useNumChallengesCompleted = () => {
    const { data, ...rest } = useCustomQuery(GET_NUM_CHALLENGES_COMPLETED)
    return { data: data?.numChallengesCompleted as number, ...rest }
}

const GET_USER_SAVINGS = gql`
    query UserSavings {
        userSavings
    }
`
export const useUserSavings = () => {
    const { data, ...rest } = useCustomQuery(GET_USER_SAVINGS)
    return { data: data?.userSavings as number, ...rest }
}

const SET_CYCLE = gql`
    ${USER_FRAGMENT}
    mutation SetCycle($cycle: Cycle!) {
        setCycle(cycle: $cycle) {
            ...UserFields
        }
    }
`

export const useSetCycle = () => {
    const [setCycle] = useCustomMutation(SET_CYCLE)
    return setCycle
}

const SET_PERCENTAGE = gql`
    ${USER_FRAGMENT}
    mutation setPercentage($percentage: Int!) {
        setPercentage(percentage: $percentage) {
            ...UserFields
        }
    }
`

export const useSetPercentage = () => {
    const [setPercentage] = useCustomMutation(SET_PERCENTAGE)
    return setPercentage
}

const GET_ORG = gql`
    query Org {
        org {
            ...OrgFields
        }
    }
    ${ORG_FRAGMENT}
`
export const useOrg = () => {
    const { data, ...rest } = useCustomQuery(GET_ORG)
    return { data: data?.org as Organisation | undefined, ...rest }
}

export default useUser
