import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ColorScheme, ElementPrimitive } from 'tusk-ui'

const sectionVariants = cva('group/section', {
    variants: {
        py: {
            none: '',
            sm: 'pt-64 pb-64',
            md: 'pt-88 pb-88 tablet:pt-64 tablet:pb-64',
            lg: 'pt-120 pb-120 tablet:pt-88 tablet:pb-88 mobile:pt-64 mobile:pb-64'
        }
    },
    defaultVariants: {
        py: 'lg'
    }
})

interface SectionProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>,
        ComponentPropsWithoutRef<typeof ColorScheme>,
        VariantProps<typeof sectionVariants> {}

const Section = forwardRef<ElementRef<typeof ElementPrimitive.Div>, SectionProps>((props, ref) => {
    const { className, children, py, scheme, ...restProps } = props

    return (
        <ColorScheme
            ref={ref}
            scheme={scheme}
            className={cx(sectionVariants({ py }), className)}
            asChild
            {...restProps}
        >
            <section>{children}</section>
        </ColorScheme>
    )
})

export default Section
