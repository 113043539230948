import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { ElementPrimitive } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export const textVariants = cva('', {
    variants: {
        variant: {
            d1: 'font-secondary text-9xl font-bold leading-[110%] mobile:text-7xl',
            d2: 'font-secondary text-7xl font-bold leading-[110%] mobile:text-6xl',
            d3: 'font-secondary text-6xl font-light leading-[130%] mobile:text-5xl',
            h1: 'font-secondary text-5xl font-bold leading-[130%] mobile:text-4xl',
            h2: 'font-secondary text-4xl font-bold leading-[130%] mobile:text-3xl',
            h3: 'font-secondary text-3xl font-bold leading-[130%] mobile:text-[1.75rem]',
            h4: 'font-secondary text-[1.75rem] font-bold leading-[130%] mobile:text-2xl',
            h5: 'font-primary text-2xl font-bold leading-[130%] mobile:text-xl',
            h6: 'font-primary text-xl font-bold leading-[130%]',
            h7: 'font-primary text-lg font-bold leading-[130%]',
            b1: 'font-primary text-2xl leading-[130%]',
            b2: 'font-primary text-xl leading-[130%] mobile:text-base',
            b3: 'font-primary text-base leading-[150%]',
            b4: 'font-primary text-sm leading-[150%]',
            l1: 'font-primary text-lg font-bold leading-[150%]',
            l2: 'font-primary text-base font-bold leading-[150%]',
            l3: 'font-primary text-sm font-bold leading-[150%]',
            c1: 'font-primary text-xs leading-[150%]'
        }
    },
    defaultVariants: {
        variant: 'b3'
    }
})

type TextVariantProps = VariantProps<typeof textVariants>

export type TextVariants = TextVariantProps['variant']

export interface TextProps extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>, TextVariantProps {}

export const Text = forwardRef<ElementRef<typeof ElementPrimitive.Div>, TextProps>((props, ref) => {
    const { className, variant, ...restProps } = props

    return (
        <ElementPrimitive.Div
            ref={ref}
            className={cx('text-theme-base', textVariants({ variant }), className)}
            {...restProps}
        />
    )
})

export default Text
