import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { Text } from 'tusk-ui'

const headingVariants = cva(
    '[&_u]:underline [&_u]:selection:underline [&_u]:text-decoration-skip-none [&_u]:decoration-gum-200 [&_u]:selection:decoration-gum-200',
    {
        variants: {
            variant: {
                d1: '[&_u]:decoration-[70px] [&_u]:selection:decoration-[70px] [&_u]:underline-offset-[-40px] [&_u]:selection:underline-offset-[-40px]',
                d2: '[&_u]:decoration-[40px] [&_u]:selection:decoration-[40px] [&_u]:underline-offset-[-20px] [&_u]:selection:underline-offset-[-20px]',
                h1: '[&_u]:decoration-[30px] [&_u]:selection:decoration-[30px] [&_u]:underline-offset-[-13px] [&_u]:selection:underline-offset-[-13px]',
                h2: '[&_u]:decoration-[24px] [&_u]:selection:decoration-[24px] [&_u]:underline-offset-[-10px] [&_u]:selection:underline-offset-[-10px]',
                h3: '[&_u]:decoration-[24px] [&_u]:selection:decoration-[24px] [&_u]:underline-offset-[-10px] [&_u]:selection:underline-offset-[-10px]',
                h4: '[&_u]:decoration-[20px] [&_u]:selection:decoration-[20px] [&_u]:underline-offset-[-8px] [&_u]:selection:underline-offset-[-8px]',
                h5: '',
                h6: '',
                h7: ''
            }
        }
    }
)

export interface HeadingProps
    extends Omit<ComponentPropsWithoutRef<typeof Text>, 'variant'>,
        VariantProps<typeof headingVariants> {}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { children, className, ...restProps } = props

    return (
        <Text ref={ref} asChild className={cx(headingVariants({ variant: props.variant }), className)} {...restProps}>
            {children}
        </Text>
    )
})

const H1 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h1', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h1>{children}</h1>
        </Heading>
    )
})

const H2 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h2', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h2>{children}</h2>
        </Heading>
    )
})

const H3 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h3', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h3>{children}</h3>
        </Heading>
    )
})

const H4 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h4', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h4>{children}</h4>
        </Heading>
    )
})

const H5 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h5', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h5>{children}</h5>
        </Heading>
    )
})

const H6 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
    const { variant = 'h6', children, ...restProps } = props

    return (
        <Heading ref={ref} variant={variant} {...restProps}>
            <h6>{children}</h6>
        </Heading>
    )
})

export { H1, H2, H3, H4, H5, H6 }
