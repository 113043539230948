import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    from,
    useMutation,
    DocumentNode,
    useQuery,
    MutationHookOptions,
    QueryHookOptions
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { CMS_URL, BACKEND_URL, GraphqlErrorCodes } from '../constants'
import { getLocale } from 'locales'
import { router } from 'mammoth/MammothRouter'
import { Locations } from 'Locations'
import { GET_USER } from './useUser'
import { errorHasCode } from '../util'

const cmsHttpLink = createHttpLink({ uri: `${CMS_URL}/graphql` })

const cmsAuthLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers
        }
    }
})

const cmsErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            if (message === 'Invalid token.') {
                window.location.reload()
            }
        })
    }
    if (networkError) console.error(`[Network error]: ${networkError}`)
})

export const cmsClient = new ApolloClient({
    link: from([cmsErrorLink, cmsAuthLink, cmsHttpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    brands: {
                        merge(existing = { data: [], meta: {} }, incoming) {
                            return {
                                ...incoming,
                                data: [...existing.data, ...incoming.data]
                            }
                        },
                        keyArgs: false
                    }
                }
            }
        }
    })
})

const customHttpLink = createHttpLink({
    uri: `${BACKEND_URL}/graphql`,
    credentials: 'include'
})

const customAuthLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            'Accept-Language': getLocale()
        }
    }
})

export const customClientCache = new InMemoryCache({
    possibleTypes: {
        User: ['UserCore', 'Lead', 'OrgLead', 'OrgMember', 'Member'],
        Stripe: ['StripeLead', 'StripeMember'],
        ChallengeStep: [
            'ModalChallengeStep',
            'LinkChallengeStep',
            'UploadChallengeStep',
            'QuestionChallengeStep',
            'FinalChallengeStep'
        ]
    },
    typePolicies: {
        DropdownOptionItem: { keyFields: false },
        SurveyV2: { keyFields: ['type'] },
        Query: {
            fields: {
                searchLessons: {
                    merge(existing = { data: [] }, incoming = { data: [] }) {
                        return {
                            ...incoming,
                            data: [...existing.data, ...incoming.data]
                        }
                    },
                    keyArgs: ['filters']
                },
                userLessonsState: {
                    merge(existing = [], incoming = []) {
                        return [...existing, ...incoming]
                    },
                    keyArgs: ['lessonHandles']
                }
            }
        }
    }
})

const customErrorLink = onError((error) => {
    if (errorHasCode(error, GraphqlErrorCodes.UNAUTHENTICATED)) {
        customClientCache.writeQuery({
            query: GET_USER,
            data: { user: null }
        })

        const { pathname, search, hash } = window.location
        const newQueryString = new URLSearchParams({ rtUrl: pathname + search + hash })
        router.navigate({ pathname: Locations.Login(), search: newQueryString.toString() })
    }
})

export const customClient = new ApolloClient({
    link: from([customErrorLink, customAuthLink, customHttpLink]),
    cache: customClientCache
})

export const useCustomMutation = (GQL: DocumentNode, options?: MutationHookOptions) =>
    useMutation(GQL, { ...options, client: customClient })
export const useCustomQuery = (GQL: DocumentNode, options?: QueryHookOptions) =>
    useQuery(GQL, { ...options, client: customClient })
