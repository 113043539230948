import { gql } from '@apollo/client'
import IMAGE_FRAGMENT from './IMAGE_FRAGMENT'

export const STRIPE_FRAGMENT = gql`
    fragment StripeFields on Stripe {
        __typename
        ... on StripeLead {
            cycle
        }
        ... on StripeMember {
            stripePaymentMethodId
            stripeCustomerId
            stripeSubscriptionId
            productId
            priceId
            cycle
            cardDetails {
                brand
                exp_month
                exp_year
                last4
            }
            giftExpiry
        }
    }
`

export const ORG_FRAGMENT = gql`
    ${IMAGE_FRAGMENT}
    fragment OrgFields on Organization {
        id
        name
        percentOff
        memberFee
        billingType
        size
        logoUrl
        features
        site_logo {
            ...ImageFields
        }
        communicationProvider
        slackTeamId
        slackChannelId
        teamsTeamId
        teamsGroupId
        teamsTenantId
        language
        authProvider
    }
`

const USER_FRAGMENT = gql`
    ${STRIPE_FRAGMENT}
    ${ORG_FRAGMENT}
    fragment UserFields on User {
        __typename

        ... on Lead {
            id
            email
            username
            firstName
            lastName
            features
            percentage
            imageUrl
            contry
            stripe {
                ...StripeFields
            }
            provider
            language
            onboardingStep
        }
        ... on OrgLead {
            id
            email
            username
            firstName
            lastName
            features
            percentage
            imageUrl
            contry
            org {
                ...OrgFields
            }
            role
            provider
            language
            onboardingStep
        }
        ... on Member {
            id
            email
            firstName
            lastName
            username
            features
            percentage
            imageUrl
            contry
            code
            stripe {
                ...StripeFields
            }
            footprint
            offset
            contry
            signup_date
            bucket
            provider
            language
            onboardingStep
        }

        ... on OrgMember {
            id
            email
            firstName
            lastName
            username
            features
            percentage
            imageUrl
            contry
            province
            signup_date
            code
            footprint
            workFootprint
            offset
            stripe {
                ...StripeFields
            }
            features
            org {
                ...OrgFields
            }
            role
            team
            bucket
            provider
            language
            onboardingStep
        }
    }
`

export const TEAM_MEMBER_FRAGMENT = gql`
    fragment TeamMemberFields on User {
        __typename
        ... on OrgMember {
            id
            email
            firstName
            lastName
            signup_date
            role
        }
    }
`

export default USER_FRAGMENT
