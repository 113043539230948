import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import { ContextProvider } from './contexts/ContextsProvider'
import { createRoot } from 'react-dom/client'
import { cmsClient, customClientCache, customClient, useCustomMutation, useCustomQuery } from './gql/clients'
import MammothRouter from './mammoth/MammothRouter'
import { setupLocale } from 'locales'
import setupAwsRum from 'setupAwsRum'

export { customClientCache }
export { cmsClient }
export { customClient }
export { useCustomMutation }
export { useCustomQuery }

setupAwsRum()
setupLocale()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <ApolloProvider client={cmsClient}>
        <ContextProvider>
            <MammothRouter />
        </ContextProvider>
    </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
