import { boxShadow, lightShadow } from '../../../theme'
import Box, { BoxProps } from './Box'

interface PaperProps extends BoxProps {
    disableShadow?: boolean
    darkShadow?: boolean
    lightRadius?: boolean
}
const Paper = ({ borderRadius, lightRadius, darkShadow, disableShadow, overflow, ...props }: PaperProps) => (
    <Box
        borderRadius={borderRadius !== undefined ? borderRadius : lightRadius ? 16 : 30}
        backgroundColor='White'
        overflow={overflow || 'hidden'}
        boxShadow={disableShadow ? undefined : darkShadow ? boxShadow : lightShadow}
        {...props}
    />
)

export default Paper
