import { Locations } from 'Locations'
import useLogout from 'gql/useLogout'
import useUser from 'gql/useUser'
import { Navigate } from 'react-router-dom'

export default function Logout() {
    const { refetch } = useUser()
    const [logout] = useLogout()

    logout()
    refetch()

    return <Navigate to={Locations.Home()} />
}
