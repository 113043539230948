import { useSetPopup } from '../../../contexts'
import { Modal } from '../..'
import { withGlobalContent } from 'mammoth/pages/PageContent'
import content from '../../../content/global/en/personalize-your-experience-popup/content'
import useUser from 'gql/useUser'
import { useEffect } from 'react'
import { OnboardingStep } from 'types'
import { ButtonLink, Text, TextLink } from 'tusk-ui'
import { Locations } from 'Locations'
import { useTwDevice } from 'mammoth/hooks/useTwDevice'
import { COUNTRIES_REQUIRING_PROVINCE } from 'mammoth/pages/Onboarding/provinces'

type Props = {
    content: typeof content
}
const PersonalizeYourExperiencePopup = ({ content }: Props) => {
    const setPopup = useSetPopup()

    const { mobile } = useTwDevice()
    const { data: user } = useUser()

    useEffect(() => {
        // Do not show popup to d2c users
        if (!user?.org) {
            return
        }
        // Show popup to any existing users that have not completed onboarding
        if (user?.footprint && user?.onboardingStep !== OnboardingStep.Completed) {
            setPopup({ PERSEONALIZE_YOUR_EXPERIENCE: {} })
        }
    }, [user?.onboardingStep, user?.footprint, user?.org])

    if (!user) {
        return null
    }

    return (
        <Modal
            maxWidth={630}
            Type='PERSEONALIZE_YOUR_EXPERIENCE'
            mobileFullScreen
            contentProps={{ p: 0 }}
            closeOnNavigate={false}
        >
            <div className='overflow-y-auto p-40 pb-0 mobile:p-24 mobile:pb-40'>
                <Text variant='h2' className='pb-40 mobile:pb-24'>
                    {content.heading}
                </Text>
                <Text variant='b2' className='pb-40 mobile:pb-24'>
                    {content.body}
                </Text>

                <img src={content.imageUrl} className='rounded-xl border border-night-100' />
            </div>

            <div className='sticky bottom-0 bg-snow'>
                {mobile && <div className='w-screen divide-y divide-snow-200 rounded-md border border-snow-200' />}

                <div className='flex flex-wrap items-center justify-end gap-20 p-40 mobile:p-24'>
                    <div className='mobile:w-full mobile:text-center'>
                        <TextLink
                            variant='b3'
                            linkVariant='underline'
                            to={Locations.Dashboard()}
                            onClick={() => setPopup(null)}
                        >
                            {content.dashboardLink}
                        </TextLink>
                    </div>
                    <div className='ml-auto mobile:w-full'>
                        <ButtonLink
                            to={Locations.Onboarding(
                                COUNTRIES_REQUIRING_PROVINCE.includes(user.contry) && !user.province
                                    ? OnboardingStep.AccountSettings
                                    : OnboardingStep.Personalization
                            )}
                            variant='primary'
                            className='mobile:w-full'
                        >
                            {content.personalizeLink}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withGlobalContent('personalize-your-experience-popup', PersonalizeYourExperiencePopup)
