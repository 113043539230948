import { ANON_EMAIL, PERMISSIONS, BACKEND_URL } from './constants'
import { Locations } from './Locations'
import { CommunicationProvider, OnboardingStep, Organisation, ThemeColors, User } from './types'
import Theme from './theme'
import { isOrgRoute } from './components/organisms/Navigation/RenderNavigation'
import i18next from 'i18next'
import { getLocale } from 'locales'
import moment from 'moment'

export function chunk(arr: any[], len: number) {
    const chunks: any[] = []
    let i = 0
    const n = arr.length
    while (i < n) chunks.push(arr.slice(i, (i += len)))
    return chunks
}

export function getElemVis(el: any, topBreakPoint = 0, bottomBreakPoint = 0, container = document.body) {
    const { top } = el.getBoundingClientRect()
    const { top: containerTop, bottom: containerBottom } = container.getBoundingClientRect()

    const tooLow = bottomBreakPoint + topBreakPoint + el.clientHeight > containerBottom
    const tooHight = (top > topBreakPoint && containerTop > 0) || containerTop > top
    const inMiddle = !tooHight && !tooLow

    return {
        tooLow,
        tooHight,
        inMiddle
    }
}

export const getCta = (user: User, label = 'Reserve a demo') => {
    if (!isAnon(user)) return { path: Locations.Dashboard(), label: `${user.firstName}` }
    return { path: Locations.ReserveDemo(), label }
}

export const getBeta = () => localStorage.getItem('addedPassword')
export const setBeta = () => localStorage.setItem('addedPassword', 'true')

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidEmail = (email: string) => emailRegex.test(email)

// Phone in format (XXX)-XXX-XXXX
const phoneRegex = /^\(([0-9]{3})\)-([0-9]{3})-([0-9]{4})$/
export const isValidPhoneNumber = (phoneNumber: string) => phoneRegex.test(phoneNumber)

export const getCode = async (id: string) => {
    const { code } = await (await fetch(`${BACKEND_URL}/get-code?userId=${id}`)).json()
    return code
}

export const syncCodes = async () => await (await fetch(`${BACKEND_URL}/sync-all-stores`, { method: 'POST' })).json()

export function copyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea')
    const style = textArea.style
    style.position = 'fixed'
    style.top = '0px'
    style.left = '0px'
    style.width = '2em'
    style.height = '2em'
    style.padding = '0px'
    style.border = 'none'
    style.outline = 'none'
    style.boxShadow = 'none'
    style.background = 'transparent'
    textArea.value = text

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        document.execCommand('copy')
    } catch (err) {
        console.error('Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
}

export const isUserOnboarded = (user: User) => {
    return user.onboardingStep === OnboardingStep.Completed || user.footprint
}

export const isAnon = (user?: User) => {
    if (!user || !user?.id) return true
    if (!user.code) return true
    return user.email === ANON_EMAIL
}

export const isOrgAdmin = (user?: User) => {
    return user?.role === 'admin' || user?.role === 'owner'
}

export const isOrgOwner = (user?: User) => {
    return user?.role === 'owner'
}

export const isEmployee = (user?: User) => {
    return !!user?.org?.id
}

export const getUserPermission = (user?: User) => {
    if (!user) return PERMISSIONS.Public
    if (!user.role) return PERMISSIONS.Member
    switch (user.role) {
        case 'owner':
            return PERMISSIONS.Owner
        case 'admin':
            return PERMISSIONS.Admin
        case 'member':
            return PERMISSIONS.Member
        default:
            return PERMISSIONS.Lead
    }
}

export function themeColor(color: string) {
    const newColor = (color || '').replace('_', '-')
    const { Colors } = Theme
    if (Colors[newColor]) return newColor as ThemeColors
    throw new Error('No color')
}

export const getUrlSearchParams = (search?: string) => {
    const urlSearchParams = new URLSearchParams(search || window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    return params
}

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

export const tonnesToKg = (tonnes: number) => tonnes * 1000
export const toTrees = (tonnes: number) => Math.ceil(tonnesToKg(tonnes) * 0.016535)
export const toCars = (tonnes: number) => Math.ceil(tonnesToKg(tonnes) * 2.5131978)
export const toCoal = (tonnes: number) => Math.ceil(tonnesToKg(tonnes) * 1.10528)

export function arrToMap(arr: any[], itemKey = 'id') {
    const map = {} as { [key: string]: any }
    for (let i = 0; i < arr?.length; i++) {
        const item = arr[i]
        map[item[itemKey]] = item
    }
    return map
}

export const getOrgLink = (token: string) => `${window.location.origin}${Locations.EmployeeInviteLink(token)}`

export default arrToMap

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
export const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

export const formatNumber = (num: number) => {
    const numDecimals = num > 1000 ? 0 : 2
    return numberToLocalString(num, { minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals })
}

export const formatOffset = (tonnes: number) =>
    !tonnes ? '...' : tonnes < 1 ? `${formatNumber(tonnes * 1000)} kg` : `${formatNumber(tonnes)} tonnes`

export function throttle(fn, wait) {
    let time = Date.now()
    return function () {
        if (time + wait - Date.now() < 0) {
            fn()
            time = Date.now()
        }
    }
}

export interface FieldToValidate {
    value: any
    setError: React.Dispatch<React.SetStateAction<string>>
    required?: boolean
    email?: boolean
    phone?: boolean
}
export const validateInputFields = (fields: Array<FieldToValidate>) => {
    let valid = true

    for (const field of fields) {
        if (field.required && !field.value) {
            field.setError('Field is required.')
            valid = false
        } else if (field.email && !isValidEmail(field.value)) {
            field.setError('Email is invalid.')
            valid = false
        } else if (field.phone && !isValidPhoneNumber(field.value)) {
            field.setError('Phone must be in (XXX)-XXX-XXXX format.')
            valid = false
        } else {
            field.setError('')
        }
    }

    return valid
}

export const numberToCurrency = (value: number, options?: Intl.NumberFormatOptions) => {
    const formatter = new Intl.NumberFormat(getLocale(), {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol', // use $ instead of $US for french
        ...options
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    return formatter.format(value)
}

export const numberToLocalString = (value: number, options?: Intl.NumberFormatOptions) => {
    const formatter = new Intl.NumberFormat(getLocale(), {
        ...options
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    return formatter.format(value)
}

export const numberto2Decimals = (value: number) =>
    numberToLocalString(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export type ReplaceValueType = {
    key: string
    value: number | string
}
function replacePreDefinedString(srcString = '', targetValue: ReplaceValueType) {
    return srcString?.replaceAll(targetValue.key, `${targetValue.value}`)
}
export function replacePreDefinedStrings(srcString = '', targetValues: ReplaceValueType | ReplaceValueType[]) {
    if (Array.isArray(targetValues)) {
        for (const value of targetValues) {
            srcString = replacePreDefinedString(srcString, value)
        }
        return srcString
    } else {
        return replacePreDefinedString(srcString, targetValues)
    }
}

export const isExternalLink = (url: string) => {
    const pattern = /^(https?:)?\/\//i // Matches URLs starting with optional http:// or https://
    return pattern.test(url)
}

export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : undefined
}

export const getInitials = (firstName?: string, lastName?: string) => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || ''
    const lastInitial = lastName?.charAt(0).toUpperCase() || ''
    return `${firstInitial}${lastInitial}`
}
// Note: this isn't enough for deeply nested objects, but it's fast for shallow objects
export const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1, Object.keys(obj1).sort()) === JSON.stringify(obj2, Object.keys(obj2).sort())
}

export const formatUserCode = (code: number) => `CNC-${code}`

export const formatFileSize = (bytes: number) => {
    if (bytes < 1024) {
        return bytes + ' b'
    } else if (bytes < 1024 * 1024) {
        return numberto2Decimals(bytes / 1024) + ' kb'
    } else if (bytes < 1024 * 1024 * 1024) {
        return numberto2Decimals(bytes / (1024 * 1024)) + ' mb'
    } else {
        return numberto2Decimals(bytes / (1024 * 1024 * 1024)) + ' gb'
    }
}
export const isDefined = (value: any) => value !== undefined && value !== null

export function getNavMode(user: User | undefined, pathname: string) {
    if (isAnon(user)) {
        return 'anon'
    }
    if (!isEmployee(user)) {
        return 'd2c'
    }
    return isOrgRoute(pathname) ? 'admin' : 'member'
}

export const isEmailOrg = (org: Organisation) => org.communicationProvider === CommunicationProvider.Email

export const isChatAppOrg = (org: Organisation) =>
    org.communicationProvider === CommunicationProvider.Slack ||
    org.communicationProvider === CommunicationProvider.Azure

export const getEntityFilterKey = () => {
    return i18next.language === 'fr' ? 'Name_Fr' : 'Name'
}

export const getOrdinal = (num: number, feminine?: boolean) => {
    const ordinal = moment.localeData().ordinal(num)
    if (getLocale().includes('fr') && feminine) return ordinal.replace('er', 'ère')
    return ordinal
}

export const getIncentiveText = (numPoints: number) =>
    i18next.t('points.incentive', { numPoints: numberToLocalString(numPoints) })

export const errorHasCode = (error: any, code: string) => {
    return Boolean(
        error.graphQLErrors?.some(({ extensions }) => {
            return extensions?.code === code
        })
    )
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
