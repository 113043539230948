import React, { ComponentPropsWithoutRef } from 'react'
import { cx } from 'tusk-ui/utils'
import Input from './Input'
import { InputError } from 'tusk-ui/components/Icon'

interface TextAreaInputProps extends ComponentPropsWithoutRef<typeof Input>, ComponentPropsWithoutRef<'textarea'> {}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
    ({ className, invalid, ...props }, ref) => {
        return (
            <div className='relative h-fit'>
                <Input ref={ref} invalid={invalid} className={cx('', className)}>
                    <textarea {...props} />
                </Input>
                {invalid && (
                    <div className='absolute right-16 top-12 flex'>
                        <InputError className='w-20' />
                    </div>
                )}
            </div>
        )
    }
)

export default TextAreaInput
