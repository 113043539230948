import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { ElementPrimitive, Icon, Text } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export const listVariants = cva('', {
    variants: {
        type: {
            ordered: 'list-disc',
            unordered: 'list-disc',
            icon: ''
        }
    },
    defaultVariants: {
        type: 'unordered'
    }
})

interface ListRootProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Base>,
        VariantProps<typeof listVariants> {}

const ListRoot = forwardRef<HTMLUListElement, ListRootProps>((props, ref) => {
    const { className, children, ...restProps } = props

    return (
        <ElementPrimitive.Base ref={ref} className={cx('space-y-24', className)} {...restProps}>
            <ul>{children}</ul>
        </ElementPrimitive.Base>
    )
})

type ListItemProps = ComponentPropsWithoutRef<typeof ElementPrimitive.Base>

const ListItem = forwardRef<ElementRef<typeof ElementPrimitive.Base>, ListItemProps>((props, ref) => {
    const { className, children, ...restProps } = props

    return (
        <ElementPrimitive.Base ref={ref} className={cx('flex gap-16', className)} {...restProps}>
            <li>{children}</li>
        </ElementPrimitive.Base>
    )
})

const ListText = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>((props, ref) => {
    const { className, children, variant = 'h6', ...restProps } = props

    return (
        <Text ref={ref} className={cx('inline-block', className)} variant={variant} {...restProps}>
            {children}
        </Text>
    )
})

export { ListRoot as Root, ListItem as Item, Icon as ItemIcon, ListText as Text }
