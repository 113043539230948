import React from 'react'

export const Receipts = React.lazy(() => import('./Public/Receipts/Receipts')) // Projects
export const Projects = React.lazy(() => import('./Public/Projects/Projects'))
export const SingleProject = React.lazy(() => import('./Public/Projects/SingleProject'))
export const Brands = React.lazy(() => import('./Public/Brands/BrandsPage'))
export const CancelMembership = React.lazy(() => import('./Members/CancelMembership/CancelMembership'))
export const Calculator = React.lazy(() => import('./OnBoarding/Calculator/Calculator'))
export const ReCalculatePersonal = React.lazy(() => import('./OnBoarding/Calculator/ReCalculatePersonal'))
export const Checkout = React.lazy(() => import('./OnBoarding/Checkout/Checkout'))

// D2C
export const ReCalculateEntry = React.lazy(() => import('./OnBoarding/CalculatorEntry/ReCalculateEntry'))
export const Dashboard = React.lazy(() => import('./Members/Dashboard/Dashboard'))
export const AccountSettings = React.lazy(() => import('./Members/AccountSettings/AccountSettings'))
export const Membership = React.lazy(() => import('./OnBoarding/Membership/Membership'))
export const Plan = React.lazy(() => import('./OnBoarding/Plan/Plan'))
export const UpdatePlan = React.lazy(() => import('./OnBoarding/Plan/UpdatePlan'))

export const Pdf = React.lazy(() => import('./Public/Pdf'))
export const OrgSustainabilitySurvey = React.lazy(() => import('./Public/OrgSustainabilitySurvey'))
export const Receipt = React.lazy(() => import('./Public/Receipt')) // Org payment
export const ClimateJourney = React.lazy(() => import('./Members/ClimateJourney/ClimateJourney'))
export const ClimateJourney404 = React.lazy(() => import('./Members/ClimateJourney/ClimateJourney404'))

export const OrgSignup = React.lazy(() => import('./Public/Auth/OrgAuth/OrgSignup'))
export const OrgDashboard = React.lazy(() => import('./OrgAdmin/OrgDashboard/OrgDashboard'))
export const OrgTeamManagement = React.lazy(() => import('./OrgAdmin/OrgTeamManagement/OrgTeamManagement'))
export const OrgPayments = React.lazy(() => import('./OrgAdmin/OrgPayments'))
export const TeamsInstructions = React.lazy(() => import('./OrgAdmin/OrgContent/TeamsInstructions'))
export const OrgAccount = React.lazy(() => import('./OrgAdmin/OrgAccountSettings/OrgAccount'))
export const ConnectChatApp = React.lazy(() => import('./OrgAdmin/OrgContent/ConnectChatApp'))

export const EmployeeInviteLink = React.lazy(() => import('./Public/WelcomePartnership/EmployeeInviteLink'))
export const ClimateClassPage = React.lazy(() => import('../pages/Members/ClimateClass/ClimateClass'))
export const ClimateClassViewPage = React.lazy(() => import('../pages/Members/ClimateClassView/ClimateClassView'))
export const SocialCallback = React.lazy(() => import('./Public/Auth/SocialCallback'))

export const PointsOverview = React.lazy(() => import('./Members/PointsOverview/PointsOverviewPage'))
export const HowToEarnPointsPage = React.lazy(() => import('./Members/PointsOverview/HowToEarnPointsPage'))
export const PointHistoryPage = React.lazy(() => import('./Members/PointsOverview/PointHistoryPage'))
export const PointRedemptionsPage = React.lazy(() => import('./Members/PointRedemptions/PointRedemptionsPage'))

export const ChallengesPage = React.lazy(() => import('./Members/Challenges/ChallengesPageContainer'))
export const ChallengePage = React.lazy(() => import('./Members/Challenges/ChallengePageContainer'))

export const AdminChallengesManagementPage = React.lazy(
    () => import('../pages/OrgAdmin/OrgChallenges/AdminChallengesManagementPage')
)
export const AdminChallengePage = React.lazy(
    () => import('../pages/OrgAdmin/OrgChallenges/AdminChallengePageContainer')
)
