import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ElementPrimitive } from 'tusk-ui'

const colorSchemeVariants = cva('', {
    variants: {
        scheme: {
            snow: 'light-theme bg-snow',
            river: 'light-theme bg-bay-100',
            smoke: 'light-theme bg-snow-200',
            gray: 'light-theme bg-snow-100',
            night: 'dark-theme bg-night',
            sea: 'dark-theme bg-sea',
            sun: 'light-theme bg-sun-100'
        }
    },
    defaultVariants: {
        scheme: 'snow'
    }
})

interface ColorSchemeProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>,
        VariantProps<typeof colorSchemeVariants> {}

const ColorScheme = forwardRef<ElementRef<typeof ElementPrimitive.Div>, ColorSchemeProps>((props, ref) => {
    const { className, scheme, ...restProps } = props

    return <ElementPrimitive.Div ref={ref} className={cx(colorSchemeVariants({ scheme }), className)} {...restProps} />
})

export default ColorScheme
