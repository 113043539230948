import { useState, useEffect, createRef } from 'react'
import { PropsWithChildren } from '../../types'

interface CollapseProps extends PropsWithChildren, React.CSSProperties {
    open: boolean
    minValue?: number
}

const Collapse = (props: CollapseProps) => <CollapseProp proporty='height' {...props} />

export const CollapseWidth = (props: CollapseProps) => <CollapseProp proporty='width' {...props} />

const CollapseProp = ({
    open,
    children,
    proporty,
    minValue = 0,
    ...rest
}: CollapseProps & { proporty: 'height' | 'width' }) => {
    const ref = createRef<HTMLDivElement>()
    const [p, setP] = useState(minValue)

    useEffect(() => {
        if (!ref || !ref.current) return

        const newP = ref.current[proporty === 'width' ? 'scrollWidth' : 'scrollHeight']
        setP(open ? newP : minValue)
    }, [open, !!ref.current])

    return (
        <div
            ref={ref}
            style={{
                transition: `${proporty} 250ms ease-in-out`,
                overflow: 'hidden',
                [proporty]: p,
                ...rest
            }}
        >
            {children}
        </div>
    )
}

export default Collapse
