import { Accordion, Card, Container, Heading, Section, Markdown } from 'tusk-ui'
import { CMSHeading, CMSMarkdown, CMSSection } from 'content/content.types'

export interface AccordionsWithCardProps extends CMSSection {
    card: {
        heading: CMSHeading
        subheading: CMSMarkdown
    }
    accordionSections: {
        heading: string
        accordions: {
            title: string
            description: CMSMarkdown
        }[]
    }[]
}
export default function AccordionsWithCard({ card, accordionSections }: AccordionsWithCardProps) {
    return (
        <Section className='bg-snow-100'>
            <Container>
                <div className='flex gap-80 tablet:flex-col'>
                    <div className='relative w-[380px] tablet:w-full'>
                        <Card scheme='snow' className='sticky top-72'>
                            <Heading.H3 variant='h4' className='mb-16'>
                                {card.heading}
                            </Heading.H3>
                            <Markdown.RichText markdown={card.subheading} />
                        </Card>
                    </div>
                    <div className='flex-1 space-y-72'>
                        {accordionSections?.map(({ heading, accordions = [] }, i) => (
                            <Accordion.Root key={i} type='single' collapsible>
                                <Heading.H2 variant='h5' className='mb-24'>
                                    {heading}
                                </Heading.H2>
                                {accordions.map(({ title, description }, j) => (
                                    <Accordion.Item key={j} value={`${title}`} className='border-b border-night-100'>
                                        <Accordion.Trigger>
                                            <Heading.H3 variant='h7'>{title}</Heading.H3>
                                        </Accordion.Trigger>
                                        <Accordion.Content>
                                            <Markdown.RichText markdown={description} />
                                        </Accordion.Content>
                                    </Accordion.Item>
                                ))}
                            </Accordion.Root>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
    )
}
