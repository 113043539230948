import styled from 'styled-components'
import Theme from '../../../theme'
import { ThemeColors } from '../../../types'

type Props = {
    backgroundColor?: ThemeColors
    color?: ThemeColors
    textStyle?: 'TEXT_LINK' | 'SMALL_CTA' | 'Small'
}
const DiscountPill = styled.div`
    ${({ textStyle = 'TEXT_LINK' }: Props) => Theme.TextStyles[textStyle]}
    ${Theme.Layout.FlexCenter}
    line-height: initial;
    border-radius: 255px;
    background: ${({ backgroundColor = 'Yellow-2' }: Props) => Theme.Colors[backgroundColor]};
    color: ${({ color = 'Dark-Purple' }: Props) => Theme.Colors[color]};
    display: inline-flex;
    padding: 10px 20px;
    text-align: center;
`

export default DiscountPill
