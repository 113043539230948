import { ThemeColors } from '../../types'
import { Text } from 'tusk-ui'
import Box, { BoxProps } from '../core/Layout/Box'

interface PillProps extends BoxProps {
    backgroundColor: ThemeColors
    textColor?: ThemeColors
}

const Pill = ({ backgroundColor, textColor = 'Ink', children, ...rest }: PillProps) => (
    <Box
        borderRadius={8}
        backgroundColor={backgroundColor}
        display='inline-flex'
        pr={2}
        pl={2}
        pt={0.5}
        pb={0.5}
        {...rest}
    >
        <Text variant='l2' className={textColor === 'White' ? 'text-snow' : ''} color={textColor}>
            {children}
        </Text>
    </Box>
)

export default Pill
