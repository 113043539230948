import Theme from '../../theme'
import IconProps from './IconProps'

const Check = ({ color = 'Dark-Purple', width = 13 }: IconProps) => (
    <svg width={width} viewBox='0 0 13 10' fill='none'>
        <path
            d='M1 5L4.66667 9L12 1'
            stroke={Theme.Colors[color]}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default Check
