/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./src/**/*.{js,ts,jsx,tsx}'],
    theme: {
        colors: {
            sun: {
                DEFAULT: '#F8CE46',
                100: '#FCF0C7',
                200: '#FBE6A2',
                300: '#F0BB00'
            },
            gum: {
                DEFAULT: '#F978C2',
                100: '#FEE4F3',
                200: '#FCBBE1',
                300: '#DB469C'
            },
            night: {
                DEFAULT: '#1B2231',
                100: '#D1D3D6',
                200: '#8D9097',
                300: '#010203'
            },
            snow: {
                DEFAULT: '#FFFFFF',
                100: '#F4F4F5',
                200: '#E3E1DF',
                300: '#767676'
            },
            error: {
                DEFAULT: '#DE3232',
                100: '#FBE6E6',
                200: '#EF9999',
                300: '#C81313'
            },
            success: {
                DEFAULT: '#038557',
                100: '#E1F2EC',
                200: '#81C8AF',
                300: '#016232'
            },
            bay: { DEFAULT: '#1D60D8', 50: '#F4F7FD', 100: '#E8EFFB', 200: '#8DAFEB', 300: '#0045C0' },
            sea: { DEFAULT: '#262D3B', 50: '#939DB8', 100: '#67769C', 200: '#263B72', 300: '#000F2E' }
        },
        borderRadius: {
            sm: '4px',
            md: '8px',
            lg: '16px',
            xl: '24px',
            full: '9999px',
            none: 0
        },
        borderWidth: {
            0: '0',
            DEFAULT: '1px',
            2: '2px',
            4: '4px'
        },
        aspectRatio: {
            '1/1': '1/1',
            '3/2': '3/2',
            '2/3': '2/3',
            '3/4': '3/4',
            '4/3': '4/3',
            '16/9': '16/9',
            '9/16': '9/16'
        },
        fontFamily: {
            primary: ['Lato', 'sans-serif'],
            secondary: ['Cambon', 'serif']
        },
        boxShadow: {
            none: 'none',
            sm: '0px 0px 16px 0px rgba(141, 144, 151, 0.15)',
            DEFAULT: '0px 3px 16px 0px rgba(0, 0, 0, 0.15)',
            lg: '0px 3px 32px 0px rgba(0, 0, 0, 0.4)'
        },
        spacing: {
            0: '0rem',
            1: '0.0625rem',
            2: '0.125rem',
            3: '0.1875rem',
            4: '0.25rem',
            8: '0.5rem',
            12: '0.75rem',
            16: '1rem',
            20: '1.25rem',
            24: '1.5rem',
            32: '2rem',
            40: '2.5rem',
            48: '3rem',
            56: '3.5rem',
            64: '4rem',
            72: '4.5rem',
            80: '5rem',
            88: '5.5rem',
            96: '6rem',
            100: '6.25rem',
            120: '7.5rem'
        },
        extend: {
            backgroundColor: {
                theme: {
                    base: 'var(--color-bg-base)',
                    secondary: 'var(--color-bg-secondary)',
                    tertiary: 'var(--color-bg-tertiary)',
                    inverse: 'var(--color-bg-inverse)',
                    'dark-hover': 'var(--color-bg-dark-hover)'
                }
            },
            textColor: {
                theme: {
                    base: 'var(--color-text-base)',
                    secondary: 'var(--color-text-secondary)',
                    tertiary: 'var(--color-text-tertiary)',
                    inverse: 'var(--color-text-inverse)'
                }
            },
            borderColor: {
                theme: {
                    base: 'var(--color-border-base)',
                    tertiary: 'var(--color-border-tertiary)',
                    'dark-hover': 'var(--color-border-dark-hover)'
                }
            },
            fill: {
                theme: {
                    base: 'var(--color-fill-base)'
                }
            },
            stroke: {
                theme: {
                    base: 'var(--color-stroke-base)'
                }
            },
            ringColor: {
                theme: {
                    base: 'var(--color-ring-base)'
                }
            },
            fontSize: {
                sm: ['0.875rem', '150%'],
                base: ['1rem', '150%'],
                lg: ['1.125rem', '150%'],
                xl: ['1.25rem', '130%'],
                '2xl': ['1.5rem', '130%'],
                '3xl': ['2rem', '130%'],
                '4xl': ['2.5rem', '130%'],
                '5xl': ['3rem', '130%'],
                '6xl': ['3.5rem', '130%'],
                '7xl': ['4rem', '110%'],
                '9xl': ['7.5rem', '110%']
            },
            width: {
                20: '1.25rem',
                100: '6.25rem',
                200: '12.5rem',
                300: '18.75rem',
                400: '25rem'
            },
            height: {
                20: '1.25rem',
                100: '6.25rem',
                200: '12.5rem',
                300: '18.75rem',
                400: '25rem'
            },
            keyframes: {
                ripple: {
                    '0%': {
                        width: '0',
                        height: '0',
                        opacity: '0.5',
                    },
                    '100%': {
                        width: '520px',
                        height: '520px',
                        opacity: '0',
                    },
                }
            },
            animation: {
                'spin-slow': 'spin 2s linear infinite',
            }
        },
        screens: {
            desktop: { max: '1919px' },
            'desktop-sm': { max: '1439px' },
            tablet: { max: '1023px' },
            mobile: { max: '767px' },
            'mobile-min': { max: '359px' }
        }
    },
    plugins: [
        function ({ addUtilities }) {
            addUtilities({
              '.whitespace-inherit': { whiteSpace: 'inherit' },
            })
          }
    ]
}
