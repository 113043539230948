import ReactMarkdown, { Options as MarkdownOptions } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Heading, Paragraph, TextLink } from 'tusk-ui'
import { PluggableList } from 'react-markdown/lib'
import { isExternalLink } from '../../../util'

interface MarkdownProps extends MarkdownOptions {
    markdown: MarkdownProps['children']
}

function MarkdownRichText({ markdown, ...restProps }: MarkdownProps) {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw] as PluggableList}
            components={{
                h1: (props) => <Heading.H1 className='mb-88'>{props.children}</Heading.H1>,
                h2: (props) => <Heading.H2 className='mb-32'>{props.children}</Heading.H2>,
                h3: (props) => <Heading.H3 className='mb-32'>{props.children}</Heading.H3>,
                h4: (props) => <Heading.H4 className='mb-16'>{props.children}</Heading.H4>,
                h5: (props) => <Heading.H5 className='mb-16'>{props.children}</Heading.H5>,
                h6: (props) => <Heading.H6 className='mb-16'>{props.children}</Heading.H6>,
                p: (props) => <Paragraph className='mb-32 last:mb-0'>{props.children}</Paragraph>,
                a: (props) => (
                    <TextLink
                        to={props.href!}
                        target={isExternalLink(props.href!) ? '_blank' : undefined}
                        className='underline'
                    >
                        {props.children}
                    </TextLink>
                ),
                ul: (props) => <ul className='-mt-20 mb-32 ms-32 list-disc [&_p]:mb-0'>{props.children}</ul>,
                ol: (props) => <ol className='-mt-20 mb-32 ms-32 list-decimal [&_p]:mb-0'>{props.children}</ol>,
                li: (props) => <li className='mb-12'>{props.children}</li>,
                img: (props) => {
                    return <img src={props.src} className='rounded-xl' alt={props.alt} />
                },
                blockquote: (props) => {
                    return <blockquote className='border-l-2 border-snow-300 py-4 pl-24 '>{props.children}</blockquote>
                }
            }}
            {...restProps}
        >
            {markdown}
        </ReactMarkdown>
    )
}

export function MarkdownHeading({ markdown, ...restProps }: MarkdownProps) {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw] as PluggableList}
            components={{
                h1: (props) => <Heading.H1 className='mb-88'>{props.children}</Heading.H1>,
                h2: (props) => <Heading.H2 className='mb-32'>{props.children}</Heading.H2>,
                h3: (props) => <Heading.H3 className='mb-32'>{props.children}</Heading.H3>,
                h4: (props) => <Heading.H4 className='mb-16'>{props.children}</Heading.H4>,
                h5: (props) => <Heading.H5 className='mb-16'>{props.children}</Heading.H5>,
                h6: (props) => <Heading.H6 className='mb-16'>{props.children}</Heading.H6>,
                p: (props) => <>{props.children}</>,
                u: (props) => <u>{props.children}</u>
            }}
            {...restProps}
        >
            {markdown}
        </ReactMarkdown>
    )
}

export { MarkdownRichText as RichText, MarkdownHeading as Heading }
