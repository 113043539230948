import NoPermission from 'components/NoPermission'
import { PERMISSIONS } from '../../constants'
import { SpinnerPage } from 'components'
import { Navigate, useLocation } from 'react-router-dom'
import { Locations } from 'Locations'
import { getUserPermission } from '../../util'
import useUser from 'gql/useUser'

export default function Private({
    permission,
    children
}: {
    permission: PERMISSIONS
    children: React.ReactElement | JSX.Element
}) {
    const { data: user, loading } = useUser()
    const { pathname, search, hash } = useLocation()
    const userPermission = getUserPermission(user)
    if (permission === PERMISSIONS.Public) return children
    if (loading) return <SpinnerPage />
    if (!user) {
        const newQueryString = new URLSearchParams({ rtUrl: pathname + search + hash })
        return (
            <Navigate
                to={{
                    pathname: Locations.Login(),
                    search: newQueryString.toString()
                }}
            />
        )
    }
    if (permission > userPermission) return <NoPermission />
    return children
}
