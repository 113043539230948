import { Section, Container, Heading, Markdown } from 'tusk-ui'
import CustomerStoryCard from '../components/CustomerStoryCard'
import { CMSHeading, CMSSection } from 'content/content.types'
import { useCollectionContent } from 'mammoth/hooks/useContent'
import { CustomerStoryContent } from 'content/collections/types'

interface CustomerStoryGridProps extends CMSSection {
    eyebrow?: CMSHeading
    customerStories: string[]
}

const CustomerStoryCardContent = ({ id }: { id: string }) => {
    const { content, loading } = useCollectionContent<CustomerStoryContent>('customer-stories', id)

    if (loading) {
        return null
    }

    return <>{content && content.data && <CustomerStoryCard customerStory={content?.data} />}</>
}

export default function CustomerStoryGrid({
    heading,
    eyebrow,
    customerStories,
    ...sectionProps
}: CustomerStoryGridProps) {
    return (
        <Section {...sectionProps}>
            <Container>
                <div className='mb-80 text-center'>
                    <Heading.H3 variant='h7' className='text-snow-300'>
                        <Markdown.Heading markdown={eyebrow} />
                    </Heading.H3>
                    <Heading.H2>
                        <Markdown.Heading markdown={heading} />
                    </Heading.H2>
                </div>
                <div className='grid grid-cols-3 gap-x-32 gap-y-64 tablet:grid-cols-2 mobile:grid-cols-1'>
                    {customerStories.map((id, index) => (
                        <CustomerStoryCardContent key={index} id={id} />
                    ))}
                </div>
            </Container>
        </Section>
    )
}
