import { Section, Container, Heading, Paragraph, ButtonLink, Carousel, Markdown } from 'tusk-ui'
import { CMSButtonLink, CMSImage, CMSSection } from 'content/content.types'
import ImageCard from '../components/ImageCard'

interface OrderedCards extends CMSSection {
    body: string
    button: CMSButtonLink
    items: {
        title: string
        description: string
        image: CMSImage
    }[]
}

export default function OrderedCards({ heading, body, button, items, ...sectionProps }: OrderedCards) {
    return (
        <Section scheme='river' {...sectionProps}>
            <Container>
                <div className='mb-80 grid grid-cols-2 gap-32 mobile:grid-cols-1'>
                    <Heading.H2 variant='h1'>
                        <Markdown.Heading markdown={heading} />
                    </Heading.H2>
                    <div>
                        <Paragraph variant='h5' className='mb-40'>
                            {body}
                        </Paragraph>
                        <ButtonLink variant={button.variant} to={button.href}>
                            {button.text}
                        </ButtonLink>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-32 gap-y-64 mobile:hidden'>
                    {items.map((item, index) => (
                        <ImageCard
                            key={index}
                            bullet={`${index + 1}`}
                            title={item.title}
                            description={item.description}
                            image={item.image}
                        />
                    ))}
                </div>
            </Container>
            <Container className='hidden mobile:block mobile:pr-0'>
                <Carousel.Root>
                    <Carousel.Content>
                        {items.map((item, index) => (
                            <Carousel.Item key={index}>
                                <ImageCard
                                    key={index}
                                    bullet={`${index + 1}`}
                                    title={item.title}
                                    description={item.description}
                                    image={item.image}
                                    className='h-full'
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel.Content>
                    <Carousel.Indicators />
                </Carousel.Root>
            </Container>
        </Section>
    )
}
