import Theme from '../../../theme'
import { Text } from 'tusk-ui'
import { JSX_TYPES } from '../../../types'
import Check from '../../icons/Check'
import Box from '../../core/Layout/Box'
import { useAnalytics } from '../../../analytics'

type CheckmarkBoxProps = {
    checked: boolean
    disabled?: boolean
    error?: boolean
    size?: 'normal'
}

interface Props extends CheckmarkBoxProps {
    label: JSX_TYPES
    onClick?: (event: any) => void
}

export const CheckmarkBox = ({ error, checked, disabled, size }: CheckmarkBoxProps) => (
    <Box
        height={25}
        width={25}
        backgroundColor={checked ? 'Dark-Purple' : 'White'}
        borderWidth={2}
        borderStyle='solid'
        borderRadius={8}
        borderColor={Theme.Colors[disabled ? 'Grey400' : error ? 'Error' : 'Dark-Purple']}
        mr={!size ? 1 : 2}
        flexCenter
    >
        <Box flexCenter opacity={checked ? 1 : 0} transition='opacity 250ms ease-in-out'>
            <Check color={disabled ? 'Grey400' : 'White'} />
        </Box>
    </Box>
)

const CheckboxCore = ({ label, checked, disabled, error, size, onClick, ...other }: Props) => (
    <Box
        display='flex'
        alignItems='center'
        cursor={!disabled ? 'pointer' : undefined}
        onClick={(e) => !disabled && onClick?.(e)}
        {...other}
    >
        <CheckmarkBox error={error} checked={checked} disabled={disabled} size={size} />
        <Box display='flex' opacity={!size ? 0.5 : 1}>
            <Text variant={!size ? 'b4' : 'b3'}>{label}</Text>
        </Box>
    </Box>
)

const Checkbox = ({ onClick, ...other }: Props) => {
    const analytics = useAnalytics()
    return (
        <CheckboxCore
            onClick={(e) => {
                analytics.track('Click', { Type: 'Checkbox', Label: other.label?.toString() || '' })
                onClick?.(e)
            }}
            {...other}
        />
    )
}

export default Checkbox
