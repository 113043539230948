import { createContext, useState } from 'react'
import {
    BREAK_POINT,
    BREAK_POINT_SMALL,
    DESKTOP_BREAK_POINT,
    TABLET_BREAK_POINT,
    BREAK_POINT_TINY,
    LAPTOP_BREAK_POINT,
    LARGE_LAPTOP_BREAK_POINT,
    SMALL_LAPTOP_BREAK_POINT
} from '../constants'
import { useDebounce, useEventListener } from '../hooks'
import { PropsWithChildren } from '../types'

export const isDesktop = () => window.innerWidth < DESKTOP_BREAK_POINT
export const isLargeLaptop = () => window.innerWidth < LARGE_LAPTOP_BREAK_POINT
export const isLaptop = () => window.innerWidth < LAPTOP_BREAK_POINT
export const isSmallLaptop = () => window.innerWidth < SMALL_LAPTOP_BREAK_POINT
export const isTablet = () => window.innerWidth < TABLET_BREAK_POINT
export const isMobile = () => window.innerWidth < BREAK_POINT
export const isSmallMobile = () => window.innerWidth < BREAK_POINT_SMALL
export const isTinyMobile = () => window.innerWidth < BREAK_POINT_TINY
export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0
export const getHeight = () => (window.innerWidth < BREAK_POINT ? window.screen.height : window.innerHeight)
export const getWidth = () => window.innerWidth

export const DeviceContext = createContext({
    tiny: isTinyMobile(),
    mobileSmall: isSmallMobile(),
    mobile: isMobile(),
    tablet: isTablet(),
    desktop: isDesktop(),
    smallLaptop: isSmallLaptop(),
    laptop: isLaptop(),
    largeLaptop: isLargeLaptop(),
    height: getHeight(),
    width: getWidth(),
    touchDevice: isTouchDevice()
})
const checkBreakpoint = (breakpoint: number, val: boolean, setter: (val: boolean) => void, width: number) => {
    if (width < breakpoint && !val) setter(true)
    else if (width > breakpoint && val) setter(false)
}

export const DeviceProvider = (props: PropsWithChildren) => {
    const [touchDevice, setTouchDevice] = useState(isTouchDevice())
    const [desktop, setDesktop] = useState(isDesktop())
    const [smallLaptop, setSmallLaptop] = useState(isSmallLaptop())
    const [laptop, setLaptop] = useState(isLaptop())
    const [largeLaptop, setLargeLaptop] = useState(isLargeLaptop())
    const [tablet, setTablet] = useState(isTablet())
    const [mobile, setMobile] = useState(isMobile())
    const [tiny, setTiny] = useState(isTinyMobile())
    const [mobileSmall, setMobileSmall] = useState(isSmallMobile())
    const [height, setHeight] = useState(getHeight())
    const [width, setWidth] = useState(getWidth())

    const setScreen = useDebounce((e: any) => {
        const { innerWidth } = e.target
        checkBreakpoint(BREAK_POINT_TINY, tiny, setTiny, innerWidth)
        checkBreakpoint(BREAK_POINT, mobile, setMobile, innerWidth)
        checkBreakpoint(BREAK_POINT_SMALL, mobileSmall, setMobileSmall, innerWidth)
        checkBreakpoint(TABLET_BREAK_POINT, tablet, setTablet, innerWidth)
        checkBreakpoint(DESKTOP_BREAK_POINT, desktop, setDesktop, innerWidth)
        checkBreakpoint(SMALL_LAPTOP_BREAK_POINT, smallLaptop, setSmallLaptop, innerWidth)
        checkBreakpoint(LAPTOP_BREAK_POINT, laptop, setLaptop, innerWidth)
        checkBreakpoint(LARGE_LAPTOP_BREAK_POINT, largeLaptop, setLargeLaptop, innerWidth)

        setHeight(getHeight())
        setWidth(getWidth())
        setTouchDevice(isTouchDevice())
    })

    useEventListener('resize', setScreen, [
        tiny,
        mobile,
        mobileSmall,
        tablet,
        largeLaptop,
        laptop,
        smallLaptop,
        desktop
    ])

    return (
        <DeviceContext.Provider
            value={{
                tiny,
                mobile,
                mobileSmall,
                tablet,
                desktop,
                height,
                width,
                smallLaptop,
                laptop,
                largeLaptop,
                touchDevice
            }}
        >
            {props.children}
        </DeviceContext.Provider>
    )
}
