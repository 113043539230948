import { Container, Section, Html } from 'tusk-ui'

export default function LegalText({ body }: { body: JSX.Element }) {
    return (
        <Section scheme='smoke'>
            <Container width='sm'>
                <Html>{body}</Html>
            </Container>
        </Section>
    )
}
