import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { Link, Button } from 'tusk-ui'

export interface ButtonLinkProps extends ComponentPropsWithoutRef<typeof Link> {
    variant?: ComponentPropsWithoutRef<typeof Button>['variant']
    size?: ComponentPropsWithoutRef<typeof Button>['size']
}

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>((props, ref) => {
    const { children, className, size, variant, ...restProps } = props

    return (
        <Button asChild variant={variant} size={size} className={className}>
            <Link ref={ref} {...restProps}>
                {children}
            </Link>
        </Button>
    )
})

export default ButtonLink
