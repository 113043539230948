import { useSetPopup } from '../../../contexts'
import { Modal } from '../..'
import { withGlobalContent } from 'mammoth/pages/PageContent'
import content from '../../../content/global/en/experience-personalized-popup/content'
import useUser from 'gql/useUser'
import { useEffect } from 'react'
import { Button, Text, TextLink } from 'tusk-ui'
import { Locations } from 'Locations'
import { useTwDevice } from 'mammoth/hooks/useTwDevice'
import { useSearchParams } from 'react-router-dom'

type Props = {
    content: typeof content
}
const ExperiencePersonalizedPopup = ({ content }: Props) => {
    const [searchParams] = useSearchParams()
    const popup = searchParams.get('popup')
    const setPopup = useSetPopup()

    const { mobile } = useTwDevice()
    const { data: user } = useUser()

    useEffect(() => {
        if (popup === 'finish-onboarding' && user?.footprint !== null) {
            setPopup({ EXPERIENCE_PERSONALIZED: {} })
        }
    }, [popup, user?.footprint])

    if (!user) {
        return null
    }

    return (
        <Modal maxWidth={630} height='auto' Type='EXPERIENCE_PERSONALIZED' mobileFullScreen contentProps={{ p: 0 }}>
            <div className='overflow-y-auto p-40 pb-0 mobile:p-24 mobile:pb-40'>
                <Text variant='h2' className='pb-40 mobile:pb-24'>
                    <h2>{content.heading}</h2>
                </Text>
                <Text variant='b2' className='pb-40 mobile:pb-24'>
                    {content.body}
                </Text>

                <img src={content.imageUrl} className='rounded-xl border border-night-100' />
            </div>

            <div className='sticky bottom-0 bg-snow'>
                {mobile && <div className='w-screen divide-y divide-snow-200 rounded-md border border-snow-200' />}

                <div className='flex flex-wrap items-center justify-end gap-20 p-40 mobile:p-24'>
                    <div className='mobile:w-full mobile:text-center'>
                        <TextLink variant='b3' linkVariant='underline' to={Locations.AccountSettings()}>
                            {content.accountSettingsLink}
                        </TextLink>
                    </div>
                    <div className='ml-auto mobile:w-full'>
                        <Button onClick={() => setPopup(null)} variant='primary' className='ml-auto mobile:w-full'>
                            {content.dashboardLink}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withGlobalContent('experience-personalized-popup', ExperiencePersonalizedPopup)
