import { createContext, useState } from 'react'
import { PropsWithChildren } from '../types'

type NavigationBanners = {
    freeAccount: boolean
    inviteTeam: boolean
    joinTeam: boolean
    projectUpdates: boolean
    b2bLeadGen: boolean
}

export type NavigationState = {
    scrollingDown: boolean | null
    mobileOpen: boolean
    dismissedBanner: boolean
    banners: NavigationBanners
    bannersHeight: number
}
export type NavigationStateDispatch = React.Dispatch<React.SetStateAction<NavigationState>>
const defaultState: NavigationState = {
    scrollingDown: null,
    mobileOpen: false,
    dismissedBanner: false,
    banners: {
        freeAccount: false,
        inviteTeam: false,
        joinTeam: false,
        projectUpdates: false,
        b2bLeadGen: false
    },
    bannersHeight: 0
}
export const NavigationStateContext = createContext(defaultState as NavigationState)
export const NavigationDispatchContext = createContext((() => {}) as NavigationStateDispatch)

export const NavigationProvider = (props: PropsWithChildren) => {
    const [navigationState, setNavigationState] = useState(defaultState)
    return (
        <NavigationStateContext.Provider value={navigationState}>
            <NavigationDispatchContext.Provider value={setNavigationState}>
                {props.children}
            </NavigationDispatchContext.Provider>
        </NavigationStateContext.Provider>
    )
}
