import { ResponsiveObject } from 'react-slick'
import env from './env/env.json'
import { Frequency, Time, WorkDay } from 'models/OnboardingFrequencySettingsModels'

export const DEV_MODE = env.DEV_MODE === 'true'
export const CMS_URL = env.CMS_URL || 'http://localhost:1337'
export const BACKEND_URL = env.BACKEND_URL || 'http://localhost:4000'

export const DESKTOP_BREAK_POINT = 1700
export const LARGE_LAPTOP_BREAK_POINT = 1500
export const LAPTOP_BREAK_POINT = 1440
export const SMALL_LAPTOP_BREAK_POINT = 1300
export const TABLET_BREAK_POINT = 1150
export const BREAK_POINT = 700
export const BREAK_POINT_SMALL = 450
export const BREAK_POINT_TINY = 360

export const ANON_USERNAME = 'anonymous'
export const ANON_EMAIL = 'anonymous@carbonneutralclub.com'

export const WELL_BELOW = 'WELL_BELOW'
export const BELOW = 'BELOW'
export const AVG = 'AVG'
export const ABOVE = 'ABOVE'
export const WELL_ABOVE = 'WELL_ABOVE'

export const BucketsText = {
    [WELL_BELOW]: 'well below',
    [BELOW]: 'below',
    [AVG]: 'the same as',
    [ABOVE]: 'above',
    [WELL_ABOVE]: 'well above'
}

export const noOffsetRecieptsOrgs = [43]

// Public < Member < Admin < Owner
export enum PERMISSIONS {
    Public = 0,
    Lead = 1,
    Member = 3,
    Admin = 5,
    Owner = 99
}

export const sliderResponsive: ResponsiveObject[] = [
    {
        breakpoint: 1150,
        settings: {
            slidesToShow: 3
        }
    },
    {
        breakpoint: 700,
        settings: {
            slidesToShow: 2
        }
    },
    {
        breakpoint: 450,
        settings: {
            slidesToShow: 1
        }
    }
]

export enum USER_UPLOAD_IMAGE_SIZES {
    THUMB = 'THUMB',
    FULL = 'FULL'
}

export enum GraphqlErrorCodes {
    UNAUTHENTICATED = 'UNAUTHENTICATED'
}

export enum CustomErrorCodes {
    USER_UNVERIFIED = 'USER_UNVERIFIED',
    SIGNUP_EMAIL_IN_USE = 'SIGNUP_EMAIL_IN_USE'
}

export const DEFAULT_FREQUENCY_SETTINGS = {
    frequency: Frequency.TwicePerWeek,
    weekdays: [WorkDay.Tuesday, WorkDay.Thursday],
    time: Time['2:00 PM'],
    timezone: 'America/New_York'
}

export enum POPUP_TRIGGERS {
    FINISH_ONBOARDING = 'finish-onboarding'
}

export const GLOBAL_TAG_HANDLES = [
    'climate_basics',
    'climate_news',
    'innovation_in_climate_tech',
    'carbon_removals',
    'footprint_tips_at_home',
    'your_teams_footprint',
    'eating_with_impact',
    'footprint_tips_at_work',
    'shopping_better',
    'climate_solutions',
    'petitions_and_events',
    'policy_changes'
] as const
