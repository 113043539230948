import { ComponentPropsWithoutRef, ReactNode } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { Section, Container, Heading, Text, Paragraph, ButtonLink, Markdown } from 'tusk-ui'
import { CMSButtonLink, CMSIcon, CMSHeading } from 'content/content.types'

const contentSectionVariants = cva('', {
    variants: {
        align: {
            left: 'items-start text-left',
            center: 'items-center text-center',
            right: 'items-end text-right'
        }
    },
    defaultVariants: {
        align: 'center'
    }
})

interface ContentSection extends ComponentPropsWithoutRef<typeof Section>, VariantProps<typeof contentSectionVariants> {
    icon?: CMSIcon
    eyebrow?: string
    heading?: CMSHeading
    subheading?: string | ReactNode
    description?: string
    cta?: CMSButtonLink
    children?: ReactNode
}

export default function ContentSection({
    icon: IconComponent,
    eyebrow,
    heading,
    subheading,
    description,
    cta,
    children,
    align,
    ...restProps
}: ContentSection) {
    return (
        <Section {...restProps}>
            <Container className='space-y-64'>
                <div className={cx('flex flex-col', contentSectionVariants({ align }))}>
                    {IconComponent && (
                        <div className='mb-16'>
                            <IconComponent size='lg' />
                        </div>
                    )}
                    {eyebrow && (
                        <Text variant='h7' className='mb-16'>
                            {eyebrow}
                        </Text>
                    )}
                    {heading && (
                        <Heading.H2 className='mb-24'>
                            <Markdown.Heading markdown={heading} />
                        </Heading.H2>
                    )}
                    {subheading && <Text variant='b1'>{subheading}</Text>}
                    {description && <Paragraph className=''>{description}</Paragraph>}
                </div>
                {children}
                {cta && (
                    <div className='flex flex-col items-center'>
                        <ButtonLink variant={cta.variant} to={cta.href}>
                            {cta.text}
                        </ButtonLink>
                    </div>
                )}
            </Container>
        </Section>
    )
}
