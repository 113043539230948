import { OnboardingStep } from '../../../types'

export const OnboardingStepSlugs = {
    [OnboardingStep.AccountSettings]: 'account',
    [OnboardingStep.Personalization]: 'interests',
    [OnboardingStep.FrequencySettings]: 'frequency'
}

const onboardingSteps = [
    OnboardingStep.AccountSettings,
    OnboardingStep.Personalization,
    OnboardingStep.FrequencySettings
]

export const getProgress = (step: OnboardingStep) => {
    return {
        current: onboardingSteps.indexOf(step) + 1,
        total: onboardingSteps.length
    }
}

export const isStepAtLeast = (currentStep: OnboardingStep, givenStep: OnboardingStep) => {
    return onboardingSteps.indexOf(currentStep) >= onboardingSteps.indexOf(givenStep)
}

export const getNextStep = (currentStep: OnboardingStep): OnboardingStep | undefined => {
    const currentStepIndex = onboardingSteps.indexOf(currentStep as OnboardingStep)
    return currentStepIndex < onboardingSteps.length - 1 ? onboardingSteps[currentStepIndex + 1] : undefined
}

export const getPrevStep = (currentStep: OnboardingStep): OnboardingStep | undefined => {
    const currentStepIndex = onboardingSteps.indexOf(currentStep as OnboardingStep)
    return currentStepIndex > 0 ? onboardingSteps[currentStepIndex - 1] : undefined
}

export const getStepFromSlug = (stepSlug?: string) => {
    return Object.entries(OnboardingStepSlugs).find(([, slug]) => slug === stepSlug)?.[0]
}
