import { createContext, useState } from 'react'
import { PropsWithChildren } from '../types'

type State = {
    id: string
    attributes: {
        Site_Brand_Name: string
    }
}

export const ReferringBrandStateContext = createContext(null as State | null)
export const ReferringBrandDispatchContext = createContext((() => {}) as (state: State | null) => void)

export const ReferringBrandProvider = (props: PropsWithChildren) => {
    const [state, setState] = useState(null as State | null)
    return (
        <ReferringBrandStateContext.Provider value={state}>
            <ReferringBrandDispatchContext.Provider value={setState}>
                {props.children}
            </ReferringBrandDispatchContext.Provider>
        </ReferringBrandStateContext.Provider>
    )
}
