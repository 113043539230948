import Theme from '../../theme'
import { PropsWithMobile } from '../../types'
import IconProps from './IconProps'

const Close = ({ width, color }: PropsWithMobile & IconProps) => (
    <svg width={width || 20} viewBox='0 0 20 20' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.09331 3.18407C0.52256 2.61794 0.497122 1.67577 1.03649 1.07968C1.57586 0.483579 2.47578 0.459283 3.04653 1.02541L10 7.92262L16.9535 1.02541C17.5243 0.459283 18.4242 0.483579 18.9636 1.07968C19.5029 1.67577 19.4775 2.61794 18.9067 3.18407L12.0648 9.97066L18.9666 16.8166C19.5373 17.3827 19.5628 18.3249 19.0234 18.921C18.484 19.5171 17.5841 19.5414 17.0134 18.9753L10 12.0187L2.98667 18.9753C2.41592 19.5414 1.516 19.5171 0.976631 18.921C0.437263 18.3249 0.462704 17.3827 1.03345 16.8166L7.93527 9.97066L1.09331 3.18407Z'
            fill={Theme.Colors[color || 'Black']}
        />
    </svg>
)

export default Close
