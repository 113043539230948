import { useSetPopup } from '../../../contexts'
import { Modal } from '../..'
import { withGlobalContent } from 'mammoth/pages/PageContent'
import content from '../../../content/global/en/calculate-popup/content'
import useUser from 'gql/useUser'
import { useEffect } from 'react'
import { ButtonLink, Text, TextLink } from 'tusk-ui'
import { Locations } from 'Locations'
import { useTwDevice } from 'mammoth/hooks/useTwDevice'
import { useSearchParams } from 'react-router-dom'
import { POPUP_TRIGGERS } from '../../../constants'
import can, { Feature } from 'accessControl'
import Handlebars from 'handlebars'
import { useEngagementDetails } from 'gql/employeePoints'
import { EngagementTypes } from 'types'

type Props = {
    content: typeof content
}
const CalculatePopup = ({ content }: Props) => {
    const [searchParams] = useSearchParams()
    const popup = searchParams.get('popup')
    const setPopup = useSetPopup()

    const { mobile } = useTwDevice()
    const { data: user } = useUser()
    const engagementDetails = useEngagementDetails()

    useEffect(() => {
        if (popup === POPUP_TRIGGERS.FINISH_ONBOARDING && user?.footprint == null) {
            setPopup({ CALCULATION_REMINDER: {} })
        }
    }, [popup, user?.footprint])

    if (!user) {
        return null
    }

    const isScope3 = can(user).access(Feature.MeasureScope3)

    return (
        <Modal maxWidth={630} height='auto' Type='CALCULATION_REMINDER' mobileFullScreen contentProps={{ p: 0 }}>
            <div className='overflow-y-auto p-40 pb-0 mobile:p-24 mobile:pb-40'>
                <Text variant='h2' className='pb-40 mobile:pb-24'>
                    <h2>{content.heading}</h2>
                </Text>
                <Text variant='b2' className='pb-40 mobile:pb-24'>
                    {content.body}
                </Text>

                <img src={content.imageUrl} className='rounded-xl border border-night-100' />
            </div>

            <div className='sticky bottom-0 bg-snow'>
                {mobile && <div className='w-screen divide-y divide-snow-200 rounded-md border border-snow-200' />}

                <div className='flex flex-wrap items-center justify-end gap-20 p-40 mobile:p-24'>
                    <div className='mobile:w-full mobile:text-center'>
                        <TextLink
                            variant='b3'
                            linkVariant='underline'
                            to={Locations.Dashboard()}
                            onClick={() => setPopup(null)}
                        >
                            {content.dashboardLink}
                        </TextLink>
                    </div>
                    <div className='ml-auto mobile:w-full'>
                        <ButtonLink
                            to={isScope3 ? Locations.Calculate('personalAndWork') : Locations.Calculate('personal')}
                            variant='primary'
                            className='mobile:w-full'
                        >
                            {Handlebars.compile(content.calculateLink)({
                                points: engagementDetails[
                                    isScope3 ? EngagementTypes.Signup : EngagementTypes.CalculatePersonalInitial
                                ]?.Formatted_Points_Short
                            })}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withGlobalContent('calculate-popup', CalculatePopup)
