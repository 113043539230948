import { useRef, useState } from 'react'
import { Section, Container, Heading, Image, Text, Icon, Stat, TextLink, Carousel } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSImage, CMSLink, CMSSection } from 'content/content.types'
import { AnimatePresence, motion } from 'framer-motion'
import { useWindowSize } from 'react-use'

interface TestimonialProps extends CMSSection {
    items: {
        quote: string
        name: string
        role: string
        moreLink: CMSLink
        desktopImage: CMSImage
        mobileImage: CMSImage
        company: {
            name: string
            logoImage: CMSImage
        }
        stats?: {
            value: number
            metric: string
            description: string
        }[]
    }[]
}

const swipeVariants = {
    initial: (direction: number) => {
        return {
            x: direction > 0 ? 100 : -100,
            opacity: 0
        }
    },
    animate: {
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            x: direction < 0 ? 100 : -100,
            opacity: 0
        }
    }
}

export default function Testimonial({ items, ...sectionProps }: TestimonialProps) {
    const [{ activeIndex, direction }, setActive] = useState<{ activeIndex: number; direction: number }>({
        activeIndex: 0,
        direction: 0
    })

    const { width: _width } = useWindowSize()
    const gallery = useRef<HTMLDivElement | null>(null)
    const galleryW = gallery.current?.offsetWidth || 0
    const activeItem = items[activeIndex]

    const paginate = (newDirection: number) => {
        setActive({ activeIndex: activeIndex + newDirection, direction: newDirection })
    }

    return (
        <Section scheme='night' {...sectionProps}>
            <Container className='tablet:hidden'>
                <div className='flex gap-120'>
                    <div className='flex-1 tablet:w-full'>
                        <AnimatePresence mode='wait' initial={false} custom={direction}>
                            <motion.div
                                className='space-y-40'
                                custom={direction}
                                variants={swipeVariants}
                                initial='initial'
                                animate='animate'
                                exit='exit'
                                transition={{ type: 'tween', duration: 0.2 }}
                                key={activeItem.quote}
                            >
                                <Image
                                    src={activeItem.company.logoImage.src}
                                    alt={activeItem.company.logoImage.alt}
                                    className='w-[140px]'
                                />
                                <div>
                                    <Heading.H3 className='min-h-250px mb-24 tablet:min-h-[240px]' variant='h2'>
                                        {activeItem.quote}
                                    </Heading.H3>
                                    <Text className='tablet:hidden'>
                                        {activeItem.name}, {activeItem.role} @ {activeItem.company.name}
                                    </Text>
                                </div>
                                <div className='hidden items-center gap-24 tablet:flex'>
                                    <Image
                                        className='h-80 w-80 rounded-full object-cover'
                                        src={activeItem.mobileImage.src}
                                        alt={activeItem.mobileImage.alt}
                                    />
                                    <Text>
                                        {activeItem.name},<br /> {activeItem.role} @ {activeItem.company.name}
                                    </Text>
                                </div>
                                {activeItem.moreLink && (
                                    <TextLink variant='h7' to={activeItem.moreLink.href} linkVariant='arrow'>
                                        {activeItem.moreLink.text}
                                    </TextLink>
                                )}
                            </motion.div>
                        </AnimatePresence>
                        {items.length > 1 && (
                            <div className='mt-72 flex items-center gap-16'>
                                <button
                                    aria-label='Previous testimonial'
                                    disabled={activeIndex === 0}
                                    className='group rounded-full border border-snow p-12 hover:border-sun disabled:border-snow-300'
                                    onClick={() => paginate(-1)}
                                >
                                    <Icon.ArrowLeft className='text-snow group-hover:text-sun group-disabled:text-snow-300' />
                                </button>
                                {items.map((item, index) => {
                                    return (
                                        <div
                                            key={`${item.name}${index}`}
                                            className={cx('h-8 w-8 rounded-full border border-snow', {
                                                'bg-snow': index === activeIndex
                                            })}
                                        />
                                    )
                                })}
                                <button
                                    aria-label='Next testimonial'
                                    disabled={activeIndex === items.length - 1}
                                    className='group inline-block rounded-full border border-snow p-12 hover:border-sun disabled:border-snow-300'
                                    onClick={() => paginate(1)}
                                >
                                    <Icon.ArrowRight className='text-snow group-hover:text-sun group-disabled:text-snow-300' />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='w-1/3 max-w-[280px] tablet:hidden'>
                        {items.length > 1 ? (
                            <div ref={gallery} className='aspect-2/3 overflow-hidden rounded-xl'>
                                <motion.div
                                    className='flex h-full w-full'
                                    animate={{ x: -(galleryW * activeIndex) }}
                                    transition={{ type: 'tween' }}
                                >
                                    {items.map((item, index) => (
                                        <Image
                                            key={index}
                                            className='object-cover'
                                            src={item.desktopImage.src}
                                            alt={item.desktopImage.alt}
                                        />
                                    ))}
                                </motion.div>
                            </div>
                        ) : (
                            <Image
                                className='aspect-2/3 rounded-xl object-cover'
                                src={activeItem.desktopImage.src}
                                alt={activeItem.desktopImage.alt}
                            />
                        )}
                        {activeItem.stats && (
                            <div className='mt-40 flex justify-between gap-16'>
                                {activeItem.stats.map((stat, index) => (
                                    <motion.div
                                        key={`${stat.value}${index}`}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <Stat variant='d3' value={stat.value} metric={stat.metric} />
                                        <Text variant='b4'>{stat.description}</Text>
                                    </motion.div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
            <Container className='hidden tablet:block mobile:pr-0'>
                <Carousel.Root>
                    <Carousel.Content>
                        {items.map((item, i) => (
                            <Carousel.Item key={i} className='basis-[90%]'>
                                <div className='flex h-full flex-col justify-between space-y-40'>
                                    <Image
                                        src={item.company.logoImage.src}
                                        alt={item.company.logoImage.alt}
                                        className='w-[140px]'
                                    />
                                    <div>
                                        <Heading.H3 className='mb-24 leading-snug' variant='h4'>
                                            "{item.quote}"
                                        </Heading.H3>
                                        <Text className='tablet:hidden'>
                                            {item.name}, {item.role} @ {item.company.name}
                                        </Text>
                                    </div>
                                    <div className='space-y-24'>
                                        <div className='hidden items-center gap-24 tablet:flex'>
                                            <Image
                                                className='h-80 w-80 rounded-full object-cover'
                                                src={item.mobileImage.src}
                                                alt={item.mobileImage.alt}
                                            />
                                            <Text>
                                                {item.name},<br /> {item.role} @ {item.company.name}
                                            </Text>
                                        </div>
                                        {item.moreLink && (
                                            <TextLink variant='h7' to={item.moreLink.href} linkVariant='arrow'>
                                                {item.moreLink.text}
                                            </TextLink>
                                        )}
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel.Content>
                    <Carousel.Indicators />
                </Carousel.Root>
            </Container>
        </Section>
    )
}
