import { Outlet } from 'react-router-dom'
import Navigation from '../components/Navigation/Navigation'
import Footer from '../components/Footer'
import NavigationContainer from '../components/Navigation/NavigationContainer'

export function NavigationOutletNoBanners() {
    return (
        <>
            <NavigationContainer className='bg-snow'>
                <Navigation />
            </NavigationContainer>
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default function NavigationOutlet() {
    return (
        <>
            <NavigationContainer className='bg-snow'>
                <Navigation />
            </NavigationContainer>
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}
