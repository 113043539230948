import { AnimatePresence, motion } from 'framer-motion'
import { Image } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export default function GradientImage({ src, alt, className }: { src: string; alt: string; className?: string }) {
    return (
        <div
            className={cx(
                'tusk-bg-gradient w-full overflow-hidden rounded-xl pl-64 pt-64 shadow tablet:pl-32 tablet:pt-32',
                className
            )}
        >
            <AnimatePresence mode='wait'>
                <motion.div
                    key={src}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className='h-full w-full'
                >
                    <Image src={src} alt={alt} className='h-full w-full rounded-tl-xl object-contain object-left' />
                </motion.div>
            </AnimatePresence>
        </div>
    )
}
