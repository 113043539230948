import React, { Suspense } from 'react'
import { createBrowserRouter, RouterProvider, RouteObject, Outlet, Navigate } from 'react-router-dom'
import AppRoot from './AppRoot'
import { publicRoutes, memberRoutes, noNavMemberRoutes, noBannersMemberRoutes, noBannersPublicRoutes } from './routes'
import { redirects } from './redirects'
import Private from './outlets/Private'
import { PERMISSIONS } from '../constants'
import i18next from 'i18next'
import { NavigationOutletNoBanners } from './outlets/Navigation'

const AppCarbon = React.lazy(() => import('./AppCarbon'))
const NavigationOutlet = React.lazy(() => import('./outlets/Navigation'))

const routes: RouteObject[] = [
    {
        path: '/:language',
        element: (
            <Suspense fallback={<></>}>
                <AppRoot />
            </Suspense>
        ),
        children: [
            {
                element: <NavigationOutlet />,
                children: publicRoutes
            },
            {
                element: (
                    <Private permission={PERMISSIONS.Member}>
                        <NavigationOutlet />
                    </Private>
                ),
                children: memberRoutes
            },
            {
                element: (
                    <Private permission={PERMISSIONS.Member}>
                        <NavigationOutletNoBanners />
                    </Private>
                ),
                children: noBannersMemberRoutes
            },
            {
                element: <NavigationOutletNoBanners />,
                children: noBannersPublicRoutes
            },
            {
                element: (
                    <Private permission={PERMISSIONS.Member}>
                        <Outlet />
                    </Private>
                ),
                children: noNavMemberRoutes
            },
            {
                path: '*',
                element: <AppCarbon />
            }
        ]
    },
    ...redirects.map((redirect) => ({
        path: redirect.from,
        element: <Navigate to={redirect.to} replace />
    })),
    {
        path: '*',
        Component: () => <Navigate to={`/${i18next.language}`} />
    }
]

export const router = createBrowserRouter(routes)

export default function MammothRouter() {
    return <RouterProvider router={router} />
}
