import { useState } from 'react'
import { Section, Container, Heading, Paragraph } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSIcon, CMSImage, CMSSection } from 'content/content.types'
import GradientImage from '../components/GradientImage'
import Collapse from 'components/animations/Collapse'

interface ProductCarouselProps extends CMSSection {
    icon?: CMSIcon
    items: {
        title: string
        description: string
        image: CMSImage
    }[]
}

export default function ProductCarousel({
    heading,
    items,
    icon: IconComponent,
    ...sectionProps
}: ProductCarouselProps) {
    const [activeIndex, setActiveIndex] = useState(0)
    const activeItem = items[activeIndex]
    return (
        <Section {...sectionProps}>
            <Container>
                <div className='flex gap-64'>
                    <div className='basis-1/3 tablet:basis-auto'>
                        {IconComponent && <IconComponent size='lg' className='mb-16' />}
                        {heading && <Heading.H2 className='mb-40'>{heading}</Heading.H2>}
                        <div className='space-y-40'>
                            {items.map((item, index) => {
                                const isActive = index === activeIndex
                                return (
                                    <button
                                        key={`${item.title}${index}`}
                                        className={cx('group')}
                                        onClick={() => {
                                            setActiveIndex(index)
                                        }}
                                    >
                                        <div className='flex gap-24'>
                                            <div
                                                className={cx(
                                                    'w-8 rounded-full bg-snow-100 transition-colors duration-300',
                                                    {
                                                        'bg-gum': isActive,
                                                        'group-hover:bg-snow-300': !isActive
                                                    }
                                                )}
                                            />
                                            <div>
                                                <Heading.H3 className='mb-16'>{item.title}</Heading.H3>
                                                <Collapse open={isActive}>
                                                    <Paragraph>{item.description}</Paragraph>
                                                    <GradientImage
                                                        src={activeItem.image.src}
                                                        alt={activeItem.image.alt}
                                                        className='mt-16 hidden max-w-[600px] tablet:block'
                                                    />
                                                </Collapse>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='flex basis-2/3 items-center tablet:hidden'>
                        <GradientImage src={activeItem.image.src} alt={activeItem.image.alt} />
                    </div>
                </div>
            </Container>
        </Section>
    )
}
