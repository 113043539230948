import { createContext, useState } from 'react'
import { PropsWithChildren } from '../types'
import { PopupTypeWithData } from '../components/atoms/Modal'

type PopupStateType = Partial<PopupTypeWithData>

export const PopupStateContext = createContext(null as PopupStateType | null)
export const PopupDispatchContext = createContext((() => {}) as (state: PopupStateType | null) => void)

export const PopupProvider = (props: PropsWithChildren) => {
    const [state, setState] = useState(null as PopupStateType | null)

    return (
        <PopupStateContext.Provider value={state}>
            <PopupDispatchContext.Provider
                value={(state: PopupStateType | null) => {
                    // @ts-ignore
                    document.scrollingElement.style.overflowY = state ? 'hidden' : 'scroll'
                    document.body.style.overflowY = state ? 'hidden' : ''
                    setState(state)
                }}
            >
                {props.children}
            </PopupDispatchContext.Provider>
        </PopupStateContext.Provider>
    )
}
