import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { ArrowDown } from '../..'
import Theme from '../../../theme'
import { PropsWithChildren, PropsWithOptionalChildren, PropsWithStyle } from '../../../types'
import { Text } from 'tusk-ui'
import { ErrorMessage, StyledContainer, StyledLabel, StyledP } from './common'
import InputField, { InputFieldProps } from './InputField'
import { ClipLoader } from '../Spinner/Spinner'
import Box from '../../core/Layout/Box'
import Paper from '../../core/Layout/Paper'
import { useGlobalContent } from 'mammoth/hooks/useContent'
import { CMSImage } from 'content/content.types'

const TriggerEl = styled.div`
    position: relative;
    cursor: pointer;
`

type ContainerProps = {
    small?: boolean
    medium?: boolean
    error?: boolean
}

const Container = styled.div<ContainerProps>`
    ${StyledContainer}
    height: ${({ small, medium }) => (small ? 40 : medium ? 47 : 56.25)}px;
    max-width: unset;
    padding-left: ${({ small }) => (small ? 16 : 20)}px;
    padding-right: ${({ small }) => (small ? 16 : 20)}px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
`

export const Option = styled.div<{ minWidth?: number | string }>`
    box-sizing: border-box;
    padding: 20px;
    display: block;
    min-width: ${({ minWidth }) => minWidth || 160}px;
    border-bottom: 1px solid ${Theme.Colors['Beige-2']};
    display: flex;
    align-items: center;
    transition: background-color 150ms ease-in-out;

    :last-of-type {
        border-bottom: none;
    }

    :hover {
        background-color: ${Theme.Colors['Pale-Grey-Blue']};
    }
`

interface CoreDropdownProps extends PropsWithStyle, PropsWithOptionalChildren {
    options: any[]
    onSelect?: (val: string, index: number) => void
    style?: CSSProperties
    scrollToBottom?: () => void
    error?: string
}

export interface ClickDropdownProps extends CoreDropdownProps, DropdownContainerProps {
    onOpen?: () => void
    onClose?: () => void
    label?: string
    medium?: boolean
    small?: boolean
    defaultSelected?: string
    hideLabel?: boolean
    disabled?: boolean
    border?: string
    includeUnselectOption?: boolean
}

export const useOutsideClose = (open: boolean, close: (o: boolean) => void) => {
    const listenerRef = useRef<() => void>()
    useEffect(() => {
        if (open) {
            listenerRef.current = () => close(open)
            window.addEventListener('click', listenerRef.current)
        } else if (listenerRef.current) window.removeEventListener('click', listenerRef.current)
        return () => listenerRef.current && window.removeEventListener('click', listenerRef.current)
    }, [open])
}

const UNSELECT_OPTION = 'Unselect'
const Dropdown = ({
    maxHeight = 300,
    hideLabel = false,
    anchor,
    label,
    options,
    children,
    onSelect: propsOnSelect,
    onOpen,
    onClose,
    small,
    medium,
    defaultSelected,
    minWidth = 150,
    error,
    disabled = false,
    border,
    includeUnselectOption,
    ...props
}: ClickDropdownProps) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(defaultSelected || '')

    useEffect(() => {
        setSelected(defaultSelected || '')
    }, [defaultSelected])

    const closeDropdown = useCallback((open) => {
        if (!open) return
        setOpen(false)
        if (onClose) onClose()
    }, [])

    const openDropdown = useCallback(() => {
        if (open) return
        setOpen(true)
        if (onOpen) onOpen()
    }, [open])

    const toggle = useCallback(() => {
        /*
            This is a hack to prevent the dropdown from closing when the user clicks to open it.
            TODO: Revisit and find a better solution
        */
        if (disabled) return
        setTimeout(() => {
            if (open) closeDropdown(open)
            else openDropdown()
        }, 100)
    }, [open])

    useOutsideClose(open, closeDropdown)

    options = includeUnselectOption ? [UNSELECT_OPTION, ...options] : options

    return (
        <TriggerEl {...props} onClick={toggle} style={{ cursor: disabled ? 'default' : 'pointer' }}>
            {children ? (
                children
            ) : (
                <Container
                    small={small}
                    medium={medium}
                    error={!!error}
                    style={{
                        minWidth: minWidth,
                        background: disabled ? Theme.Colors.Grey200 : undefined,
                        border
                    }}
                >
                    {!hideLabel && !!label && (
                        <StyledLabel focus={!!selected} small={small} medium={medium}>
                            {label}
                        </StyledLabel>
                    )}
                    {selected && (
                        <StyledP
                            style={
                                hideLabel || !label || !selected
                                    ? {}
                                    : {
                                          marginTop: 10
                                      }
                            }
                            small={small}
                            medium={medium}
                        >
                            {selected}
                        </StyledP>
                    )}
                    <ErrorMessage error={!!error}>{error}</ErrorMessage>
                    <ArrowDown />
                </Container>
            )}
            <DropdownContainer minWidth={minWidth} maxHeight={maxHeight} open={open} anchor={anchor}>
                {options.map((o: any, i: number) =>
                    typeof o === 'string' ? (
                        <Option
                            minWidth={minWidth}
                            key={i}
                            onClick={(e) => {
                                e.stopPropagation()
                                setSelected(o === UNSELECT_OPTION ? '' : o)
                                closeDropdown(open)
                                // @ts-ignore
                                if (propsOnSelect) {
                                    const j = includeUnselectOption ? (o === UNSELECT_OPTION ? -1 : i - 1) : i
                                    propsOnSelect(o, j)
                                }
                            }}
                        >
                            <Text variant='b3'>{o}</Text>
                        </Option>
                    ) : (
                        o
                    )
                )}
            </DropdownContainer>
        </TriggerEl>
    )
}

type DropdownContainerProps = {
    anchor?: 'right' | 'left' | 'bottom' | 'top'
    minWidth?: number | string
    maxHeight?: number | string
    open?: boolean
}
export const DropdownContainer = ({
    children,
    minWidth,
    maxHeight,
    anchor,
    open
}: PropsWithChildren & DropdownContainerProps) => (
    <Paper
        lightRadius
        minWidth={minWidth ? minWidth : '100%'}
        opacity={open ? 1 : 0}
        transform={open ? 'translate(0px, 0px)' : 'translate(0px, 10px)'}
        pointerEvents={open ? 'all' : 'none'}
        transition='all 250ms ease-in-out'
        position='absolute'
        zIndex={99}
        left={anchor === 'left' ? 0 : undefined}
        bottom={anchor === 'top' ? '100%' : undefined}
        top={anchor === 'bottom' ? '100%' : undefined}
        right={anchor === 'right' ? 0 : undefined}
        backgroundColor='White'
        overflow='auto'
        maxHeight={maxHeight}
        borderWidth={0}
        borderStyle='solid'
        borderColor='Beige-2'
        overflowY='auto'
        darkShadow
    >
        {children}
    </Paper>
)

interface AutocompleteProps extends CoreDropdownProps {
    inputProps: InputFieldProps
    google?: boolean
    loading?: boolean
    reducedRadius?: number
}

export const Autocomplete = ({
    google,
    inputProps,
    options,
    loading,
    onSelect: propsOnSelect,
    scrollToBottom,
    ...props
}: AutocompleteProps) => {
    const { content } = useGlobalContent<{ autocomplete: { google: CMSImage } }>('select')
    const open = (options && options.length) || loading
    return (
        <TriggerEl {...props}>
            <InputField {...inputProps} />
            <Paper
                darkShadow
                borderRadius={10}
                minWidth={150}
                opacity={open ? 1 : 0}
                transform={open ? 'translate(0px, 0px)' : 'translate(0px, 10px)'}
                pointerEvents={open ? 'all' : 'none'}
                transition='all 250ms ease-in-out'
                position='absolute'
                zIndex={99}
                right={0}
                left={0}
                backgroundColor='White'
                overflow='auto'
                maxHeight={220}
                borderWidth={2}
                borderStyle='solid'
                borderColor='Beige-2'
                overflowY='scroll'
                onScroll={({ target }) => {
                    if (!scrollToBottom) return
                    // @ts-ignore
                    const { scrollHeight, clientHeight, scrollTop } = target
                    const distanceFromBottom = scrollHeight - clientHeight - scrollTop
                    if (distanceFromBottom < 50) scrollToBottom()
                }}
            >
                {options.map((o: any, i: number) => (
                    <Option
                        key={i}
                        onClick={(e) => {
                            e.stopPropagation()
                            // @ts-ignore
                            if (propsOnSelect) propsOnSelect(o, i)
                        }}
                    >
                        <Text variant='b3'>{o}</Text>
                    </Option>
                ))}
                {google && content?.autocomplete?.google && (
                    <Box pt={1} pb={1} backgroundColor='Beige-2' flexCenter>
                        <img
                            height='12'
                            width='96'
                            src={content.autocomplete.google.src}
                            alt={content.autocomplete.google.alt || ''}
                        />
                    </Box>
                )}

                {loading && (
                    <Box p={4} flexCenter>
                        {' '}
                        <ClipLoader size={20} />
                    </Box>
                )}
            </Paper>
        </TriggerEl>
    )
}
export default Dropdown
