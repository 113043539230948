import { Chip, Container, Heading, Icon, Image, Paragraph, Section, Text, TextLink, Stat, Markdown } from 'tusk-ui'
import { CMSImage, CMSSection, CMSStat } from 'content/content.types'
import { numberto2Decimals } from '../../util'

interface EmployeeShowcaseProps extends CMSSection {
    employees: [
        {
            image: CMSImage
            name: string
            role: string
            linkedInUrl: string
            quote: string
            footprints: {
                title: string
                decreaseRate: string
                stat: CMSStat
            }[]
        }
    ]
}

export default function EmployeeShowcase({ heading, employees, ...sectionProps }: EmployeeShowcaseProps) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container className='space-y-64'>
                <Heading.H2 className='text-center'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
                <div className='grid grid-flow-col gap-48 mobile:grid-flow-row'>
                    {employees.map((employee, index) => {
                        return (
                            <div key={`${employee.name}${index}`} className='flex flex-col justify-between space-y-24'>
                                <div className='space-y-24'>
                                    <Image
                                        src={employee.image.src}
                                        alt={employee.image.alt}
                                        className='aspect-2/3 rounded-xl mobile:aspect-1/1'
                                    />
                                    <div>
                                        <Text variant='h5' className='mb-8'>
                                            {employee.name}, {employee.role}
                                        </Text>
                                        <TextLink
                                            linkVariant='arrow'
                                            variant='l1'
                                            to={employee.linkedInUrl}
                                            target='_blank'
                                            className='text-snow-300 hover:text-night [&_svg]:text-snow-300'
                                        >
                                            LinkedIn
                                        </TextLink>
                                    </div>
                                    <Paragraph variant='b2'>{employee.quote}</Paragraph>
                                </div>
                                <div>
                                    {employee.footprints.map((footprint, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex items-center justify-between border-b border-night-100 py-24'
                                            >
                                                <div>
                                                    <Text className='mb-4'>{footprint.title}</Text>
                                                    <Chip className='inline-flex items-center bg-bay-100 hover:bg-bay-100 active:bg-bay-100'>
                                                        <Icon.ArrowDown size='xs' className='mr-3' />
                                                        <Text variant='b4'>{footprint.decreaseRate}</Text>
                                                    </Chip>
                                                </div>
                                                <div>
                                                    <Stat
                                                        variant='h3'
                                                        smallMetric
                                                        value={
                                                            typeof footprint.stat.value === 'number'
                                                                ? numberto2Decimals(footprint.stat.value)
                                                                : footprint.stat.value
                                                        }
                                                        metric={footprint.stat.metric}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </Section>
    )
}
