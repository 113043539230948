import Skeleton, { SkeletonProps, SkeletonTheme as SkeletonT, SkeletonThemeProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Theme from '../../theme'

export const SkeletonTheme = (props: SkeletonThemeProps) => (
    <SkeletonT baseColor={Theme.Colors['Beige-2']} highlightColor={Theme.Colors['White']} {...props} />
)
const Skel = (props: SkeletonProps) => <Skeleton {...props} />

export default Skel
