import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { ElementPrimitive } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

const iconVariants = cva('', {
    variants: {
        size: {
            xs: 'w-12',
            sm: 'w-16',
            md: 'w-24',
            lg: 'w-32',
            xl: 'w-40'
        }
    },
    defaultVariants: {
        size: 'md'
    }
})

interface IconBaseProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Svg>,
        VariantProps<typeof iconVariants> {
    useFill?: boolean
    useStroke?: boolean
}

const IconBase = forwardRef<ElementRef<typeof ElementPrimitive.Svg>, IconBaseProps>((props, ref) => {
    const { className, size, useFill, useStroke, viewBox = '0 0 24 24', ...restProps } = props

    return (
        <ElementPrimitive.Svg
            ref={ref}
            fill={useFill ? 'currentColor' : 'none'}
            stroke={useStroke ? 'currentColor' : 'none'}
            className={cx('text-night', iconVariants({ size }), className)}
            viewBox={viewBox}
            {...restProps}
        />
    )
})

export default IconBase
