import { ReactNode, useEffect, useRef, useState } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import {
    Container,
    Text,
    TextLink,
    ButtonLink,
    Button,
    Modal,
    Link,
    Image,
    Icon,
    Accordion,
    DropdownMenu
} from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import * as NavigationMenu from './NavigationMenu'
import { CMSLink } from 'content/content.types'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import { Locations } from 'Locations'
import useUser from 'gql/useUser'
import { getNavMode, getUserPermission } from '../../../util'
import { isWithoutNavRoute } from 'components/organisms/Navigation/RenderNavigation'
import { withGlobalContent } from 'mammoth/pages/PageContent'
import content from 'content/global/en/header/content'
import Skel from 'components/atoms/Skeleton'

interface MainNavLinks extends CMSLink {
    links?: CMSLink[]
}

export type NavigationContent = typeof content
type Props = {
    content: NavigationContent
}

const navLinkStyles = cx(
    'flex h-full items-center whitespace-nowrap border-b-4 border-transparent px-16 hover:bg-snow-100 hover:text-night pt-4'
)

const Navigation = ({ content }: Props) => {
    const { userLinks, logo, loginLink } = content
    const { data: user } = useUser()
    const subnavRef = useRef(null)
    const [subnavRefTarget, setSubnavRefTarget] = useState(null)
    const { pathname } = useLocation()

    useEffect(() => {
        if (subnavRef.current) {
            setSubnavRefTarget(subnavRef.current)
        }
    }, [])

    if (isWithoutNavRoute(pathname)) {
        return <></>
    }

    const navMode = getNavMode(user, pathname)

    const { links, ctaLink } = userLinks[navMode]
    let mobileLinks: (Partial<CMSLink> & { links?: CMSLink[] })[] = []
    if (navMode === 'anon') {
        mobileLinks = [...links, loginLink]
    } else {
        mobileLinks = [
            ...links,
            {
                text: 'Profile',
                href: '/profile',
                links: userLinks[navMode].profileLinks
            },
            {
                text: 'Logout',
                href: Locations.Logout()
            }
        ]
    }

    return (
        <>
            <div className='border-b border-night-100'>
                <Container width='lg' className='flex h-64 w-full items-stretch gap-24'>
                    <div className='flex space-x-24'>
                        <LogoLink logo={logo} />
                        <NavigationMenu.Root className='items-stretch tablet:hidden'>
                            <NavigationMenu.List className='flex h-full items-stretch'>
                                {links.map((link, index) => (
                                    <NavigationMenu.Item key={index} className='flex items-stretch'>
                                        {link.links ? (
                                            <>
                                                <NavigationMenu.Trigger className={cx(navLinkStyles)}>
                                                    <Text variant='l2'>{link.text}</Text>
                                                    <Icon.ChevronDown className='text-snow-300 transition group-data-[state=open]:rotate-180' />
                                                </NavigationMenu.Trigger>
                                                {subnavRefTarget &&
                                                    createPortal(
                                                        <NavigationMenu.Content className='border-b border-night-100'>
                                                            <Container width='lg' className='flex h-64 items-stretch'>
                                                                <ul className='ml-[230px] flex h-full items-stretch'>
                                                                    {link.links.map((subLink, j) => (
                                                                        <NavLink
                                                                            key={j}
                                                                            to={subLink.href}
                                                                            active={subLink.href === pathname}
                                                                        >
                                                                            {subLink.text}
                                                                        </NavLink>
                                                                    ))}
                                                                </ul>
                                                            </Container>
                                                        </NavigationMenu.Content>,
                                                        subnavRefTarget
                                                    )}
                                            </>
                                        ) : (
                                            <NavLink to={link.href} active={link.href === pathname}>
                                                {link.text}
                                            </NavLink>
                                        )}
                                    </NavigationMenu.Item>
                                ))}
                            </NavigationMenu.List>
                        </NavigationMenu.Root>
                    </div>
                    <div className='ml-auto flex items-center gap-16'>
                        {navMode === 'anon' && loginLink && (
                            <TextLink variant='l2' to={loginLink.href}>
                                {loginLink.text}
                            </TextLink>
                        )}
                        {navMode === 'anon' ? (
                            ctaLink && (
                                <ButtonLink size='sm' to={ctaLink.href} className='tablet:hidden'>
                                    {ctaLink.text}
                                </ButtonLink>
                            )
                        ) : (
                            <ButtonLink to={Locations.Feedback()} size='sm' className='tablet:hidden'>
                                {content.feedbackPopup.openCta}
                            </ButtonLink>
                        )}
                        {navMode !== 'anon' && userLinks[navMode].profileLinks && (
                            <DropdownMenu.Root>
                                <DropdownMenu.Trigger asChild>
                                    <Button size='sm' className='group px-16 tablet:hidden'>
                                        {navMode === 'member' && <Icon.PersonCircle className='group-hover:text-sun' />}
                                        {navMode === 'admin' && <Icon.Suitcase className='group-hover:text-sun' />}
                                    </Button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content sticky='always' align='end' className='p-16'>
                                    {userLinks[navMode].profileLinks
                                        .filter((link) => {
                                            const userPermission = getUserPermission(user)
                                            // @ts-ignore
                                            const routePermission = link.permission
                                            if (!routePermission) return true
                                            return userPermission >= routePermission
                                        })
                                        .map((link, i) => (
                                            <DropdownMenu.Item key={i}>
                                                <TextLink variant='b3' to={link.href}>
                                                    {link.text}
                                                </TextLink>
                                            </DropdownMenu.Item>
                                        ))}
                                </DropdownMenu.Content>
                            </DropdownMenu.Root>
                        )}
                        <Modal.Root>
                            <Modal.Trigger className='hidden tablet:block'>
                                <Icon.MenuHamburger />
                            </Modal.Trigger>
                            <Modal.Dialog className='duration-400 h-[100dvh] w-screen opacity-0 transition-opacity data-[open=true]:opacity-100'>
                                {({ close, isOpen }) => (
                                    <MobileNavigationContent
                                        close={close}
                                        isOpen={isOpen}
                                        logo={logo}
                                        // @ts-ignore
                                        mobileLinks={mobileLinks}
                                        pathname={pathname}
                                        ctaLink={ctaLink}
                                    />
                                )}
                            </Modal.Dialog>
                        </Modal.Root>
                    </div>
                </Container>
            </div>
            <div ref={subnavRef} />
        </>
    )
}

const MobileNavigationContent = ({
    isOpen,
    close,
    logo,
    mobileLinks,
    pathname,
    ctaLink
}: {
    isOpen: boolean
    close: any
    logo: typeof content.logo
    mobileLinks: MainNavLinks[]
    pathname: string
    ctaLink: CMSLink
}) => {
    useEffect(() => {
        if (isOpen) close()
    }, [pathname])

    return (
        <Container width='lg'>
            <div className='duration-600 flex h-full flex-col justify-between'>
                <div className='flex h-64 justify-between'>
                    <LogoLink logo={logo} />
                    <Modal.Close className='mr-12'>
                        <Icon.Close />
                    </Modal.Close>
                </div>
                <nav>
                    <Accordion.Root type='multiple' className='w-full'>
                        {mobileLinks.map((link, index) => (
                            <Accordion.Item
                                key={index}
                                value={`${link.href}${index}`}
                                className={cx('border-0 p-16', {
                                    'border-l-4 border-night': link.href === pathname
                                })}
                            >
                                {link.links ? (
                                    <>
                                        <Accordion.Trigger>
                                            <Text variant='l2' className='whitespace-nowrap'>
                                                {link.text}
                                            </Text>
                                        </Accordion.Trigger>
                                        <Accordion.Content>
                                            <ul className='p-24'>
                                                {link.links.map((subLink, j) => (
                                                    <TextLink
                                                        key={j}
                                                        variant='h7'
                                                        to={subLink.href}
                                                        className='block p-16'
                                                    >
                                                        {subLink.text}
                                                    </TextLink>
                                                ))}
                                            </ul>
                                        </Accordion.Content>
                                    </>
                                ) : (
                                    <TextLink variant='h7' to={link.href}>
                                        {link.text}
                                    </TextLink>
                                )}
                            </Accordion.Item>
                        ))}
                    </Accordion.Root>
                </nav>
                {ctaLink && (
                    <ButtonLink to={ctaLink.href} className='mb-80 w-full'>
                        {ctaLink.text}
                    </ButtonLink>
                )}
            </div>
        </Container>
    )
}

const NavLink = ({
    children,
    to,
    className
}: {
    children: ReactNode
    to: string
    active?: boolean
    className?: string
}) => {
    return (
        <RouterNavLink
            to={to}
            className={({ isActive }) =>
                cx(
                    navLinkStyles,
                    {
                        'border-night': isActive
                    },
                    className
                )
            }
        >
            <Text variant='l2'>{children}</Text>
        </RouterNavLink>
    )
}

const LogoLink = ({ logo }: { logo: typeof content.logo }) => {
    return (
        <Link to={logo?.link.href} className='flex w-[205px] items-center'>
            <Image src={logo?.image.src} alt={logo?.image.alt} className='object-contain' />
        </Link>
    )
}

export default withGlobalContent('header', Navigation, () => (
    <Container width='lg'>
        <div className='flex h-64 items-center justify-between'>
            <Skel height={17} width={170} />
            <Skel height={44} width={130} />
        </div>
    </Container>
))
