import { Section, Container, Paragraph, ButtonLink } from 'tusk-ui'
import ImageBlockCard from '../components/ImageBlockCard'
import { CMSButtonLink, CMSImage, CMSSection } from 'content/content.types'

interface SimpleCard extends CMSSection {
    body: string
    button: CMSButtonLink
    image: CMSImage
}

export default function SimpleCard({ heading, body, button, image, ...sectionProps }: SimpleCard) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container>
                <ImageBlockCard heading={heading} image={image}>
                    <Paragraph>{body}</Paragraph>
                    <ButtonLink variant={button.variant} to={button.href}>
                        {button.text}
                    </ButtonLink>
                </ImageBlockCard>
            </Container>
        </Section>
    )
}
