import { Image, Link, Section, Text, TextLink, Container } from 'tusk-ui'
import useUser from 'gql/useUser'
import { useLocation } from 'react-router-dom'
import SubscribeForm from '../forms/SubscribeForm'
import { withoutFooter } from 'components/organisms/Navigation/navigationConstants'
import { getNavMode } from '../../util'
import { withGlobalContent } from 'mammoth/pages/PageContent'
import content from 'content/global/en/footer/content'
import { publicRoutes as oldPublicRoutes } from 'App/routes'
import { publicRoutes as newPublicRoutes } from 'mammoth/routes'
import { isRoute } from 'components/organisms/Navigation/RenderNavigation'
import { cx } from 'tusk-ui/utils'

export interface FooterProps {
    content: typeof content
}

function Footer({ content }: FooterProps) {
    const { copyright, copyrightLinks, socialLinks, logoImage, userLinks } = content
    const { pathname } = useLocation()
    const { data: user } = useUser()
    const { navLinks } = userLinks[getNavMode(user, pathname)]

    const publicRoutes = [...oldPublicRoutes, ...newPublicRoutes].map((route) => `/:language/${route.path}`)
    const isPublicRoute = isRoute(pathname, publicRoutes)
    if (isRoute(pathname, withoutFooter)) {
        return null
    }
    return (
        <Section py='sm' className='pb-32'>
            <Container>
                {isPublicRoute && <SubscribeForm content={content} />}
                <div className={cx('border-b border-snow-200 py-64', !isPublicRoute ? 'border-t' : '')}>
                    <div className='flex items-start justify-between gap-80 desktop-sm:flex-col desktop-sm:gap-40'>
                        <Image src={logoImage.src} alt={logoImage.alt} className='w-40 object-contain' />
                        <div className='grid w-full auto-cols-fr grid-flow-col gap-48 mobile:grid-flow-row mobile:grid-cols-2 mobile:gap-y-40'>
                            {navLinks.map((navLink, index) => (
                                <div key={index}>
                                    <Text variant='h7' className='mb-16'>
                                        {navLink.title}
                                    </Text>
                                    <div className='space-y-16'>
                                        {navLink.links.map((link, index) => (
                                            <TextLink key={index} className='block' to={link.href}>
                                                {link.text}
                                            </TextLink>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='hidden gap-24 tablet:flex'>
                            {socialLinks.map((link, index) => (
                                <Link key={index} to={link.href}>
                                    {link.icon}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='flex justify-between pt-24'>
                    <div className='flex gap-24 mobile:flex-col-reverse'>
                        <Text variant='b4'>{copyright}</Text>
                        {copyrightLinks.map((link, index) => (
                            <TextLink variant='b4' key={index} to={link.href}>
                                {link.text}
                            </TextLink>
                        ))}
                    </div>
                    <div className='flex gap-24 tablet:hidden'>
                        {socialLinks.map((link, index) => (
                            <Link key={index} to={link.href} aria-label={link.alt} target='_blank'>
                                {link.icon}
                            </Link>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
    )
}

export default withGlobalContent('footer', Footer)
