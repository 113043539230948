import { PropsWithChildren } from '../../../types'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react'

interface TooltipProps extends PropsWithChildren {
    text: string
    onOpen?: () => void
    onClose?: () => void
    id: string
    maxWidth?: string
    place?: 'top' | 'bottom' | 'left' | 'right'
}

function Tooltip({ children, text, onOpen, onClose, id, place = 'bottom', maxWidth = '50vw' }: TooltipProps) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div data-tooltip-html={text} id={id} data-tooltip-place={place} style={{ cursor: 'pointer' }}>
                {children}
            </div>
            <ReactTooltip
                style={{ fontSize: 14, maxWidth, zIndex: 1 }}
                anchorId={id}
                isOpen={isOpen}
                setIsOpen={(open) => {
                    if (open) onOpen?.()
                    else onClose?.()

                    setIsOpen(open)
                }}
            />
        </>
    )
}

export default Tooltip
