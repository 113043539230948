import { Section, Container, Heading, Stat, Paragraph, ButtonLink } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSButtonLink, CMSSection } from 'content/content.types'

interface StatList extends CMSSection {
    button?: CMSButtonLink
    layout: 'horizontal' | 'vertical'
    stats: {
        value: string | number
        metric: string
        title: string
        description?: string
    }[]
}

export default function StatList({ heading, button, stats, layout = 'vertical', ...sectionProps }: StatList) {
    return (
        <Section py={layout === 'horizontal' ? 'md' : 'lg'} {...sectionProps}>
            <Container className='space-y-64'>
                <Heading.H2 className={cx({ 'text-center': layout === 'horizontal' })}>{heading}</Heading.H2>
                <div className='mb-64'>
                    {layout === 'vertical' ? (
                        <>
                            {stats.map((stat, i) => (
                                <div
                                    key={i}
                                    className={cx(
                                        'grid grid-cols-2 items-center gap-80 border-b border-snow-200 py-40 last:border-none tablet:grid-cols-1 tablet:gap-16'
                                    )}
                                >
                                    <Stat value={stat.value} metric={stat.metric} />
                                    <div>
                                        <Heading.H3 variant='h6' className='mb-16'>
                                            {stat.title}
                                        </Heading.H3>
                                        <Paragraph>{stat.description}</Paragraph>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className='flex flex-wrap justify-center gap-64'>
                            {stats.map((stat, i) => (
                                <div key={i} className='max-w-[320px] text-center'>
                                    <Stat value={stat.value} metric={stat.metric} className='mb-8' />
                                    <Heading.H3 variant='h7' className='mb-8'>
                                        {stat.title}
                                    </Heading.H3>
                                    <Paragraph>{stat.description}</Paragraph>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {button && (
                    <div className='flex justify-center'>
                        <ButtonLink variant={button.variant} to={button.href}>
                            {button.text}
                        </ButtonLink>
                    </div>
                )}
            </Container>
        </Section>
    )
}
