import { forwardRef, ComponentPropsWithoutRef, ElementRef, ReactElement } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ElementPrimitive } from 'tusk-ui'

const iconButtonVariants = cva(
    'group box-content flex items-center justify-center rounded-full bg-snow ring-night hover:bg-night focus:outline-none focus:ring-1 active:bg-night group-hover/chip:bg-night group-focus/chip:ring-0 group-active/chip:bg-night',
    {
        variants: {
            size: {
                sm: 'w-12 h-12 p-[6px] border',
                md: 'w-[19.2px] h-[19.2px] p-[9.6px] border',
                lg: 'w-24 h-24 p-12 border-2'
            },
            border: {
                true: 'border-night ring-offset-[3px]',
                false: 'w-auto h-auto border-0 ring-inset'
            }
        },
        defaultVariants: {
            size: 'lg',
            border: false
        }
    }
)

const iconButtonIconVariants = cva(
    'flex text-night group-hover/chip:text-snow group-hover:text-snow group-active/chip:text-snow group-active:text-snow',
    {
        variants: {
            size: {
                sm: 'w-8',
                md: 'w-[12.8px]',
                lg: 'w-16'
            }
        },
        defaultVariants: {
            size: 'lg'
        }
    }
)

export interface IconButtonProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Button>,
        VariantProps<typeof iconButtonVariants> {
    icon: (props: { className?: string }) => ReactElement | null
    as?: React.ElementType
}

const IconButton = forwardRef<ElementRef<typeof ElementPrimitive.Button>, IconButtonProps>((props, ref) => {
    const { className, size, icon: Icon, as: Component = ElementPrimitive.Button, ...restProps } = props

    return (
        <Component ref={ref} className={cx(iconButtonVariants({ size }), className)} {...restProps}>
            <Icon className={iconButtonIconVariants({ size })} />
        </Component>
    )
})

export default IconButton
