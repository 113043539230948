import { ReactNode } from 'react'
import { Card, Heading, Image } from 'tusk-ui'
import { CMSHeading, CMSImage } from 'content/content.types'

type ImageBlockCardProps = {
    heading: CMSHeading
    children?: ReactNode
    image: CMSImage
}

export default function ImageBlockCard({ heading, children, image }: ImageBlockCardProps) {
    return (
        <Card padding='lg' scheme='night' className='grid grid-cols-2 gap-80 tablet:grid-cols-1 mobile:gap-40'>
            <div className='space-y-24'>
                <Heading.H3>{heading}</Heading.H3>
                {children}
            </div>
            <div>
                <Image
                    className='aspect-3/2 rounded-xl border border-snow object-cover'
                    src={image.src}
                    alt={image.alt}
                />
            </div>
        </Card>
    )
}
