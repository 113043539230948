import { ThemeColors } from '../../types'
import Box from './Layout/Box'

type DividerProps = {
    height?: number | string
    width?: number | string
    backgroundColor?: ThemeColors
}
const Divider = ({ height = 2, width = '100%', backgroundColor = 'Beige-2' }: DividerProps) => (
    <Box height={height} width={width} backgroundColor={backgroundColor} />
)

export default Divider
