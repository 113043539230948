import { Text } from 'tusk-ui'
import Theme from '../../theme'
import { ThemeColors } from '../../types'
import Box, { BoxProps } from '../core/Layout/Box'
import React from 'react'

type Props = {
    value: string
    setValue: (value: string, index: number) => void
}
const transition = 'all 500ms ease-in-out'

interface TabsCoreProps extends Props {
    options: string[]
    info?: string
    infoColor?: ThemeColors
    activeColor?: ThemeColors
    activeTextColor?: ThemeColors
    containerProps?: BoxProps
    height?: number
}
const Tabs = ({
    setValue,
    value,
    options,
    info,
    infoColor = 'White',
    activeColor = 'Pink',
    activeTextColor = 'Ink',
    containerProps,
    height = 44
}: TabsCoreProps) => (
    <Box display='inline-flex' flexDirection='column'>
        <Box
            display='flex'
            height={`${height}px`}
            borderRadius={`${height}px`}
            borderColor={Theme.Colors[activeColor]}
            borderWidth={2}
            borderStyle='solid'
            overflow='hidden'
            cursor='pointer'
            width='100%'
            {...(containerProps || {})}
        >
            {options.map((o, i) => (
                <React.Fragment key={i}>
                    {!!i && <Box width={2} backgroundColor={activeColor} />}
                    <Box
                        flex={1}
                        onClick={() => setValue(o, i)}
                        backgroundColor={value === o ? activeColor : undefined}
                        flexCenter
                        transition={transition}
                        pr={3 + (i === options.length - 1 ? 1 : 0)}
                        pl={3 + (!i ? 1 : 0)}
                    >
                        <Text
                            color={value === o ? activeTextColor : undefined}
                            className='whitespace-nowrap uppercase'
                            variant='b4'
                        >
                            {o}
                        </Text>
                    </Box>
                </React.Fragment>
            ))}
        </Box>
        {info && (
            <Box flexCenter pt={1}>
                <Text color={infoColor} variant='b4' className='text-center'>
                    {info}
                </Text>
            </Box>
        )}
    </Box>
)

export default Tabs
