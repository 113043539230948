import { VariantProps, cva } from 'class-variance-authority'
import { Section, Container, Heading, Text, Image, Paragraph, Markdown } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSImage, CMSSection } from 'content/content.types'
import { ReactNode } from 'react'

const contentSectionVariants = cva('', {
    variants: {
        align: {
            left: 'items-start text-left',
            center: 'items-center text-center',
            right: 'items-end text-right'
        }
    },
    defaultVariants: {
        align: 'center'
    }
})

interface ContentImageProps extends CMSSection, VariantProps<typeof contentSectionVariants> {
    eyebrow?: string
    subheading?: string
    description?: string
    inverseLayout?: boolean
    image: CMSImage
    children: ReactNode
}

export default function ContentImage({
    eyebrow,
    heading,
    subheading,
    description,
    children,
    inverseLayout,
    image,
    ...sectionProps
}: ContentImageProps) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container>
                <Heading.H2 className='mb-64'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
                <div className='flex items-start gap-80 tablet:flex-col'>
                    <div
                        className={cx('w-2/5 tablet:w-full', {
                            ['order-1']: !!inverseLayout
                        })}
                    >
                        {subheading && <Heading.H3 className='mb-64'>{subheading}</Heading.H3>}
                        {eyebrow && <Text variant='h7'>{eyebrow}</Text>}
                        {description && <Paragraph className=''>{description}</Paragraph>}
                        {children}
                    </div>
                    <Image
                        src={image.src}
                        alt={image.alt}
                        aspectRatio={image.aspectRatio}
                        className='w-3/5 rounded-xl tablet:w-full'
                    />
                </div>
            </Container>
        </Section>
    )
}
