import React, { KeyboardEvent, RefObject, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'tusk-ui'
import Theme from '../../../theme'
import { PropsWithStyle } from '../../../types'
import Search from '../../icons/Search'
import Box from '../../core/Layout/Box'
import { Container, StyledLabel, InputStyles, ErrorMessage, StyledInputContainerProps, StyledIcon } from './common'
import { useAnalytics } from '../../../analytics'

// if hasLabel is not defined, it is assumed to be true
export const StyledInputField = styled.input<{ hasLabel?: boolean }>`
    ${InputStyles}
    ${({ hasLabel }) => hasLabel === false && `padding-top: 0px;`}
`

const TextAreaField = styled.textarea`
    ${InputStyles}
    resize: none;
    height: 190px;
`

export const ReadOnlyField = styled.input`
    ${InputStyles}
    background-color: ${Theme.Colors['Beige']};
    height: 36px;
    text-overflow: ellipsis;
    padding-top: 0px;
    font-weight: normal;
`

export interface InputFieldProps extends PropsWithStyle, StyledInputContainerProps {
    label?: string
    placeHolder?: string
    type?: 'password' | 'text' | 'number' | 'date'
    rows?: number
    name?: string
    subCopy?: string
    value?: string
    onPressEnter?: () => void
    onChange?: (value: string) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    autoComplete?: string
    controlledValue?: boolean
    disabled?: boolean
    icon?: 'search'
    inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>
}

let idCounter = 0

const InputField = ({
    label,
    placeHolder,
    onChange,
    error,
    onBlur,
    onFocus,
    onPressEnter,
    rows,
    subCopy,
    controlledValue,
    icon,
    inputRef,
    ...inputProps
}: InputFieldProps) => {
    const [focus, setFocus] = useState(false)
    const [value, setValue] = useState(inputProps.value)
    const analytics = useAnalytics()

    useEffect(() => {
        if (controlledValue) setValue(inputProps.value ? inputProps.value : '')
    }, [inputProps.value, setValue, controlledValue])

    const props = {
        ...inputProps,
        error: !!error,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = e.target
            setValue(value)
            if (onChange) onChange(value)
        },
        onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            analytics.track('Enter Field', { 'Field Name': name })
            setFocus(true)
            if (onFocus) onFocus(e)
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFocus(false)
            if (onBlur) onBlur(e)
        }
    }

    const keyUp = (event: KeyboardEvent) => !!onPressEnter && event.keyCode === 13 && onPressEnter()

    const name = props.name || `input-id-${idCounter++}`
    return (
        <>
            <Container>
                {label && (
                    <StyledLabel rows={rows} htmlFor={name} focus={focus || !!value}>
                        {label}
                    </StyledLabel>
                )}
                {rows ? (
                    <TextAreaField
                        placeholder={placeHolder}
                        onKeyUp={keyUp}
                        id={name}
                        ref={inputRef as RefObject<HTMLTextAreaElement>}
                        rows={rows}
                        {...props}
                    />
                ) : (
                    <StyledInputField
                        hasLabel={!!label}
                        onKeyUp={keyUp}
                        id={name}
                        ref={inputRef as RefObject<HTMLInputElement>}
                        {...props}
                    />
                )}
                <ErrorMessage error={!!error}>{error}</ErrorMessage>
                {icon === 'search' && (
                    <StyledIcon focus={focus || !!value}>
                        <Search width={30} color='Yellow-Orange' />
                    </StyledIcon>
                )}
            </Container>
            {subCopy ? (
                <Box pl={4} pt={1} pr={4} display='flex' opacity={0.5}>
                    <Text variant='b4'>{subCopy}</Text>
                </Box>
            ) : (
                <></>
            )}
        </>
    )
}

export const PhoneField = ({ onChange, ...props }: InputFieldProps) => {
    return (
        <InputField
            {...props}
            onChange={(value) => {
                const input = value.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
                const areaCode = input.substring(0, 3)
                const middle = input.substring(3, 6)
                const last = input.substring(6, 10)

                if (input.length > 6) value = `(${areaCode})-${middle}-${last}`
                else if (input.length > 3) value = `(${areaCode})-${middle}`
                else if (input.length > 0) value = `(${areaCode}`

                onChange?.(value)
            }}
        />
    )
}

export default InputField
