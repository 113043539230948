import { Link } from 'react-router-dom'
import { Button } from '../index'
import styled from 'styled-components'
import { CTA_Section, User } from '../../types'
import { getCta } from '../../util'
import { useDevice } from '../../contexts'
import useUser from '../../gql/useUser'
import Box from '../core/Layout/Box'
import { Text } from 'tusk-ui'
import Theme from 'theme'

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 0;
    pointer-events: none;
`

function Graphic() {
    return (
        <Container>
            <svg width='100%' viewBox='0 0 1441 369' fill='none'>
                <path
                    d='M1440.93 276.325C1440.93 276.325 982.709 286.44 677.143 354.877V368.883L1440.93 369.012V276.325Z'
                    fill={Theme.Colors['Dark-Purple']}
                />
                <path
                    d='M0.59491 264.847C0.59491 264.847 423.262 255.735 763.414 369L0.59491 367.835V264.847Z'
                    fill={Theme.Colors['Dark-Purple']}
                />
                <path
                    d='M226.165 272.305C226.165 272.305 108.703 284.82 15.0941 367.858L42.576 367.905C42.576 367.905 161.298 271.699 300.726 278.516C300.726 278.516 249.961 273.936 226.165 272.305Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M0.594963 309.105C0.594963 309.105 37.0469 268.984 82.8538 265.442L43.4041 264.777C43.4041 264.777 18.9434 270.883 -0.0115967 293.874L0.594963 309.105Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M235.135 368.196C235.135 368.196 329.607 292.651 475.636 300.761C475.636 300.761 492.363 303.453 504.728 305.644C504.728 305.644 360.075 298.256 265.72 368.243L235.135 368.196Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M635.862 332.702C635.862 332.702 541.693 331.431 460.344 368.534H504.74C504.74 368.534 600.868 333.669 662.469 339.344C662.457 339.355 646.908 335.335 635.862 332.702Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M109.764 282.688H147.347L131.927 145.429H124.835L109.764 282.688Z'
                    fill={Theme.Colors['Beige-2']}
                />
                <path
                    d='M272.987 299.13H299.734L288.758 201.479H283.707L272.987 299.13Z'
                    fill={Theme.Colors['Beige-2']}
                />
                <path
                    d='M738.463 360.948C738.463 360.948 721.246 362.02 697.941 368.883L680.688 368.872C680.688 368.872 705.499 357.102 720.768 355.541C720.768 355.541 729.167 358.023 738.463 360.948Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M808.008 368.895C808.008 368.895 862.901 328.856 896.414 317.25L928.923 313.288C928.923 313.288 874.694 332.585 827.453 368.895H808.008Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M923.219 368.907C923.219 368.907 1007.3 309.081 1060.43 299.316L1101.13 295.634C1101.13 295.634 1013.42 315.665 944.239 368.907H923.219Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M1084.52 368.942C1084.52 368.942 1193.45 295.308 1299.03 282.163L1336.74 280.217C1336.74 280.217 1210.72 301.507 1105.19 368.953L1084.52 368.942Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M1440.98 280.788C1368.95 304.269 1283.5 369 1283.5 369H1300.84C1346.18 331.664 1406.69 304.176 1441 289.167L1440.98 280.788Z'
                    fill={Theme.Colors['Black']}
                />
                <path
                    d='M1237.06 347.268H1283.22L1264.29 178.721H1255.57L1237.06 347.268Z'
                    fill={Theme.Colors['Beige-2']}
                />
                <g className='windmill'>
                    <circle cx='286' cy='203' r='76.5' stroke='white' strokeOpacity='0.01' />
                    <path
                        d='M286.04 207.084C286.04 207.084 301.472 216.64 325.886 227.279C325.886 227.279 348.271 229.633 356.401 225.764C356.401 225.764 330.739 205.045 288.559 199.988L286.04 207.084Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M289.294 200.151C289.294 200.151 288.221 182.042 282.855 155.975C282.855 155.975 271.879 136.34 264.04 131.935C264.04 131.935 261.882 164.82 282.039 202.178L289.294 200.151Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M283.217 200.221C283.217 200.221 266.758 207.865 244.455 222.431C244.455 222.431 230.213 239.829 228.988 248.743C228.988 248.743 260.401 238.675 287.778 206.222L283.217 200.221Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M286.168 208.389C289.305 208.389 291.849 205.849 291.849 202.714C291.849 199.58 289.305 197.04 286.168 197.04C283.031 197.04 280.487 199.58 280.487 202.714C280.487 205.849 283.031 208.389 286.168 208.389Z'
                        fill='white'
                    />
                </g>
                <g className='windmill'>
                    <circle cx='128' cy='153' r='118.5' stroke='white' strokeOpacity='0.01' />
                    <path
                        d='M127.599 159.005C127.599 159.005 150.392 173.116 186.471 188.836C186.471 188.836 219.528 192.308 231.543 186.61C231.543 186.61 193.633 156.01 131.332 148.529L127.599 159.005Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M132.405 148.762C132.405 148.762 130.819 122.019 122.887 83.5061C122.887 83.5061 106.685 54.5139 95.09 48C95.09 48 91.8939 96.5806 121.685 151.757L132.405 148.762Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M123.423 148.867C123.423 148.867 99.1026 160.17 66.1617 181.669C66.1617 181.669 45.1187 207.375 43.3107 220.531C43.3107 220.531 89.7126 205.663 130.142 157.723L123.423 148.867Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M127.798 160.916C132.429 160.916 136.184 157.165 136.184 152.537C136.184 147.91 132.429 144.159 127.798 144.159C123.166 144.159 119.411 147.91 119.411 152.537C119.411 157.165 123.166 160.916 127.798 160.916Z'
                        fill='white'
                    />
                </g>
                <g className='windmill'>
                    <circle cx='1260' cy='173' r='131.5' stroke='white' strokeOpacity='0.01' />
                    <path
                        d='M1259.44 180.83C1259.44 180.83 1285.84 197.179 1327.61 215.369C1327.61 215.369 1365.89 219.401 1379.8 212.782C1379.8 212.782 1335.9 177.346 1263.76 168.688L1259.44 180.83Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M1265.02 168.979C1265.02 168.979 1263.17 138.006 1253.99 93.4226C1253.99 93.4226 1235.22 59.8392 1221.81 52.2998C1221.81 52.2998 1218.11 108.56 1252.6 172.452L1265.02 168.979Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M1254.61 169.096C1254.61 169.096 1226.45 182.182 1188.31 207.084C1188.31 207.084 1163.94 236.857 1161.84 252.099C1161.84 252.099 1215.58 234.876 1262.4 179.362L1254.61 169.096Z'
                        fill={Theme.Colors['Beige-2']}
                    />
                    <path
                        d='M1259.67 183.056C1265.04 183.056 1269.39 178.71 1269.39 173.349C1269.39 167.988 1265.04 163.642 1259.67 163.642C1254.31 163.642 1249.96 167.988 1249.96 173.349C1249.96 178.71 1254.31 183.056 1259.67 183.056Z'
                        fill='white'
                    />
                </g>
            </svg>
        </Container>
    )
}

const GetInTouch = ({
    Heading = '',
    Call_To_Action = '',
    Destination = '',
    Background_Color = 'Beige'
}: CTA_Section) => {
    const { tablet } = useDevice()
    const { data: user } = useUser()

    const destination = Destination || getCta(user as User).path
    const label = Call_To_Action || getCta(user as User, Call_To_Action).label

    return (
        <Box
            minHeight={tablet ? '400px' : ''}
            backgroundColor={Background_Color}
            pt={8}
            display='flex'
            justifyContent='center'
            alignItems='flex-end'
            position='relative'
        >
            <Box
                maxWidth={795}
                pr={4}
                pl={4}
                flexDirection='column'
                display='flex'
                justifyContent='center'
                position='absolute'
                top={0}
                bottom={0}
                zIndex={1}
            >
                <Text variant='h2' className='text-center' color='Dark-Purple'>
                    {Heading}
                </Text>
                <Box pt={8} flexCenter>
                    <Link to={destination}>
                        <Button>{label}</Button>
                    </Link>
                </Box>
            </Box>
            <Graphic />
        </Box>
    )
}

export default GetInTouch
