import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { cx } from 'tusk-ui/utils'

export type LinkProps = ComponentPropsWithoutRef<typeof RouterLink>

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { className, to, ...restProps } = props

    return (
        <RouterLink
            ref={ref}
            className={cx('cursor-pointer', className)}
            to={to}
            preventScrollReset={to?.toString().includes('#')}
            {...restProps}
        />
    )
})

export default Link
