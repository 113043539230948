import Theme from '../../theme'
import IconProps from './IconProps'

const InfoIcon = ({ color }: IconProps) => (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2C5.35786 2 2 5.35786 2 9.5C2 13.6421 5.35786 17 9.5 17ZM9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z'
            fill={Theme.Colors[color || 'Pink']}
        />
        <path
            d='M9.51162 6.32C9.16628 6.32 8.87695 6.21267 8.64362 5.998C8.41962 5.774 8.30762 5.49867 8.30762 5.172C8.30762 4.84534 8.41962 4.57467 8.64362 4.36C8.87695 4.136 9.16628 4.024 9.51162 4.024C9.85695 4.024 10.1416 4.136 10.3656 4.36C10.5989 4.57467 10.7156 4.84534 10.7156 5.172C10.7156 5.49867 10.5989 5.774 10.3656 5.998C10.1416 6.21267 9.85695 6.32 9.51162 6.32ZM10.4776 7.244V15H8.51762V7.244H10.4776Z'
            fill={Theme.Colors[color || 'Pink']}
        />
    </svg>
)

export default InfoIcon
