import { ComponentPropsWithoutRef, useRef, useState } from 'react'
import { Image, Text, Icon, Section, Container, Heading } from 'tusk-ui'
import { CMSImage, CMSSection } from 'content/content.types'
import { LogoGrid } from './'
import { cx } from 'tusk-ui/utils'
import { LogoImgGrid } from './LogoGrid'

interface VideoDemoProps extends CMSSection {
    logos: ComponentPropsWithoutRef<typeof LogoGrid>['logos']
    video: {
        title: string
        timeStamp?: string
        src: string
        thumbnail: CMSImage
    }
}

function formatDuration(seconds?: number): string {
    if (typeof seconds !== 'number') throw new Error('Input must be a number')

    const roundedSeconds = Math.floor(seconds)
    const minutes = Math.floor(roundedSeconds / 60)
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(roundedSeconds % 60).padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds} min`
}

type VideoStates = 'initial' | 'loaded' | 'play' | 'paused'

export default function VideoDemo({ video, heading, logos, ...sectionProps }: VideoDemoProps) {
    const [vidState, setVidState] = useState<VideoStates>('initial')
    const [vidDuration, setVidDuration] = useState('')
    const [playHover, setPlayHover] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)

    return (
        <Section py='sm' {...sectionProps}>
            <Container className='space-y-64'>
                <Heading.H2 variant='h1' className='mb-24 text-center'>
                    {heading}
                </Heading.H2>
                <div className='relative aspect-16/9 overflow-hidden rounded-xl mobile:aspect-3/4'>
                    <div
                        className={cx('absolute left-0 top-0 z-10 h-full w-full', {
                            hidden: vidState === 'play'
                        })}
                    >
                        <Image
                            src={video.thumbnail.src}
                            alt={video.thumbnail.alt}
                            className={cx(
                                'absolute h-full w-full rounded-xl object-center transition-transform duration-500',
                                {
                                    'scale-105': playHover
                                }
                            )}
                        />
                        <div className='flex h-full w-full items-center justify-start p-32 mobile:items-end mobile:justify-center'>
                            <button
                                className='relative z-30 flex h-96 w-[280px] items-center gap-16 rounded-full bg-snow p-8 shadow-[0_3px_16px_0_rgba(0,0,0,0.15)] transition-shadow hover:shadow-[0_3px_32px_0_rgba(0,0,0,0.40)] hover:shadow-night-200 mobile-min:p-16 mobile-min:text-center'
                                onClick={() => {
                                    setVidState('play')
                                    videoRef.current?.play()
                                }}
                                onMouseEnter={() => {
                                    if (!playHover) {
                                        setPlayHover(true)
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (playHover) {
                                        setPlayHover(false)
                                    }
                                }}
                            >
                                <Image src='/assets/images/play-pill.svg' className='h-80 w-80 mobile-min:hidden' />
                                <div>
                                    <div className='mb-8 flex items-center'>
                                        <Icon.Play className='mr-4' />
                                        <Text variant='h6'>{video.title}</Text>
                                    </div>
                                    <Text className='text-night-300'>{video.timeStamp || vidDuration}</Text>
                                </div>
                            </button>
                        </div>
                    </div>
                    <video
                        className='relative z-0 h-full w-full'
                        controls
                        controlsList='nodownload'
                        ref={videoRef}
                        onLoadedMetadata={(e) => {
                            const videoTarget = e.target as HTMLVideoElement
                            setVidState('loaded')
                            setVidDuration(formatDuration(videoTarget.duration))
                        }}
                    >
                        <source src={video.src} type='video/mp4' />
                    </video>
                </div>
                <LogoImgGrid items={logos} />
            </Container>
        </Section>
    )
}
