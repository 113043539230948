import { forwardRef, ComponentPropsWithoutRef, ElementRef } from 'react'
import { cx } from 'tusk-ui/utils'
import { cva, VariantProps } from 'class-variance-authority'
import { ElementPrimitive } from 'tusk-ui'

const containerContentVariants = cva('mx-auto', {
    variants: {
        width: {
            flush: '',
            lg: 'max-w-[1350px] px-48 mobile:px-16',
            md: 'max-w-[1296px] px-48 tablet:px-64 mobile:px-16',
            flushRight: 'max-w-[1296px] pl-48 tablet:px-64 mobile:px-16',
            sm: 'max-w-[896px] px-48 mobile:px-16'
        }
    },
    defaultVariants: {
        width: 'md'
    }
})

const splitContainerContentVariants = cva('', {
    variants: {
        width: {
            lg: 'max-w-[675px] px-48 mobile:px-16',
            md: 'max-w-[648px] px-48 tablet:px-64 mobile:px-16',
            sm: 'max-w-[448px] px-48 mobile:px-16'
        },
        align: {
            left: 'mr-auto',
            right: 'ml-auto',
            center: 'mx-auto'
        }
    },
    defaultVariants: {
        width: 'md',
        align: 'center'
    }
})

interface ContainerContentProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>,
        VariantProps<typeof containerContentVariants> {}

const Container = forwardRef<ElementRef<typeof ElementPrimitive.Div>, ContainerContentProps>((props, ref) => {
    const { className, width, ...restProps } = props

    return (
        <ElementPrimitive.Div ref={ref} className={cx(containerContentVariants({ width }), className)} {...restProps} />
    )
})

interface SplitContainerContentProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>,
        VariantProps<typeof splitContainerContentVariants> {}
export const SplitContainer = forwardRef<ElementRef<typeof ElementPrimitive.Div>, SplitContainerContentProps>(
    (props, ref) => {
        const { className, width, align, ...restProps } = props

        return (
            <ElementPrimitive.Div
                ref={ref}
                className={cx(splitContainerContentVariants({ width, align }), className)}
                {...restProps}
            />
        )
    }
)

export default Container
