import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { ElementPrimitive } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

const imageVariants = cva('w-full object-cover', {
    variants: {
        aspectRatio: {
            '1/1': 'aspect-1/1',
            '3/2': 'aspect-3/2',
            '4/3': 'aspect-4/3'
        }
    },
    defaultVariants: {}
})

interface ImageProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Img>,
        VariantProps<typeof imageVariants> {}

const Image = forwardRef<ElementRef<typeof ElementPrimitive.Img>, ImageProps>((props, ref) => {
    const { className, aspectRatio, alt, ...restProps } = props
    return (
        <ElementPrimitive.Img
            ref={ref}
            alt={alt ?? ''}
            className={cx(imageVariants({ aspectRatio }), className)}
            {...restProps}
        />
    )
})

export default Image
