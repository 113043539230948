import { Card, Container, Section, Heading, Text, Image, Carousel } from 'tusk-ui'
import { CMSImage, CMSSection } from 'content/content.types'

type Quote = {
    text: string
    name: string
}

interface ImageQuoteGridProps extends CMSSection {
    quote1: Quote
    quote2: Quote
    images: [CMSImage, CMSImage, CMSImage]
}

export default function ImageQuoteGrid({ quote1, quote2, images, ...sectionProps }: ImageQuoteGridProps) {
    return (
        <Section {...sectionProps}>
            <Container className='grid grid-cols-12 gap-40 tablet:hidden'>
                <Card scheme='night' padding='sm' className='col-span-4 min-h-[380px]'>
                    <Heading.H4 className='mb-24'>{quote1.text}</Heading.H4>
                    <Text>{quote1.name}</Text>
                </Card>
                <Image className='col-span-8 h-full rounded-xl' src={images[0].src} alt={images[0].alt} />
                <Image className='col-span-3 h-full rounded-xl' src={images[1].src} alt={images[1].alt} />
                <Image className='col-span-5 h-full rounded-xl' src={images[2].src} alt={images[2].alt} />
                <Card scheme='night' padding='sm' className='col-span-4'>
                    <Heading.H4 className='mb-24'>{quote2.text}</Heading.H4>
                    <Text>{quote2.name}</Text>
                </Card>
            </Container>
            <Container className='hidden tablet:block mobile:pr-0'>
                <Carousel.Root>
                    <Carousel.Content>
                        <Carousel.Item className='basis-2/3'>
                            <Card scheme='night' padding='sm' className='h-full'>
                                <Heading.H4 className='mb-24'>{quote1.text}</Heading.H4>
                                <Text>{quote1.name}</Text>
                            </Card>
                        </Carousel.Item>
                        {images.map((image, i) => (
                            <Carousel.Item className='basis-1/3' key={i}>
                                <Image className='h-full rounded-xl' src={image.src} alt={image.alt} />
                            </Carousel.Item>
                        ))}
                        <Carousel.Item>
                            <Card scheme='night' padding='sm' className='h-full basis-2/3'>
                                <Heading.H4 className='mb-24'>{quote2.text}</Heading.H4>
                                <Text>{quote2.name}</Text>
                            </Card>
                        </Carousel.Item>
                    </Carousel.Content>
                    <Carousel.Indicators />
                </Carousel.Root>
            </Container>
        </Section>
    )
}
