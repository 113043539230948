import { ReactNode } from 'react'
import { Section, Container, Heading, Card, ButtonLink } from 'tusk-ui'
import { CMSButtonLink, CMSSection } from 'content/content.types'

interface HeadingCardCtaProps extends CMSSection {
    button: CMSButtonLink
    children?: ReactNode
}

export default function HeadingCardCta({ heading, button, children, ...sectionProps }: HeadingCardCtaProps) {
    return (
        <Section {...sectionProps}>
            <Container className='space-y-64'>
                <Heading.H2 className='text-center'>{heading}</Heading.H2>
                <Card padding='lg' scheme='night' className='grid grid-cols-2 gap-80 mobile:grid-cols-1 mobile:gap-40'>
                    {children}
                </Card>
                <div className='flex justify-center'>
                    <ButtonLink variant='secondary' to={button.href}>
                        {button.text}
                    </ButtonLink>
                </div>
            </Container>
        </Section>
    )
}
