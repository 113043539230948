import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'
import { Slot } from '@radix-ui/react-slot'

export interface PrimitiveProps {
    id?: string
    children?: ReactNode
    className?: string
}

interface PolymorphicComponent {
    asChild?: boolean
}

interface BasePrimitive extends PrimitiveProps, PolymorphicComponent {}

export const Base = forwardRef<HTMLElement, PrimitiveProps>((props, ref) => {
    return <Slot ref={ref} {...props} />
})

interface DivProps extends BasePrimitive, ComponentPropsWithoutRef<'div'> {}
export const Div = forwardRef<HTMLDivElement, DivProps>(({ asChild, ...restProps }, ref) => {
    const Component = asChild ? Slot : 'div'
    return <Component ref={ref} {...restProps} />
})

interface ButtonProps extends BasePrimitive, ComponentPropsWithoutRef<'button'> {}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ asChild, ...restProps }, ref) => {
    const Component = asChild ? Slot : 'button'
    return <Component ref={ref} {...restProps} />
})

interface AnchorProps extends BasePrimitive, ComponentPropsWithoutRef<'a'> {}
export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(({ asChild, ...restProps }, ref) => {
    const Component = asChild ? Slot : 'a'
    return <Component ref={ref} {...restProps} />
})

interface SpanProps extends BasePrimitive, ComponentPropsWithoutRef<'span'> {}
export const Span = forwardRef<HTMLSpanElement, SpanProps>(({ asChild, ...restProps }, ref) => {
    const Component = asChild ? Slot : 'span'
    return <Component ref={ref} {...restProps} />
})

interface SvgProps extends PrimitiveProps, ComponentPropsWithoutRef<'svg'> {}
export const Svg = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
    return <svg ref={ref} xmlns='http://www.w3.org/2000/svg' {...props} />
})

interface ImgProps extends PrimitiveProps, ComponentPropsWithoutRef<'img'> {}
export const Img = forwardRef<HTMLImageElement, ImgProps>(({ ...restProps }, ref) => {
    return <img ref={ref} {...restProps} />
})

interface ParagraphProps extends PrimitiveProps, ComponentPropsWithoutRef<'p'> {}
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(({ ...restProps }, ref) => {
    return <p ref={ref} {...restProps} />
})

interface UlProps extends BasePrimitive, ComponentPropsWithoutRef<'ul'> {}
export const Ul = forwardRef<HTMLUListElement, UlProps>(({ ...restProps }, ref) => {
    return <ul ref={ref} {...restProps} />
})
