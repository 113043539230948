import { Fragment } from 'react'
import * as sectionComponents from '../sections'

export default function SectionBlockRender({ sections }: { sections: any[] }) {
    return (
        <Fragment>
            {sections.map((section, index) => {
                const Component = sectionComponents[section.id]
                if (Component) {
                    return <Component key={`${section.id}${index}`} {...section.data} />
                } else {
                    return null
                }
            })}
        </Fragment>
    )
}
