import { Card, Container, Section, Heading, Paragraph, Image } from 'tusk-ui'
import { CMSImage, CMSSection } from 'content/content.types'
import { cx } from 'tusk-ui/utils'

interface PressGridProps extends CMSSection {
    investors: {
        heading: string
        logos: CMSImage[]
    }
    articles: {
        logo: CMSImage
        description: string
    }[]
}
export default function PressGrid({ heading, investors, articles, ...sectionProps }: PressGridProps) {
    return (
        <Section {...sectionProps}>
            <Container>
                {investors && (
                    <Card scheme='river' padding='lg' className='mb-64'>
                        <Heading.H2 className='mb-64 text-center'>{investors?.heading}</Heading.H2>
                        <div
                            className={cx(
                                'mx-auto grid grid-cols-5 items-center gap-x-120 gap-y-64 tablet:grid-cols-6 tablet:gap-x-100 mobile:grid-cols-4 mobile:gap-48'
                            )}
                        >
                            {investors.logos.map((item, i) => (
                                <Image
                                    key={i}
                                    className={cx('max-h-40 object-contain tablet:col-span-2 mobile:max-h-40', {
                                        'tablet:col-start-2 mobile:col-start-auto': i === investors.logos.length - 2,
                                        'mobile:col-start-2': i === investors.logos.length - 1
                                    })}
                                    src={item.src}
                                    alt={item.alt}
                                />
                            ))}
                        </div>
                    </Card>
                )}
                <Card scheme='night' padding='lg'>
                    <Heading.H2 className='mb-64 text-center'>{heading}</Heading.H2>
                    <div className='grid grid-flow-col gap-64 tablet:grid-flow-row'>
                        {articles.map((article, index) => {
                            return (
                                <div key={index} className='flex flex-col justify-between'>
                                    <Image
                                        src={article.logo.src}
                                        alt={article.logo.alt}
                                        className='mb-16 max-h-24 w-auto object-contain object-left'
                                    />
                                    <Paragraph>{article.description}</Paragraph>
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </Container>
        </Section>
    )
}
