import { cx } from 'class-variance-authority'
import { ReactNode, useEffect, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'

const NavigationContainer = ({ className, children }: { className?: string; children: ReactNode }) => {
    const headerRef = useRef(null)
    const { height } = useResizeObserver({ ref: headerRef })

    useEffect(() => {
        document.documentElement.style.setProperty('--mmth-nav-container-height', `${height}px`)
    }, [height])

    return (
        <header
            ref={headerRef}
            role='banner'
            className={cx('fixed left-0 top-0 z-20 w-[calc(100%-var(--removed-body-scroll-bar-size,0px))]', className)}
        >
            {children}
        </header>
    )
}

export default NavigationContainer
