import { CMSImage } from 'content/content.types'
import { Helmet } from 'react-helmet'

type PageSeoProps = {
    title: string
    description: string
    image: CMSImage
}

const defaultSEO = {
    title: 'Mammoth Climate - Make every job a climate job',
    description:
        'Mammoth Climate helps organizations meet their climate targets by rewarding employees for reducing emissions at work.',
    image: { src: '/assets/open-graph/home.png', alt: '' }
}

export default function PageSeo(props: PageSeoProps) {
    const title = props.title || defaultSEO.title
    const description = props.description || defaultSEO.description
    const image = props.image || defaultSEO.image
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:type' content='website' />
            {image && <meta property='og:image' content={image.src} />}
        </Helmet>
    )
}
