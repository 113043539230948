import { PropsWithChildren, PropsWithMobile } from '../../types'
import Box from '../core/Layout/Box'

interface Props extends PropsWithChildren, PropsWithMobile {
    topBreakPoint?: number
    bottomBreakPoint?: number
    disablePr?: boolean
}

const Sticky = ({ children, mobile = false, topBreakPoint = 120, disablePr }: Props) => {
    return (
        <Box willChange='position' pr={mobile || disablePr ? undefined : 12} position='sticky' top={topBreakPoint}>
            {children}
        </Box>
    )
}

export default Sticky
