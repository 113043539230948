import React, { ComponentPropsWithoutRef } from 'react'
import { cx } from 'tusk-ui/utils'
import Input from './Input'
import { InputError } from 'tusk-ui/components/Icon'

interface TextInputProps extends ComponentPropsWithoutRef<typeof Input>, ComponentPropsWithoutRef<'input'> {}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({ className, invalid, ...props }, ref) => {
    return (
        <div className='relative h-fit'>
            <Input ref={ref} invalid={invalid} className={cx('', className)}>
                <input {...props} />
            </Input>
            {invalid && (
                <div className='absolute right-16 top-0 flex h-full items-center'>
                    <InputError className='w-20' />
                </div>
            )}
        </div>
    )
})

export default TextInput
