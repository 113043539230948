import { gql } from '@apollo/client'
import { useCustomMutation } from 'gql/clients'
import { useState } from 'react'
import { Heading } from 'tusk-ui'
import { StatusCode } from 'types'
import { isValidEmail } from '../../util'
import { Field, Form, TextInput } from 'tusk-ui/components/Form'
import { FooterProps } from 'mammoth/components/Footer'

const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation subscribeToNewsletter($email: String!) {
        subscribeToNewsletter(email: $email) {
            code
        }
    }
`

export default function SubscribeForm({ content }: FooterProps) {
    const { heading, form } = content
    const { subscribe: subscribeLabel, successMessage, oops, invalidEmail, placeholder } = form
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [subscribeToNewsletter] = useCustomMutation(SUBSCRIBE_TO_NEWSLETTER)
    async function subscribe() {
        if (!isValidEmail(email)) {
            setError(invalidEmail)
            return
        }
        setLoading(true)
        const { data } = await subscribeToNewsletter({ variables: { email } })
        setLoading(false)
        if (data?.subscribeToNewsletter?.code === StatusCode.Success) {
            setSuccess(true)
        } else {
            setError(oops)
        }
    }

    return (
        <div className='flex justify-between gap-16 border-b border-snow-200 pb-64 mobile:flex-col mobile:gap-40'>
            <Heading.H3 variant='h4'>{heading}</Heading.H3>
            <div>
                {success ? (
                    <p>{successMessage}</p>
                ) : (
                    <Form.Root>
                        <Field.Root>
                            <div className='flex mobile:flex-col mobile:gap-16'>
                                <TextInput
                                    className='min-w-[250px] rounded-none rounded-l-sm mobile:rounded-r-sm'
                                    placeholder={placeholder}
                                    onChange={(e) => setEmail(e.target.value)}
                                    invalid={!!error}
                                />
                                <Form.Submit
                                    loading={loading}
                                    className='rounded-none rounded-r-sm mobile:rounded-l-sm'
                                    onClick={subscribe}
                                >
                                    {subscribeLabel}
                                </Form.Submit>
                            </div>
                            {error && <Field.Error>{error}</Field.Error>}
                        </Field.Root>
                    </Form.Root>
                )}
            </div>
        </div>
    )
}
