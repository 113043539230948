import { css } from 'styled-components'
import { BREAK_POINT } from './constants'
import Modal from 'react-modal'

const FontFamily = 'Lato, sans-serif;'

const Colors = {
    Black: '#000000',
    Grey100: '#F4F4F5',
    Grey200: '#EAEAEB',
    Grey300: '#D3D3D3',
    Grey400: '#8A8A93',
    Grey500: '#6C6C75',
    Grey600: '#4E4F55',
    Grey700: '#26262A',
    Grey730: '#1C2231',
    Grey750: '#1B1E2B',
    Grey800: '#0A0A0A',
    Ink: '#272727',
    'Darkest-Blue': '#0045C0',
    'Middle-Blue': '#1D60D8',
    Blue: '#1D60D8',
    'Blue-2': '#1D60D8',
    'Pale-Blue': '#8DAFEB',
    'Pale-Blue-2': '#8DAFEB',
    'Pale-Grey-Blue': '#E8EFFB',
    'Pale-Grey-Blue-2': '#E8EFFB',
    'Pale-Grey-Pink': '#FAE0E6',
    'Grey-Blue': '#E8EFFB',
    'Yellow-Orange': '#F0BB00',
    Yellow: '#FCF0C7',
    'Yellow-2': '#F0BB00',
    'Pale-Yellow': '#FBE6A2',
    'Dark-Purple': '#1B2231',
    'Purple-Grape': '#1B2231',
    'Purple-Blue': '#104195',
    'Purple-2': '#1B2231',
    'Purple-3': '#25307D',
    Pink: '#F978C2',
    'Pink-Dark': '#DB469C',
    'Pale-Pink': '#FCBBE1',
    Beige: '#F4F4F5',
    'Beige-2': '#E3E1DF',
    Green: '#B2F0B5',
    'Green-2': '#51B655',
    'Green-3': '#4F8351',
    'Grey-Green': '#232C23',
    'Grey-Green-2': '#676767',
    'Grey-Green-3': '#D0D0D0',
    White: '#FFFFFF',
    Error: '#C72800',
    Transparent: 'transparent'
}

const HeadingStyle = css`
    font-family: Cambon, sans-serif;
    font-weight: 700;
`

const TextStyle = css`
    font-family: Lato, sans-serif;
`

const FlexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const boxShadow = `0px 4px 24px rgba(0, 0, 0, .1)`
export const lightShadow = boxShadow

const Theme = {
    spacing(num: number) {
        return 5 * num
    },
    FontFamily,
    Colors,
    TextStyles: {
        H1_L: css`
            font-size: 64px;
            line-height: 1.119047619;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 40px;
            }
        `,
        H1_M: css`
            font-size: 48px;
            line-height: 1.119047619;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 40px;
            }
        `,
        H1_S: css`
            font-size: 40px;
            line-height: 1.1333333333;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 38px;
            }
        `,
        H2_XL: css`
            font-size: 36px;
            line-height: 1.2692307692;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 36px;
            }
        `,
        H2_L: css`
            font-size: 34px;
            line-height: 1.2692307692;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 34px;
            }
        `,
        H2_M: css`
            font-size: 30px;
            line-height: 1.2692307692;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 30px;
            }
        `,
        H2_S: css`
            font-size: 24px;
            ${HeadingStyle}
            @media screen and (max-width: ${BREAK_POINT}px) {
                font-size: 24px;
            }
        `,
        H3_L: css`
            font-size: 20px;
            line-height: 1.4;
            ${HeadingStyle}
        `,
        H3_M: css`
            font-size: 18px;
            line-height: 1.4;
            ${HeadingStyle}
        `,
        H3_S: css`
            font-size: 16px;
            line-height: 1.4;
            ${HeadingStyle}
        `,
        H4_M: css`
            font-size: 14px;
            line-height: 1.4;
            ${HeadingStyle}
        `,
        P_XL: css`
            font-weight: 500;
            font-size: 20px;
            line-height: 1.75;
            ${TextStyle}
        `,
        P_L: css`
            font-weight: normal;
            font-size: 18px;
            line-height: 1.75;
            ${TextStyle}
        `,
        P_M: css`
            font-weight: normal;
            font-size: 16px;
            line-height: 1.7777777778;
            ${TextStyle}
        `,
        P_S: css`
            font-weight: normal;
            font-size: 14px;
            line-height: 1.8;
            ${TextStyle}
        `,
        TEXT_LINK: css`
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5555555556;
            ${TextStyle}
        `,
        SMALL_CTA: css`
            font-weight: 600;
            font-size: 14px;
            line-height: 1.5555555556;
            cursor: pointer;
            ${TextStyle}
        `,
        LABEL: css`
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
            ${TextStyle}
        `,
        P_XS: css`
            font-size: 14px;
            line-height: 18px;
            ${TextStyle}
        `,
        FORM_LABEL: css`
            font-weight: 700;
            font-size: 14px;
            line-height: 1.6363636364;
            ${TextStyle}
        `
    },
    Layout: {
        FlexCenter
    }
}

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

Modal.setAppElement('#root')

export default Theme
