import { ComponentPropsWithoutRef, ReactNode, useState } from 'react'
import { Field, SelectInput, TextInput, TextAreaInput } from '.'
import { cx } from 'tusk-ui/utils'
import { InputVariantProps } from './inputs/Input'

export const InputWrapper = ({
    label,
    error,
    children,
    required,
    className
}: {
    label?: string | ReactNode
    required?: boolean
    error?: string
    children: ReactNode
    className?: string
}) => {
    return (
        <Field.Root className={className}>
            {label && <Field.Label required={required}>{label}</Field.Label>}
            {children}
            <div className='h-16'>{error && <Field.Error>{error}</Field.Error>}</div>
        </Field.Root>
    )
}

const FieldSelect = ({
    className,
    triggerClassName,
    dropdownClassName,
    label,
    placeholder,
    options,
    required,
    value,
    error,
    onChange,
    variant = 'input'
}: {
    className?: string
    triggerClassName?: string
    dropdownClassName?: string
    label?: string
    required?: boolean
    value?: string
    placeholder?: string
    options: { name: string; value: string }[]
    onChange?: (value: string) => void
    error?: string
} & InputVariantProps) => {
    const [open, setOpen] = useState(false)
    const [closing, setClosing] = useState(false)

    const toggleOpen = (open: boolean) => {
        if (open) {
            setOpen(true)
        } else {
            setClosing(true)
            setTimeout(() => {
                setOpen(false)
                setClosing(false)
            }, 300)
        }
    }

    return (
        <SelectInput.Root
            className={className}
            value={value}
            onValueChange={onChange}
            open={open}
            onOpenChange={toggleOpen}
        >
            <InputWrapper required={required} label={label} error={error}>
                <SelectInput.Trigger
                    invalid={!!error}
                    placeholder={placeholder}
                    className={triggerClassName}
                    closing={closing}
                    variant={variant}
                />
            </InputWrapper>
            <SelectInput.Dropdown className={dropdownClassName} closing={closing}>
                {options.map((option) => {
                    return (
                        <SelectInput.Option key={option.value} value={option.value}>
                            {option.name}
                        </SelectInput.Option>
                    )
                })}
            </SelectInput.Dropdown>
        </SelectInput.Root>
    )
}

interface FieldTextProps extends ComponentPropsWithoutRef<'input'> {
    className?: string
    label?: string
    required?: boolean
    error?: string
}
const FieldText = ({ className, label, required, value, error, ...restProps }: FieldTextProps) => {
    return (
        <InputWrapper required={required} label={label} error={error} className={className}>
            <TextInput required={required} value={value} invalid={!!error} {...restProps} />
        </InputWrapper>
    )
}

interface FieldUnitProps extends ComponentPropsWithoutRef<'input'> {
    unit: string
    min: number
    max: number
    step: number
}
const FieldUnit = ({ className, value, unit, min, max, ...restProps }: FieldUnitProps) => {
    return (
        <Field.Root className={cx(className, 'relative pr-16')}>
            {unit && <Field.Unit>{unit}</Field.Unit>}
            <TextInput
                min={min}
                max={max}
                type='number'
                className='w-fit min-w-[calc(100%+16px)] max-w-[130px] pr-64 mobile:w-full'
                value={value}
                {...restProps}
            />
        </Field.Root>
    )
}

interface FieldTextAreaProps extends ComponentPropsWithoutRef<typeof TextAreaInput> {
    className?: string
    label: string | ReactNode
    required?: boolean
    error?: string
}
const FieldTextArea = ({ className, label, required, value, error, ...restProps }: FieldTextAreaProps) => {
    return (
        <InputWrapper required={required} label={label} error={error} className={className}>
            <TextAreaInput required={required} value={value} invalid={!!error} {...restProps} />
        </InputWrapper>
    )
}

export { FieldSelect as Select, FieldText as Text, FieldTextArea as TextArea, FieldUnit as Unit }
