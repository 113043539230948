import { CMSJumpLink, CMSSection } from 'content/content.types'
import { Heading, Section, Container, Text, JumpLinkCard, Markdown } from 'tusk-ui'
import { useLocation } from 'react-router-dom'
import { cx } from 'tusk-ui/utils'

interface AnchorLinksProps extends CMSSection {
    mobileOnly?: boolean
    links: CMSJumpLink[]
}

export default function AnchorLinks({ heading, mobileOnly = false, links, ...sectionProps }: AnchorLinksProps) {
    const location = useLocation()

    return (
        <>
            <Section {...sectionProps} py='none' className='pt-64'>
                {!mobileOnly && (
                    <>
                        <Container width='sm' className='mb-64'>
                            <Heading.H2 className='text-center'>
                                <Markdown.Heading markdown={heading} />
                            </Heading.H2>
                        </Container>
                        <div className='bg-snow tablet:hidden'>
                            <Container className=''>
                                <div className='flex justify-between gap-16'>
                                    {links.map((link, i) => (
                                        <JumpLinkCard
                                            key={i}
                                            to={link.href}
                                            IconComponent={link.icon}
                                            className='flex-1'
                                            active={location.hash === link.href}
                                        >
                                            {link.text}
                                        </JumpLinkCard>
                                    ))}
                                </div>
                            </Container>
                        </div>
                    </>
                )}
            </Section>
            <div
                className={cx('sticky z-30 hidden border-b border-night-100 bg-snow mobile:block', {
                    'top-[var(--mmth-nav-container-height)] tablet:block': !mobileOnly
                })}
            >
                <Container width='flush' className='py-8 tablet:pl-64 mobile:pl-16'>
                    <div className='flex items-center justify-between gap-16'>
                        <Text variant='l2' className='whitespace-nowrap'>
                            Jump To:
                        </Text>
                        <div className='no-scrollbar flex snap-x snap-mandatory gap-8 overflow-auto'>
                            {links.map((link, i) => (
                                <JumpLinkCard
                                    key={i}
                                    to={link.href}
                                    size='sm'
                                    active={location.hash === link.href}
                                    className='snap-start snap-always'
                                >
                                    {link.text}
                                </JumpLinkCard>
                            ))}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
