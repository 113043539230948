import { useCallback, useEffect } from 'react'
import { Text } from 'tusk-ui'
import Modal from 'react-modal'
import { PopupStateContext, PopupDispatchContext, PopupProvider } from '../../contexts/PopupContext'
import Close from '../icons/Close'
import { useLocation } from 'react-router-dom'
import {
    BoxProps,
    Brand,
    ModalChallengeStep,
    FinalChallengeStep,
    Project,
    PropsWithChildren,
    Reward,
    RewardTypes,
    SubscriptionType,
    User
} from '../../types'
import { useAnalytics } from '../../analytics'
import { useDevice, usePopup, useSetPopup } from '../../contexts'
import Box from '../core/Layout/Box'
import { ChatAppContentModel } from '../../models/ChatAppContent'
import { RedemptionModalModel, RedemptionSuccessModalModel } from '../../models/PointRedemptionsPageModels'
import { FilterType } from '../popups/FiltersPopup'
import { Course } from 'models/Course'

export { PopupStateContext }
export { PopupDispatchContext }
export { PopupProvider }

type Empty = Record<string, never>
type WithUser = {
    user: User
}
export type PopupTypeWithData = {
    ADD_TEAMMATES: Empty
    CARBON_FOOTPRINT: Empty
    FOOTPRINT_COMPARISON: Empty
    FOOTPRINT_BREAKDOWN: Empty
    EMISSIONS_BREAKDOWN: Empty
    UPDATE_PLAN: {
        oldFootprint?: string
        newFootprint?: string
        isOrg?: boolean
        periodEnd?: string
        plan?: SubscriptionType
        percent?: number
    }
    MAKE_ADMIN: WithUser
    MAKE_OWNER: WithUser
    MAKE_MEMBER: WithUser
    DELETE_MEMBER: WithUser
    VIEW_SCOPE_3_HISTORY: WithUser
    UPDATE_ORG_PAYMENT_POPUP: Empty
    DELETE_ORG_PENDING: WithUser
    SHARE_JOURNEY: Empty
    B2B_LEAD_GEN: Empty
    COURSE_EXAMPLE_POPUP: Course
    REQUEST_CUSTOM_MODULE_POPUP: Empty
    CONTENT_EXAMPLE_POPUP: ChatAppContentModel
    SAVE_FREQUENCY_SETTINGS: Empty
    TELL_US_FAVORITE: Empty
    REMOVE_SLACK_POPUP: Empty
    REDEMPTION_POPUP: {
        contents?: RedemptionModalModel
        reward?: Reward
        project?: Project
        brand?: Brand
        currentPoints?: number
        onComplete?: (pointsCost: number, rewardValue: number) => void
    }
    REDEMPTION_SUCCESS_POPUP: {
        contents?: RedemptionSuccessModalModel
        rewardType?: RewardTypes
        project?: Project
        brand?: Brand
        pointsCost?: number
        rewardValue?: number
    }
    FILTERS_POPUP: {
        heading: string
        filters: FilterType[]
        applyButton?: string
        onApply?: () => void
    }
    CONFIRM_CHALLENGE_COMPLETION_POPUP: {
        step: FinalChallengeStep
        onSubmit?: (args: { advice: string; experience: string; rating: string; feedback: string }) => void
    }
    MODAL_CHALLENGE_STEP_POPUP: {
        modalChallengeStep: ModalChallengeStep
        onSubmit?: () => void
    }
    ORG_LANGUAGE_CONFIRM_POPUP: {
        onSubmit: () => void
    }
    UNSUBSCRIBE: Empty
    LESSON_FEEDBACK: Empty
    PERSEONALIZE_YOUR_EXPERIENCE: Empty
    CALCULATION_REMINDER: Empty
    EXPERIENCE_PERSONALIZED: Empty
    CLIMATE_CLASS_FILTERS_POPUP: Empty
}

export type PopupTypes = keyof PopupTypeWithData

interface AppModalProps extends PropsWithChildren {
    Type: PopupTypes
    Heading?: string
    closeEventName?: string
    disableClose?: boolean
    onClose?: () => void

    maxWidth?: number | string
    maxHeight?: number | string
    height?: number | string
    width?: number | string

    contentStyle?: Omit<React.CSSProperties, 'maxWidth' | 'maxHeight' | 'height' | 'width'> // Prevent passing both as a direct prop and as a contentStyleProp
    overlayStyle?: React.CSSProperties
    contentProps?: BoxProps

    mobileFullScreen?: boolean
    closeOnNavigate?: boolean
}

const AppModal = ({
    children,
    Type,
    Heading,
    disableClose,
    closeEventName,
    maxWidth,
    maxHeight,
    height,
    width,
    contentProps = {},
    contentStyle = {},
    overlayStyle = {},
    mobileFullScreen,
    closeOnNavigate = true,

    ...props
}: AppModalProps) => {
    const setIsOpen = useSetPopup()
    const analytics = useAnalytics()
    const { pathname } = useLocation()
    const popupState = usePopup()

    const isOpen = Object.keys(popupState || {}).includes(Type)

    const onClose = useCallback(
        (track: boolean) => {
            props.onClose && props?.onClose()
            if (disableClose || !isOpen) return
            setIsOpen(null)
            if (track) analytics.track('Close Popup', { Name: Type })
        },
        [disableClose, setIsOpen, analytics, closeEventName, props, isOpen]
    )

    const { mobile, tablet } = useDevice()

    useEffect(() => {
        if (isOpen && closeOnNavigate) onClose(false)
    }, [pathname, closeOnNavigate])

    const tabletPadding = mobile && mobileFullScreen ? 0 : 20

    return (
        <Modal
            isOpen={!!isOpen}
            style={{
                content: {
                    border: 'none',
                    borderRadius: mobile && mobileFullScreen ? 0 : 30,
                    position: 'initial',
                    maxWidth: maxWidth || (tablet ? `calc(100vw - ${2 * tabletPadding}px)` : 995),
                    marginRight: tablet ? tabletPadding : 'auto',
                    marginLeft: tablet ? tabletPadding : 'auto',
                    marginTop: tablet ? tabletPadding : 50,
                    height: mobile && mobileFullScreen ? '100vh' : height,
                    width,
                    padding: 0,
                    animation: 'zoomIn .5s ease-in-out forwards',
                    maxHeight: maxHeight || `calc(100vh - ${tablet ? 2 * tabletPadding : 100}px)`,
                    ...contentStyle
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, .10)',
                    zIndex: 21,
                    animation: 'fadeIn .25s ease-in-out forwards',
                    height: 'calc(var(--vh, 1vh) * 100)',
                    ...overlayStyle
                }
            }}
            onRequestClose={() => onClose(true)}
        >
            {!disableClose && (
                <Box position='sticky' top={0} display='flex' zIndex={1} justifyContent={'flex-end'}>
                    <Box onClick={() => onClose(true)} p={8} cursor='pointer' position='absolute'>
                        <Close mobile={mobile} />
                    </Box>
                </Box>
            )}
            <Box p={mobile ? 6 : 12} pt={mobile ? 8 : undefined} {...contentProps}>
                {Heading && (
                    <Box pb={6}>
                        <Text variant='h3'>
                            <h2>{Heading}</h2>
                        </Text>
                    </Box>
                )}
                {children}
            </Box>
        </Modal>
    )
}

export default AppModal
