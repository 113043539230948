import { Container, Heading, Paragraph, Section } from 'tusk-ui'
import SectionBlockRender from '../../components/SectionBlockRender'
import PageContent from '../PageContent'
export default function PageBase({ path }: { path: string }) {
    return (
        <PageContent path={path}>
            {({ data }: { data: any }) => (
                <>
                    <Section scheme='night'>
                        <Container>
                            <Heading.H1 variant='d2' className='mb-40'>
                                {data.heading}
                            </Heading.H1>
                            <Paragraph variant='b2'>{data.subheading}</Paragraph>
                        </Container>
                    </Section>
                    <SectionBlockRender sections={data.sections} />
                </>
            )}
        </PageContent>
    )
}
