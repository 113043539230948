import styled from 'styled-components'
import { BREAK_POINT, TABLET_BREAK_POINT } from '../../../constants'

// will hade width equivalent to the padding+margin on one side
export const OneSidePaddingBox = styled.div`
    flex-shrink: 0;
    min-width: 5rem;
    width: calc(((100vw - 1600px) / 2) + 5rem);

    @media screen and (max-width: ${TABLET_BREAK_POINT}px) {
        min-width: 3rem;
        width: 3rem;
    }

    @media screen and (max-width: ${BREAK_POINT}px) {
        min-width: 20px;
        width: 20px;
    }
`

const PagePadding = styled.div`
    padding-right: 5rem;
    padding-left: 5rem;
    max-width: calc(1600px - 10rem);
    margin: auto;

    @media screen and (max-width: ${TABLET_BREAK_POINT}px) {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const oneSideNarrowPadding = (mobile: boolean, tablet: boolean) => (mobile ? '20px' : tablet ? '3rem' : '80px')

export const NarrowPagePadding = styled.div`
    padding-right: 80px;
    padding-left: 80px;
    max-width: calc(1600px - 160px);
    margin: auto;

    @media screen and (max-width: ${TABLET_BREAK_POINT}px) {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const PagePaddingRight = styled.div`
    padding-right: 5rem;
    max-width: calc(800px - 5rem);
    margin-right: auto;

    @media screen and (max-width: ${TABLET_BREAK_POINT}px) {
        padding-right: 3rem;
        padding-left: 3rem;
        margin-right: initial;
    }

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const PagePaddingLeft = styled.div`
    padding-left: 5rem;
    max-width: calc(800px - 5rem);
    margin-left: auto;

    @media screen and (max-width: ${TABLET_BREAK_POINT}px) {
        padding-right: 3rem;
        padding-left: 3rem;
        margin-left: initial;
    }

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const HeaderPadding = styled(PagePadding)`
    padding-top: 6.666rem;

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-top: 5rem;
    }
`

export const LargeSectionPadding = styled(HeaderPadding)`
    padding-bottom: 6.666rem;

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-bottom: 5rem;
    }
`

export const MediumSectionPadding = styled(PagePadding)`
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media screen and (max-width: ${BREAK_POINT}px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
`

export default PagePadding
