import * as React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import {
    type EmblaCarouselType as CarouselApi,
    type EmblaOptionsType as CarouselOptions,
    type EmblaPluginType as CarouselPlugin
} from 'embla-carousel'

import { cx } from 'tusk-ui/utils'
import { PlayArrow, Pause } from '../Icon'

type CarouselProps = {
    opts?: CarouselOptions
    plugins?: CarouselPlugin[]
    setApi?: (api: CarouselApi) => void
}

type CarouselContextProps = {
    carouselRef: ReturnType<typeof useEmblaCarousel>[0]
    api: ReturnType<typeof useEmblaCarousel>[1]
    scrollPrev: () => void
    scrollNext: () => void
    canScrollPrev: boolean
    canScrollNext: boolean
} & CarouselProps

const CarouselContext = React.createContext<CarouselContextProps | null>(null)

export function useCarousel() {
    const context = React.useContext(CarouselContext)

    if (!context) {
        throw new Error('useCarousel must be used within a <Carousel />')
    }

    return context
}

const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
    ({ opts, setApi, plugins, className, children, ...props }, ref) => {
        const [carouselRef, api] = useEmblaCarousel(
            {
                ...opts,
                align: 'start',
                axis: 'x'
            },
            plugins
        )
        const [canScrollPrev, setCanScrollPrev] = React.useState(false)
        const [canScrollNext, setCanScrollNext] = React.useState(false)

        const onSelect = React.useCallback((api: CarouselApi) => {
            if (!api) {
                return
            }

            setCanScrollPrev(api.canScrollPrev())
            setCanScrollNext(api.canScrollNext())
        }, [])

        const scrollPrev = React.useCallback(() => {
            api?.scrollPrev()
        }, [api])

        const scrollNext = React.useCallback(() => {
            api?.scrollNext()
        }, [api])

        const handleKeyDown = React.useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'ArrowLeft') {
                    event.preventDefault()
                    scrollPrev()
                } else if (event.key === 'ArrowRight') {
                    event.preventDefault()
                    scrollNext()
                }
            },
            [scrollPrev, scrollNext]
        )

        React.useEffect(() => {
            if (!api || !setApi) {
                return
            }

            setApi(api)
        }, [api, setApi])

        React.useEffect(() => {
            if (!api) {
                return
            }

            onSelect(api)
            api.on('reInit', onSelect)
            api.on('select', onSelect)

            return () => {
                api?.off('select', onSelect)
            }
        }, [api, onSelect])

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    opts,
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext
                }}
            >
                <div
                    ref={ref}
                    onKeyDownCapture={handleKeyDown}
                    className={cx('relative', className)}
                    role='region'
                    aria-roledescription='carousel'
                    {...props}
                >
                    {children}
                </div>
            </CarouselContext.Provider>
        )
    }
)
Carousel.displayName = 'Carousel'

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const { carouselRef } = useCarousel()

        return (
            <div ref={carouselRef} className='h-full w-full overflow-hidden'>
                <div ref={ref} className={cx('-ml-24 flex', className)} {...props} />
            </div>
        )
    }
)
CarouselContent.displayName = 'CarouselContent'

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                role='group'
                aria-roledescription='slide'
                className={cx('min-w-0 shrink-0 grow-0 basis-[80%] pl-24', className)}
                {...props}
            />
        )
    }
)
CarouselItem.displayName = 'CarouselItem'

const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<'button'>>(
    ({ className, ...props }, ref) => {
        const { scrollPrev, canScrollPrev } = useCarousel()

        return (
            <button
                ref={ref}
                className={cx('absolute  -left-12 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full', className)}
                disabled={!canScrollPrev}
                onClick={scrollPrev}
                {...props}
            >
                {'<'}
                <span className='sr-only'>Previous slide</span>
            </button>
        )
    }
)
CarouselPrevious.displayName = 'CarouselPrevious'

const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<'button'>>(
    ({ className, ...props }, ref) => {
        const { scrollNext, canScrollNext } = useCarousel()

        return (
            <button
                ref={ref}
                className={cx('absolute -right-12 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full', className)}
                disabled={!canScrollNext}
                onClick={scrollNext}
                {...props}
            >
                {'>'}
                <span className='sr-only'>Next slide</span>
            </button>
        )
    }
)
CarouselNext.displayName = 'CarouselNext'

const CarouselControl = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const circleRef = React.createRef<SVGCircleElement>()
        const animationRef = React.useRef<Animation>()
        const { api } = useCarousel()
        const [paused, setPaused] = React.useState(false)

        const autoplay = api?.plugins()?.autoplay
        const duration = React.useMemo(() => autoplay?.options?.delay, [autoplay])

        const toggleAutoplay = () => {
            if (autoplay?.isPlaying()) {
                autoplay?.stop()
                animationRef?.current?.pause()
            } else {
                autoplay?.play()
                animationRef?.current?.finish()
            }
            setPaused(!autoplay?.isPlaying())
        }

        React.useEffect(() => {
            const circle = circleRef?.current
            if (!circle || animationRef.current || !duration) return

            const circumference = circle.r.baseVal.value * 2 * Math.PI
            animationRef.current = circle.animate(
                [
                    { strokeDasharray: circumference, strokeDashoffset: 0 },
                    { strokeDasharray: circumference, strokeDashoffset: circumference }
                ],
                { fill: 'forwards', duration, easing: 'linear' }
            )

            animationRef?.current?.addEventListener('finish', () => animationRef?.current?.play())
        }, [circleRef?.current, duration])

        return (
            <div
                ref={ref}
                className={cx('absolute flex cursor-pointer items-center justify-center', className)}
                onClick={toggleAutoplay}
                {...props}
            >
                <svg width='40' height='40'>
                    <circle
                        ref={circleRef}
                        className='stroke-theme-base stroke-[4px] transition-all duration-[250ms] ease-linear'
                        fill='transparent'
                        r='17.65'
                        cx='20'
                        cy='20'
                    />
                </svg>
                <div className='absolute flex items-center justify-center'>
                    {paused ? <PlayArrow className='fill-theme-base' /> : <Pause className='fill-theme-base' />}
                </div>
            </div>
        )
    }
)
CarouselControl.displayName = 'CarouselControl'

const CarouselIndicators = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const { api } = useCarousel()
        const [current, setCurrent] = React.useState(0)
        const [count, setCount] = React.useState(0)

        React.useEffect(() => {
            if (!api) {
                return
            }

            setCount(api.scrollSnapList().length)
            setCurrent(api.selectedScrollSnap() + 1)

            api.on('select', () => {
                setCurrent(api.selectedScrollSnap() + 1)
            })
        }, [api])

        return (
            <div ref={ref} className={cx('flex justify-center gap-16 py-32', className)} {...props}>
                {Array.from({ length: count }).map((_, index) => (
                    <div
                        key={`${_}${index}`}
                        className={cx('h-8 w-8 rounded-full border border-theme-base', {
                            'bg-theme-inverse': current === index + 1
                        })}
                    />
                ))}
            </div>
        )
    }
)

export {
    type CarouselApi,
    Carousel as Root,
    CarouselContent as Content,
    CarouselItem as Item,
    CarouselPrevious as Previous,
    CarouselNext as Next,
    CarouselControl as Control,
    CarouselIndicators as Indicators
}
