import { AwsRum } from 'aws-rum-web'
import env from './env/env.json'

let awsRum: AwsRum | null = null

export default function setupAwsRum() {
    try {
        const { applicationId, guestRoleArn, identityPoolId } = env.AWS_RUM_CONFIG

        awsRum = new AwsRum(applicationId, '1.0.0', 'us-west-2', {
            sessionSampleRate: 1,
            guestRoleArn,
            identityPoolId,
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: false
        })
    } catch (error) {
        console.error('Error initializing CloudWatch RUM web client', error)
    }
}

export function getAwsRum() {
    return awsRum
}
