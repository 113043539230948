import { useWindowSize } from 'react-use'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const twConfig = resolveConfig(tailwindConfig)

type ScreenType = { max: string }
type TwScreens = {
    'desktop-sm': ScreenType
    tablet: ScreenType
    mobile: ScreenType
}

export const useTwDevice = () => {
    const { width } = useWindowSize()
    // @ts-ignore
    const twScreens = twConfig.theme.screens as TwScreens
    return {
        desktopSm: width <= parseInt(twScreens['desktop-sm'].max),
        tablet: width <= parseInt(twScreens.tablet.max),
        mobile: width <= parseInt(twScreens.mobile.max)
    }
}
