import { cx } from 'tusk-ui/utils'
import Text, { TextProps } from './Text/Text'

const Html = ({ children, className, ...props }: { children: React.ReactNode } & TextProps) => {
    return (
        <Text
            className={cx(
                '[&_a]:font-primary [&_a]:underline [&_b1]:font-primary [&_b1]:text-2xl [&_b1]:font-bold [&_b2]:font-primary [&_b2]:text-xl [&_b2]:font-bold [&_b3]:font-primary [&_b3]:text-base [&_b3]:font-bold [&_b4]:font-primary [&_b4]:text-sm [&_b4]:font-bold [&_blockquote]:border-l-2 [&_c1]:font-primary [&_c1]:text-xs [&_c1]:font-bold [&_h1]:mb-88 [&_h1]:font-secondary [&_h1]:text-5xl [&_h1]:font-bold [&_h2]:mb-32 [&_h2]:font-secondary [&_h2]:text-4xl [&_h2]:font-bold [&_h3]:mb-32 [&_h3]:font-secondary [&_h3]:text-[1.75rem] [&_h3]:font-bold [&_h4]:mb-16 [&_h4]:font-secondary [&_h4]:text-[1.75rem] [&_h4]:font-bold [&_h5]:mb-16 [&_h5]:font-primary [&_h5]:text-2xl [&_h5]:font-bold [&_h6]:mb-16 [&_h6]:font-primary [&_h6]:text-xl [&_h6]:font-bold [&_h7]:font-primary [&_h7]:text-lg [&_h7]:font-bold [&_img]:rounded-xl [&_l1]:font-primary [&_l1]:text-lg [&_l1]:font-bold [&_l2]:font-primary [&_l2]:text-base [&_l2]:font-bold [&_l3]:font-primary [&_l3]:text-sm [&_l3]:font-bold [&_li]:my-12 [&_li]:ml-8 [&_ol]:mb-24 [&_ol]:list-inside [&_ol]:list-decimal [&_p]:my-16 [&_ul]:mb-24 [&_ul]:list-inside [&_ul]:list-disc',
                className
            )}
            {...props}
        >
            {children}
        </Text>
    )
}
export default Html
