import { ElementPrimitive } from 'tusk-ui'
import { cva, VariantProps } from 'class-variance-authority'
import { ComponentPropsWithoutRef } from 'react'
import { cx } from 'tusk-ui/utils'

export const pillVariants = cva('w-max py-4 px-16 rounded-full font-primary text-base font-bold leading-[150%]', {
    variants: {
        variant: {
            night: ['bg-night-300 text-snow'],
            snow: ['bg-snow text-night']
        }
    }
})

type PillVariantProps = VariantProps<typeof pillVariants>

export type PillVariants = PillVariantProps['variant']

export interface PillProps extends ComponentPropsWithoutRef<typeof ElementPrimitive.Div>, PillVariantProps {}

const Pill = ({ className, variant, children, ...rest }: PillProps) => (
    <div className={cx('text-theme-base', pillVariants({ variant }), className)} {...rest}>
        {children}
    </div>
)

export default Pill
