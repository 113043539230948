import { PageBanner, PageBase } from './pages/templates'
import Logout from './pages/Logout'
import SendChatAppMessage from './pages/SendChatAppMessage'
import { Navigate } from 'react-router-dom'

export const publicRoutes = [
    Object.freeze({
        index: true,
        path: '',
        element: <PageBase path='home' />
    }),
    Object.freeze({
        path: 'product',
        element: <PageBase path='product' />
    }),
    Object.freeze({
        path: 'company',
        element: <PageBase path='company' />
    }),
    Object.freeze({
        path: 'customer-stories',
        element: <PageBase path='customer-stories' />
    }),
    Object.freeze({
        path: 'customer-stories/:url',
        lazy: async () => {
            const Component = await import('./pages/CustomerStory')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'slack-landing-page',
        element: <PageBase path='slack-landing-page' />
    }),
    Object.freeze({
        path: 'faq',
        element: <PageBanner path='faq' />
    }),
    Object.freeze({
        path: 'terms',
        element: <PageBanner path='terms' />
    }),
    Object.freeze({
        path: 'privacy',
        element: <PageBanner path='privacy' />
    }),
    Object.freeze({
        path: 'cookies-policy',
        element: <PageBanner path='cookies-policy' />
    }),
    Object.freeze({
        path: 'brand-app-privacy',
        element: <PageBanner path='brand-app-privacy' />
    }),
    Object.freeze({
        path: 'security-commitment',
        element: <PageBanner path='security-commitment' />
    }),
    Object.freeze({
        path: 'reserve-demo',
        lazy: async () => {
            const Component = await import('./pages/ReserveDemo')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'login',
        lazy: async () => {
            const Component = await import('./pages/Login')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'contact',
        lazy: async () => {
            const Component = await import('./pages/Contact')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'forgot-password',
        lazy: async () => {
            const Component = await import('./pages/ForgotPassword')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'reset-password',
        lazy: async () => {
            const Component = await import('./pages/ResetPassword')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'blog',
        lazy: async () => {
            const Component = await import('./pages/Blog/Blog')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'blog/:slug',
        lazy: async () => {
            const Component = await import('./pages/Blog/BlogArticle')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'no-org-access',
        lazy: async () => {
            const Component = await import('../pages/Public/Error/AuthErrorPage')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'logout',
        element: <Logout />
    }),
    Object.freeze({
        path: 'email-verification/:email',
        lazy: async () => {
            const Component = await import('./pages/EmailVerification')
            return { Component: Component.default }
        }
    })
]

export const memberRoutes = [
    Object.freeze({
        path: 'send-chat-app-message',
        element: <SendChatAppMessage />
    }),
    Object.freeze({
        path: 'quiz/:id',
        lazy: async () => {
            const Component = await import('./pages/Quiz')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'feedback',
        lazy: async () => {
            const Component = await import('./pages/Feedback')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'account-settings',
        lazy: async () => {
            const Component = await import('../pages/Members/AccountSettings/AccountSettings')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'climate-class',
        lazy: async () => {
            const Component = await import('../pages/Members/ClimateClass/ClimateClass')
            return { Component: Component.default }
        }
    })
]

export const noBannersMemberRoutes = [
    Object.freeze({
        path: 'onboarding-calculate-entry',
        lazy: async () => {
            const Component = await import('./pages/CalculatorEntry/OnboardingCalculatorEntry')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'quarterly-calculator-entry',
        element: <Navigate to={'/quarterly-calculate-entry'} replace />
    }),
    Object.freeze({
        path: 'quarterly-calculate-entry',
        lazy: async () => {
            const Component = await import('./pages/CalculatorEntry/QuarterlyCalculatorEntry')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'migration-calculate-entry',
        lazy: async () => {
            const Component = await import('./pages/CalculatorEntry/MigrationCalculatorEntry')
            return { Component: Component.default }
        }
    })
]

export const noBannersPublicRoutes = [
    Object.freeze({
        path: 'signup',
        lazy: async () => {
            const Component = await import('./pages/Signup')
            return { Component: Component.default }
        }
    })
]

export const noNavMemberRoutes = [
    Object.freeze({
        path: 'quarterly-calculator',
        element: <Navigate to={'/calculate/work'} replace />
    }),
    Object.freeze({
        path: 'calculate/:type',
        lazy: async () => {
            const Component = await import('./pages/Calculator/Calculator')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'calculate-submission/:type',
        lazy: async () => {
            const Component = await import('./pages/CalculatorSubmission/CalculatorSubmission')
            return { Component: Component.default }
        }
    }),
    Object.freeze({
        path: 'onboarding/:step',
        lazy: async () => {
            const Component = await import('./pages/Onboarding/Onboarding')
            return { Component: Component.default }
        }
    })
]
