import { useEffect } from 'react'
import { useAnalytics } from '../../../analytics'
import { Image, PropsWithChildren } from '../../../types'
import { useLocation, useNavigationType } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getUrlSearchParams } from '../../../util'

interface PageProps extends PropsWithChildren {
    SEO_Title?: string
    SEO_Image?: Image | string
    SEO_Description?: string
    disableScrollSnap?: boolean
}
const Page = ({
    SEO_Title = 'Mammoth Climate',
    SEO_Description = 'Save money for fighting climate change. Estimate and offset your carbon footprint, then reduce it with year-round savings at sustainable brands.',
    SEO_Image,
    children,
    disableScrollSnap
}: PageProps) => {
    const analytics = useAnalytics()
    const location = useLocation()
    const navigationType = useNavigationType()
    const { search, hash } = location

    useEffect(() => {
        analytics.track('Page Visit', {})
    }, [analytics])

    useEffect(() => {
        const { referral } = getUrlSearchParams(search)
        const isGoingBackInHistory = navigationType === 'POP'
        if (referral || hash || disableScrollSnap || isGoingBackInHistory) return
        window.scrollTo(0, 0)
    }, [SEO_Title, SEO_Description, disableScrollSnap, search, hash, navigationType])

    return (
        <>
            <Helmet>
                <title>{SEO_Title}</title>
                <meta name='description' content={SEO_Description} />
                <meta property='og:title' content={SEO_Title} />
                <meta property='og:description' content={SEO_Description} />
                <meta property='og:type' content='website' />
                {SEO_Image && (
                    <meta
                        property='og:image'
                        content={typeof SEO_Image === 'string' ? SEO_Image : SEO_Image?.data?.attributes?.url}
                    />
                )}
            </Helmet>
            {children}
        </>
    )
}

export default Page
