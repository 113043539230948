import { forwardRef, ComponentPropsWithoutRef, ElementRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ElementPrimitive } from 'tusk-ui'
import { textVariants } from '../Text/Text'

export const buttonVariants = cva(
    'focus-ring inline-flex items-center justify-center whitespace-nowrap rounded-md transition-colors duration-300 disabled:text-snow-300 border-2 mobile:whitespace-normal text-center mobile:w-full box-border focus:ring-1 focus:ring-offset-[3px] focus:ring-theme-base',
    {
        variants: {
            variant: {
                primary: [
                    'border-sun bg-sun text-night hover:border-theme-dark-hover hover:bg-theme-dark-hover hover:text-sun disabled:bg-snow-100 disabled:border-snow-100'
                ],
                secondary: [
                    'border-theme-base text-theme-base hover:text-snow hover:border-theme-dark-hover hover:bg-theme-dark-hover disabled:border-snow-200'
                ],
                tertiary: [
                    'bg-theme-base border-theme-tertiary text-theme-base hover:text-snow hover:border-theme-dark-hover hover:bg-theme-dark-hover disabled:bg-snow'
                ]
            },
            size: {
                sm: cx(textVariants({ variant: 'l2' }), 'px-24 py-8'),
                md: cx(textVariants({ variant: 'l1' }), 'px-32 py-12')
            }
        },
        defaultVariants: {
            variant: 'primary',
            size: 'md'
        }
    }
)

export interface ButtonProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Button>,
        VariantProps<typeof buttonVariants> {}

const Button = forwardRef<ElementRef<typeof ElementPrimitive.Button>, ButtonProps>((props, ref) => {
    const { className, size, variant, ...restProps } = props

    return (
        <ElementPrimitive.Button
            ref={ref}
            className={cx(buttonVariants({ variant, size }), className)}
            {...restProps}
        />
    )
})

export default Button
