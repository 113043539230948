import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ColorScheme, ElementPrimitive } from 'tusk-ui'

const cardVariants = cva('rounded-xl', {
    variants: {
        padding: {
            flush: '',
            sm: 'p-24',
            md: 'p-40 mobile:p-24',
            lg: 'p-64 mobile:p-24'
        }
    },
    defaultVariants: {
        padding: 'md'
    }
})

export interface CardProps extends ComponentPropsWithoutRef<typeof ColorScheme>, VariantProps<typeof cardVariants> {}

const Card = forwardRef<ElementRef<typeof ElementPrimitive.Div>, CardProps>((props, ref) => {
    const { className, scheme = 'river', padding, ...restProps } = props

    return <ColorScheme ref={ref} scheme={scheme} className={cx(cardVariants({ padding }), className)} {...restProps} />
})

export default Card
