import { ReactNode } from 'react'
import { List } from 'tusk-ui'

type IconListProps = {
    className?: string
    items: {
        text: string
        icon: ReactNode
    }[]
    size?: 'sm' | 'md'
}

export default function IconList(props: IconListProps) {
    const { items, className, size = 'md' } = props
    return (
        <List.Root className={className}>
            {items.map(({ text, icon }, index) => (
                <List.Item key={`${text}${index}`}>
                    <div className='w-24'>{icon && <>{icon}</>}</div>
                    <List.Text variant={size === 'sm' ? 'b3' : 'h6'}>{text}</List.Text>
                </List.Item>
            ))}
        </List.Root>
    )
}
