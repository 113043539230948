import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import Button from './Button'
import { Spinner } from 'tusk-ui'

const ButtonLoading = forwardRef<
    ElementRef<typeof Button>,
    ComponentPropsWithoutRef<typeof Button> & {
        loading?: boolean
        loadingText?: string
    }
>(({ loading, loadingText, children, disabled, ...restProps }, ref) => {
    return (
        <Button ref={ref} {...restProps} disabled={disabled || loading}>
            {loading && <Spinner />}
            {loading && loadingText ? loadingText : children}
        </Button>
    )
})

export default ButtonLoading
