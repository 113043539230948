import useUser from 'gql/useUser'
import i18next from 'i18next'
import { supportedLngs } from 'locales'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams, Location } from 'react-router-dom'
import { User } from 'types'

const allLanguages = [
    'aa',
    'ab',
    'ae',
    'af',
    'ak',
    'am',
    'an',
    'ar',
    'as',
    'av',
    'ay',
    'az',
    'ba',
    'be',
    'bg',
    'bi',
    'bm',
    'bn',
    'bo',
    'br',
    'bs',
    'ca',
    'ce',
    'ch',
    'co',
    'cr',
    'cs',
    'cu',
    'cv',
    'cy',
    'da',
    'de',
    'dv',
    'dz',
    'ee',
    'el',
    'en',
    'eo',
    'es',
    'et',
    'eu',
    'fa',
    'ff',
    'fi',
    'fj',
    'fo',
    'fr',
    'fy',
    'ga',
    'gd',
    'gl',
    'gn',
    'gu',
    'gv',
    'ha',
    'he',
    'hi',
    'ho',
    'hr',
    'ht',
    'hu',
    'hy',
    'hz',
    'ia',
    'id',
    'ie',
    'ig',
    'ii',
    'ik',
    'io',
    'is',
    'it',
    'iu',
    'ja',
    'jv',
    'ka',
    'kg',
    'ki',
    'kj',
    'kk',
    'kl',
    'km',
    'kn',
    'ko',
    'kr',
    'ks',
    'ku',
    'kv',
    'kw',
    'ky',
    'la',
    'lb',
    'lg',
    'li',
    'ln',
    'lo',
    'lt',
    'lu',
    'lv',
    'mg',
    'mh',
    'mi',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms',
    'mt',
    'my',
    'na',
    'nb',
    'nd',
    'ne',
    'ng',
    'nl',
    'nn',
    'no',
    'nr',
    'nv',
    'ny',
    'oc',
    'oj',
    'om',
    'or',
    'os',
    'pa',
    'pi',
    'pl',
    'ps',
    'pt',
    'qu',
    'rm',
    'rn',
    'ro',
    'ru',
    'rw',
    'sa',
    'sc',
    'sd',
    'se',
    'sg',
    'si',
    'sk',
    'sl',
    'sm',
    'sn',
    'so',
    'sq',
    'sr',
    'ss',
    'st',
    'su',
    'sv',
    'sw',
    'ta',
    'te',
    'tg',
    'th',
    'ti',
    'tk',
    'tl',
    'tn',
    'to',
    'tr',
    'ts',
    'tt',
    'tw',
    'ty',
    'ug',
    'uk',
    'ur',
    'uz',
    've',
    'vi',
    'vo',
    'wa',
    'wo',
    'xh',
    'yi',
    'yo',
    'za',
    'zh',
    'zu'
]

const unsupportedLanguages = allLanguages.filter((lang) => !supportedLngs.includes(lang))

const generatePath = ({ pathname, search, hash }: Location) => {
    const [language, ...rest] = pathname.slice(1).split('/')
    if (supportedLngs.includes(language)) return pathname
    if (unsupportedLanguages.includes(language)) return `/${i18next.language}/${rest.join('/')}${search}${hash}`
    return `/${i18next.language}${pathname}${search}${hash}`
}

const getForcedLanguagePath = (location: Location, user: User) => {
    let pathname = location.pathname
    const [_, ...rest] = location.pathname.slice(1).split('/')
    pathname = rest.join('/')
    return `/${user.language}/${pathname}${location.search}${location.hash}`
}

export const useLanguageRedirect = () => {
    const { language = '' } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { data: user } = useUser()

    useEffect(() => {
        if (user && user.language && supportedLngs.includes(language) && language !== user.language) {
            i18next.changeLanguage(user.language)
            navigate(getForcedLanguagePath(location, user), { replace: true })
            return
        }
        if (!supportedLngs.includes(language)) {
            navigate(generatePath(location), { replace: true })
            return
        }
    }, [language, user])
}
