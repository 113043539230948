import { gql } from '@apollo/client'
import { SpinnerPage } from 'components'
import { useCustomMutation } from 'gql/clients'
import useUser from 'gql/useUser'
import { Button, Text } from 'tusk-ui'

const SEND_GROUP_MESSAGE = gql`
    mutation SendGroupMessage {
        sendChatAppGroupMessage {
            code
        }
    }
`
const SEND_DIRECT_MESSAGE = gql`
    mutation SendDirectMessage {
        sendChatAppDirectMessage {
            code
        }
    }
`

const SendChatAppMessage = () => {
    const { data: user, loading: loadingUser } = useUser()
    const [sendGroupMessage, { loading: loadingSendGroup }] = useCustomMutation(SEND_GROUP_MESSAGE)
    const [sendDirectMessage, { loading: loadingSendDM }] = useCustomMutation(SEND_DIRECT_MESSAGE)
    if (loadingUser) return <SpinnerPage />

    return (
        <div className='p-100'>
            <Text variant='h1'>Welcome to the Slack test space.</Text>
            <Text variant='b2' className='max-w-[700px] pt-32'>
                This is a space for the Slack app review team. You can use this space to test sending group messages and
                direct messages to the Slack app.
            </Text>
            {user?.org?.slackChannelId && (
                <div className='flex gap-24 pt-32'>
                    <Button
                        disabled={loadingSendGroup}
                        onClick={loadingSendGroup ? undefined : () => sendGroupMessage()}
                    >
                        Send a group message
                    </Button>
                    <Button
                        disabled={loadingSendDM}
                        onClick={loadingSendDM ? undefined : () => sendDirectMessage()}
                        variant='secondary'
                    >
                        Send a direct message
                    </Button>
                </div>
            )}
        </div>
    )
}

export default SendChatAppMessage
