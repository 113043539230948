import React from 'react'
import Theme from '../../../theme'
import { ThemeColors } from '../../../types'

export type Position = 'absolute' | 'relative' | 'fixed' | 'sticky'
export type BorderStyle = 'solid' | 'dotted' | 'dashed'
export type FlexPosition = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'stretch'
export type FlexDirection = 'row' | 'column' | 'column-reverse' | 'row-reverse'
export type Overflow = 'hidden' | 'scroll' | 'visible' | 'auto' | 'clip'
export type Display = 'flex' | 'block' | 'inline-block' | 'inline-flex' | 'contents' | 'grid'
export type Cursor = 'pointer' | 'default'
export type PointerEvents = 'none' | 'all'
export type WillChange = 'position' | 'opacity' | 'width' | string
export type BoxSizing = 'border-box'
export type FlexWrap = 'nowrap' | 'wrap'
export type AnimationPlayState = 'running' | 'paused'

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
    p?: number | false
    pt?: number | false
    pb?: number | false
    pr?: number | false
    pl?: number | false
    m?: number | false
    mr?: number | false
    ml?: number | false
    mt?: number | false
    mb?: number | false
    height?: number | string
    maxHeight?: number | string
    minHeight?: number | string
    width?: number | string
    maxWidth?: number | string
    minWidth?: number | string
    flex?: number | false
    flexDirection?: FlexDirection
    flexGrow?: number | false
    flexWrap?: FlexWrap
    justifyContent?: FlexPosition
    alignItems?: FlexPosition
    flexCenter?: boolean
    background?: string
    backgroundSize?: 'cover' | 'contain' | 'auto'
    backgroundRepeat?: 'no-repeat' | 'repeat'
    backgroundPosition?: 'center' | 'right' | 'left' | 'top' | 'bottom'

    backgroundColor?: ThemeColors
    backgroundImage?: string
    position?: Position
    right?: number | string
    left?: number | string
    top?: number | string
    bottom?: number | string
    zIndex?: number | false
    borderRadius?: number | string
    borderBottomLeftRadius?: number | string
    borderBottomRightRadius?: number | string
    borderTopLeftRadius?: number | string
    borderTopRightRadius?: number | string
    borderColor?: ThemeColors | string
    borderWidth?: number | false
    borderTopWidth?: number
    borderTopColor?: ThemeColors | string
    borderTopStyle?: BorderStyle
    borderBottomWidth?: number
    borderBottomColor?: ThemeColors | string
    borderBottomStyle?: BorderStyle
    borderRightWidth?: number
    borderRightColor?: ThemeColors | string
    borderRightStyle?: BorderStyle
    borderLeftWidth?: number
    borderLeftColor?: ThemeColors | string
    borderLeftStyle?: BorderStyle
    borderStyle?: BorderStyle
    opacity?: number
    shadowOffset?: string
    shadowOpacity?: number
    shadowRadius?: string
    shadowBlur?: string
    shadowSpread?: string
    boxShadow?: ThemeColors | string
    elevation?: string
    overflow?: Overflow
    overflowY?: Overflow
    overflowX?: Overflow
    display?: Display

    cursor?: Cursor
    pointerEvents?: PointerEvents
    transition?: string
    transform?: string
    gridTemplateColumns?: string
    columnGap?: string | number
    childRef?: any
    willChange?: WillChange
    boxSizing?: BoxSizing
    animationPlayState?: AnimationPlayState

    gridColumn?: number
}

const pxUnitToEm = (num: number) => `${Theme.spacing(num) / 24}em`

const BoxCssHandlers = {
    p: (style: React.CSSProperties, value: any) => (style.padding = pxUnitToEm(value)),
    pt: (style: React.CSSProperties, value: any) => (style.paddingTop = pxUnitToEm(value)),
    pb: (style: React.CSSProperties, value: any) => (style.paddingBottom = pxUnitToEm(value)),
    pr: (style: React.CSSProperties, value: any) => (style.paddingRight = pxUnitToEm(value)),
    pl: (style: React.CSSProperties, value: any) => (style.paddingLeft = pxUnitToEm(value)),
    m: (style: React.CSSProperties, value: any) => (style.margin = pxUnitToEm(value)),
    mr: (style: React.CSSProperties, value: any) => (style.marginRight = pxUnitToEm(value)),
    ml: (style: React.CSSProperties, value: any) => (style.marginLeft = pxUnitToEm(value)),
    mb: (style: React.CSSProperties, value: any) => (style.marginBottom = pxUnitToEm(value)),
    mt: (style: React.CSSProperties, value: any) => (style.marginTop = pxUnitToEm(value)),
    height: (style: React.CSSProperties, value: any) => (style.height = value),
    maxHeight: (style: React.CSSProperties, value: any) => (style.maxHeight = value),
    minHeight: (style: React.CSSProperties, value: any) => (style.minHeight = value),
    width: (style: React.CSSProperties, value: any) => (style.width = value),
    minWidth: (style: React.CSSProperties, value: any) => (style.minWidth = value),
    maxWidth: (style: React.CSSProperties, value: any) => (style.maxWidth = value),
    flexDirection: (style: React.CSSProperties, value: any) => (style.flexDirection = value),
    flex: (style: React.CSSProperties, value: any) => (style.flex = value),
    flexGrow: (style: React.CSSProperties, value: any) => (style.flexGrow = value),
    flexWrap: (style: React.CSSProperties, value: any) => (style.flexWrap = value),
    flexCenter: (style: React.CSSProperties, value: any) => {
        if (!value) return
        style.display = 'flex'
        style.alignItems = 'center'
        style.justifyContent = 'center'
    },
    justifyContent: (style: React.CSSProperties, value: any) => (style.justifyContent = value),
    alignItems: (style: React.CSSProperties, value: any) => (style.alignItems = value),
    backgroundColor: (style: React.CSSProperties, value: ThemeColors) => {
        if (Theme.Colors.hasOwnProperty(value)) style.backgroundColor = Theme.Colors[value]
        else style.backgroundColor = value
    },
    background: (style: React.CSSProperties, value: string) => (style.background = value),
    backgroundImage: (style: React.CSSProperties, value: string) => {
        style.backgroundImage = value
        style.backgroundPosition = 'center'
        style.backgroundRepeat = 'no-repeat'
        style.backgroundSize = 'cover'
    },
    backgroundSize: (style: React.CSSProperties, value: string) => (style.backgroundSize = value),
    backgroundRepeat: (style: React.CSSProperties, value: string) => (style.backgroundRepeat = value),
    backgroundPosition: (style: React.CSSProperties, value: string) => (style.backgroundPosition = value),

    position: (style: React.CSSProperties, value: Position) => (style.position = value),
    bottom: (style: React.CSSProperties, value: Position) => (style.bottom = value),
    right: (style: React.CSSProperties, value: Position) => (style.right = value),
    left: (style: React.CSSProperties, value: Position) => (style.left = value),
    top: (style: React.CSSProperties, value: Position) => (style.top = value),
    zIndex: (style: React.CSSProperties, value: number) => (style.zIndex = value),
    opacity: (style: React.CSSProperties, value: number) => (style.opacity = value),
    borderRadius: (style: React.CSSProperties, value: string | number) => (style.borderRadius = value),
    borderBottomRightRadius: (style: React.CSSProperties, value: string | number) =>
        (style.borderBottomRightRadius = value),
    borderTopRightRadius: (style: React.CSSProperties, value: string | number) => (style.borderTopRightRadius = value),
    borderBottomLeftRadius: (style: React.CSSProperties, value: string | number) =>
        (style.borderBottomLeftRadius = value),
    borderTopLeftRadius: (style: React.CSSProperties, value: string | number) => (style.borderTopLeftRadius = value),
    borderColor: (style: React.CSSProperties, value: ThemeColors) => {
        style.borderColor = Theme.Colors[value] || value
        // style.borderWidth = width || 1
    },
    borderWidth: (style: React.CSSProperties, value: string | number) => (style.borderWidth = value),
    borderTopWidth: (style: React.CSSProperties, value: string | number) => (style.borderTopWidth = value),
    borderBottomWidth: (style: React.CSSProperties, value: string | number) => (style.borderBottomWidth = value),
    borderRightWidth: (style: React.CSSProperties, value: string | number) => (style.borderRightWidth = value),
    borderLeftWidth: (style: React.CSSProperties, value: string | number) => (style.borderLeftWidth = value),
    borderTopColor: (style: React.CSSProperties, value: ThemeColors) =>
        (style.borderTopColor = Theme.Colors[value] || value),
    borderBottomColor: (style: React.CSSProperties, value: ThemeColors) =>
        (style.borderBottomColor = Theme.Colors[value] || value),
    borderRightColor: (style: React.CSSProperties, value: ThemeColors) =>
        (style.borderRightColor = Theme.Colors[value] || value),
    borderLeftColor: (style: React.CSSProperties, value: ThemeColors) =>
        (style.borderLeftColor = Theme.Colors[value] || value),
    borderStyle: (style: React.CSSProperties, value: BorderStyle) => (style.borderStyle = value),
    borderTopStyle: (style: React.CSSProperties, value: BorderStyle) => (style.borderTopStyle = value),
    borderBottomStyle: (style: React.CSSProperties, value: BorderStyle) => (style.borderBottomStyle = value),
    borderRightStyle: (style: React.CSSProperties, value: BorderStyle) => (style.borderRightStyle = value),
    borderLeftStyle: (style: React.CSSProperties, value: BorderStyle) => (style.borderLeftStyle = value),
    boxShadow: (style: React.CSSProperties, value: string) => (style.boxShadow = value),
    overflow: (style: React.CSSProperties, value: Overflow) => (style.overflow = value),
    overflowY: (style: React.CSSProperties, value: Overflow) => (style.overflowY = value),
    overflowX: (style: React.CSSProperties, value: Overflow) => (style.overflowX = value),
    display: (style: React.CSSProperties, value: Display) => (style.display = value),
    cursor: (style: React.CSSProperties, value: Cursor) => (style.cursor = value),
    pointerEvents: (style: React.CSSProperties, value: PointerEvents) => (style.pointerEvents = value),
    transition: (style: React.CSSProperties, value: string) => (style.transition = value),
    transform: (style: React.CSSProperties, value: string) => (style.transform = value),
    gridTemplateColumns: (style: React.CSSProperties, value: string) => (style.gridTemplateColumns = value),
    columnGap: (style: React.CSSProperties, value: string) => (style.columnGap = value),
    willChange: (style: React.CSSProperties, value: WillChange) => (style.willChange = value),
    boxSizing: (style: React.CSSProperties, value: BoxSizing) => (style.boxSizing = value),

    animationPlayState: (style: React.CSSProperties, value: AnimationPlayState) => (style.animationPlayState = value),

    gridColumn: (style: React.CSSProperties, value: string) => (style.gridColumn = `span ${value}`)
}

export const Box = ({ childRef, ...props }: BoxProps) => {
    const passedProps = Object.keys(props)
    const propsToPass = {}
    const style = {}

    for (let i = 0; i < passedProps.length; i++) {
        const passedProp = passedProps[i]
        // @ts-ignore
        if (passedProp in BoxCssHandlers) BoxCssHandlers[passedProp](style, props[passedProp])
        // @ts-ignore
        else propsToPass[passedProp] = props[passedProp]
    }

    return <div {...propsToPass} ref={childRef} style={{ ...style, ...(props.style || {}) }} />
}

export default Box
