import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslations from '../content/i18n/en.json'
import frTranslations from '../content/i18n/fr-CA.json'
import './moment'

export const resources = {
    fr: { translation: frTranslations },
    en: { translation: enTranslations }
}

export const fallbackLng = 'en'

export const supportedLngs = Object.keys(resources)

export const setupLocale = () => {
    i18n.use(initReactI18next)
        .use(LanguageDetector)
        .init({
            resources,
            fallbackLng,
            interpolation: {
                escapeValue: false
            },
            supportedLngs,
            detection: {
                order: ['path', 'sessionStorage'],
                caches: ['sessionStorage']
            }
        })
}

export const getLocale = () => i18n.language
