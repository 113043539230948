import styled from 'styled-components'
import Theme from '../../theme'
import { DiscountPill } from '..'
import { Image as ImageType, PropsWithMobile, ThemeColors } from '../../types'
import { useState } from 'react'
import Box from '../core/Layout/Box'

interface ContainerProps extends PropsWithMobile {
    backgroundColor?: ThemeColors
    discount?: number
    hover?: boolean
}

export const BrandMiniCardContainer = styled.div`
    position: relative;
    border: 3px solid transparent;
    border-radius: 15px;
    background-color: ${({ backgroundColor }: ContainerProps) => Theme.Colors[backgroundColor || 'White']};
    display: flex;
    align-items: center;
    flex-direction: column;
    height: ${({ mobile, tablet }: ContainerProps) => (mobile ? 80 : tablet ? 100 : 125)}px;
    max-width: 100%;
    flex-grow: 1;
    min-width: ${({ tablet }: ContainerProps) => (tablet ? ' 0px' : `175px`)};
    padding-right: ${Theme.spacing(3)}px;
    padding-top: ${({ discount }: ContainerProps) => Theme.spacing(discount ? 1 : 0)}px;
    padding-left: ${Theme.spacing(3)}px;
    flex: 1;
    transition: all 250ms ease-in-out;

    ${({ hover }: ContainerProps) =>
        hover
            ? `:hover {
            background-color: ${Theme.Colors['Grey-Blue']};
        }`
            : ''}
`

export const BrandMiniImage = styled.img`
    margin: auto;
    width: calc(100% - ${({ tablet }: PropsWithMobile) => (tablet ? 20 : 40)}px);
    height: calc(100% - ${({ tablet }: PropsWithMobile) => (!tablet ? 40 : 40)}px);
    object-fit: contain;
    /* max-width: 100%; */
    transition: all 250ms ease-in-out;
`

interface BrandCardCoreProps extends ContainerProps {
    image?: ImageType
    cursor?: 'pointer' | 'default'
    useSrc?: boolean
}

const BrandMiniCardCore = ({
    backgroundColor,
    image,
    hover,
    mobile,
    discount,
    tablet,
    useSrc,
    cursor = 'pointer'
}: BrandCardCoreProps) => {
    const [mouseOver, setMouseOver] = useState(false)
    return (
        <Box
            cursor={cursor}
            position='relative'
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            minWidth={tablet ? 100 : 150}
        >
            {discount && !tablet && (
                <Box
                    position='absolute'
                    zIndex={0}
                    flexCenter
                    top={mouseOver ? -25 : -10}
                    right={0}
                    left={0}
                    transition='top 200ms ease-in-out'
                >
                    <DiscountPill>{discount}%</DiscountPill>
                </Box>
            )}
            <BrandMiniCardContainer
                backgroundColor={backgroundColor}
                mobile={mobile}
                tablet={tablet}
                hover={hover}
                discount={discount}
            >
                <BrandMiniImage
                    alt={image?.data?.attributes?.alternativeText}
                    mobile={mobile}
                    tablet={tablet}
                    data-sizes={!useSrc ? 'auto' : undefined}
                    className={!useSrc ? 'lazyload' : undefined}
                    data-src={!useSrc ? image?.data?.attributes?.url : undefined}
                    src={useSrc ? image?.data?.attributes?.url : undefined}
                />
            </BrandMiniCardContainer>
        </Box>
    )
}

const BrandMiniCard = (props: BrandCardCoreProps) => <BrandMiniCardCore {...props} />

export default BrandMiniCard
