import { Container, Heading, Markdown, Paragraph, Section } from 'tusk-ui'
import { CMSIcon, CMSSection } from 'content/content.types'

interface IconTextGridProps extends CMSSection {
    items: {
        icon: CMSIcon
        title: string
        description: string
    }[]
}

export default function IconTextGrid({ heading, items, ...sectionProps }: IconTextGridProps) {
    return (
        <Section py='sm' {...sectionProps}>
            <Container className='space-y-64'>
                <Heading.H2 className='text-center'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
                <div className='flex flex-wrap justify-around gap-64'>
                    {items.map((item, index) => {
                        const IconComponent = item.icon
                        return (
                            <div key={`${item.title}${index}`} className='w-[320px] tablet:w-full'>
                                <div className='flex items-center'>
                                    <IconComponent size='lg' />
                                    <Heading.H3 variant='h7' className='ml-16'>
                                        {item.title}
                                    </Heading.H3>
                                </div>
                                <Paragraph className='mt-16'>{item.description}</Paragraph>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </Section>
    )
}
