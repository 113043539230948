import React, { useRef, useState, createContext, useContext, forwardRef } from 'react'
import { cx } from 'tusk-ui/utils'

type ModalContextType = {
    dialogRef: React.RefObject<HTMLDialogElement> | null
    isOpen: boolean
    open: () => void
    close: () => void
}

type ModalDialogProps = {
    children?: React.ReactNode | any
    className?: string
}

export const ModalContext = createContext<ModalContextType>({
    dialogRef: null,
    isOpen: false,
    open: () => {},
    close: () => {}
})

export type ModalProps = {
    children: React.ReactNode
}

const Root = (props: ModalProps) => {
    const { children } = props
    const dialogRef = useRef<HTMLDialogElement>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    function open() {
        if (dialogRef.current) {
            dialogRef.current.showModal()
            setIsOpen(true)
        }
    }

    function close() {
        if (dialogRef.current) {
            dialogRef.current.close()
            setIsOpen(false)
        }
    }

    return <ModalContext.Provider value={{ dialogRef, isOpen, open, close }}>{children}</ModalContext.Provider>
}

const Dialog = (props: ModalDialogProps) => {
    const { dialogRef, isOpen, open, close } = useContext(ModalContext)
    const { children, className } = props

    return (
        <dialog ref={dialogRef} className={cx(className)} data-open={isOpen}>
            {typeof children === 'function' ? children({ isOpen, open, close }) : children}
        </dialog>
    )
}

type ModalOpenProps = {
    children: React.ReactNode
    className?: string
}

const Trigger = forwardRef<HTMLButtonElement, ModalOpenProps>((props, ref) => {
    const { open } = useContext(ModalContext)
    const { children, className } = props

    return (
        <button ref={ref} className={className} onClick={() => open()}>
            {children}
        </button>
    )
})

type ModalCloseButtonProps = {
    children?: React.ReactNode
    className?: string
    ariaLabel?: string
}

const Close = forwardRef<HTMLButtonElement, ModalCloseButtonProps>((props, ref) => {
    const { close } = useContext(ModalContext)
    const { children, className, ...restProps } = props

    return (
        <button ref={ref} className={className} onClick={() => close()} {...restProps}>
            {children}
        </button>
    )
})

export { Root, Dialog, Trigger, Close }
