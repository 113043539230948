import { cva, VariantProps } from 'class-variance-authority'
import { CMSIcon, CMSImage, CMSSection } from 'content/content.types'
import { Carousel, Container, Section, Text, Heading, Paragraph, Markdown } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import ImageCard from '../components/ImageCard'

const gridVariant = cva('grid gap-x-32 gap-y-64', {
    variants: {
        cols: {
            '4': 'grid-cols-2',
            '5': 'grid-cols-6',
            '6': 'grid-cols-3'
        }
    }
})

interface ProductGridProps extends CMSSection {
    description: string
    icon?: CMSIcon
    eyebrow?: string
    items: {
        title: string
        description: string
        image: CMSImage
    }[]
}

export default function ProductGrid({
    items,
    heading,
    eyebrow,
    description,
    icon: IconComponent,
    ...sectionProps
}: ProductGridProps) {
    const numCols = items.length.toString() as VariantProps<typeof gridVariant>['cols']
    return (
        <>
            <Section {...sectionProps}>
                <Container className='space-y-64'>
                    <div className={cx('flex flex-col items-center')}>
                        {IconComponent && (
                            <div className='mb-16'>
                                <IconComponent size='lg' />
                            </div>
                        )}
                        {eyebrow && (
                            <Text variant='h7' className='mb-16'>
                                {eyebrow}
                            </Text>
                        )}
                        {heading && (
                            <Heading.H2 className='mb-24'>
                                <Markdown.Heading markdown={heading} />
                            </Heading.H2>
                        )}
                        {description && <Paragraph className=''>{description}</Paragraph>}
                    </div>
                    <div className={cx('grid gap-x-32 gap-y-64 mobile:hidden', gridVariant({ cols: numCols }))}>
                        {items.map((item, index) => {
                            return (
                                <ImageCard
                                    className={cx({
                                        'col-span-3': items.length === 5 && index <= 1,
                                        'col-span-2': items.length === 5 && index > 1
                                    })}
                                    key={index}
                                    title={item.title}
                                    description={item.description}
                                    image={item.image}
                                />
                            )
                        })}
                    </div>
                </Container>
                <Container className='hidden pt-64 mobile:block mobile:pr-0'>
                    <Carousel.Root>
                        <Carousel.Content>
                            {items.map((item, index) => (
                                <Carousel.Item key={index}>
                                    <ImageCard
                                        key={index}
                                        title={item.title}
                                        description={item.description}
                                        image={item.image}
                                        className='h-full'
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel.Content>
                        <Carousel.Indicators />
                    </Carousel.Root>
                </Container>
            </Section>
        </>
    )
}
