import { Link } from 'react-router-dom'
import { useDevice } from '../contexts'
import { Locations } from '../Locations'
import Button from './core/Button/Button'
import Box from './core/Layout/Box'
import PagePadding from './core/Layout/PagePadding'
import Paper from './core/Layout/Paper'
import { Text } from 'tusk-ui'
import content from 'content/pages/en/no-permission/content'
import { withContent } from 'mammoth/pages/PageContent'

type Props = {
    content: typeof content.data
}

const NoPermission = ({ content }: Props) => {
    const device = useDevice()
    return (
        <PagePadding>
            <Box pt={15} flexCenter minHeight={device?.height}>
                <Paper lightRadius>
                    <Box p={8}>
                        <Box pb={6}>
                            <Text variant='h3' className='text-center'>
                                {content.title}
                            </Text>
                        </Box>
                        <Link to={Locations.Home()}>
                            <Button>{content.homeBtn}</Button>
                        </Link>
                    </Box>
                </Paper>
            </Box>
        </PagePadding>
    )
}

export default withContent('no-permission', NoPermission)
