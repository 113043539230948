import { Section, Container, Heading, ButtonLink, Markdown } from 'tusk-ui'
import { CMSButtonLink, CMSSection } from 'content/content.types'

interface CatchAllProps extends CMSSection {
    cta: CMSButtonLink
}

export default function CatchAll({ heading, cta, ...sectionProps }: CatchAllProps) {
    return (
        <Section scheme='night' className='tusk-bg-mammoth-2' py='sm' {...sectionProps}>
            <Container width='sm'>
                <Heading.H2 variant='h1' className='mb-40 text-center'>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
                <div className='flex flex-col items-center'>
                    <ButtonLink variant={cta.variant} to={cta.href}>
                        {cta.text}
                    </ButtonLink>
                </div>
            </Container>
        </Section>
    )
}
