import Theme from '../../theme'
import Box from '../core/Layout/Box'
import IconProps from './IconProps'

export interface ArrowDownProps extends IconProps {
    revers?: boolean
}
const ArrowDown = ({ color, width, revers }: ArrowDownProps) => (
    <Box transform={revers ? 'rotate(180deg)' : ''} flexCenter>
        <svg width={width || '9'} viewBox='0 0 9 6'>
            <path d='M4.5 6L0.602886 0.75L8.39711 0.75L4.5 6Z' fill={Theme.Colors[color || 'Dark-Purple']} />
        </svg>
    </Box>
)

export default ArrowDown
