import { CMSSection } from 'content/content.types'
import { Section, Container, Heading, Stat, Paragraph, Markdown } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

interface StatListComparisonProps extends CMSSection {
    compare: {
        heading: string
        stats: {
            value: string | number
            metric: string
            title: string
            description?: string
        }[]
    }[]
}

export default function StatListComparison({ heading, compare }: StatListComparisonProps) {
    return (
        <Section py='md'>
            <Container className='space-y-64'>
                <Heading.H2>
                    <Markdown.Heading markdown={heading} />
                </Heading.H2>
                <div>
                    {compare.length === 1 ? (
                        <div>
                            <Heading.H3 variant='h5'>{compare[0].heading}</Heading.H3>
                            <div className='flex justify-between gap-32 tablet:flex-col'>
                                {compare[0].stats.map((stat) => (
                                    <div
                                        key={stat.title}
                                        className='w-min border-snow-200 tablet:border-b tablet:pb-16'
                                    >
                                        <Stat variant='d1' value={stat.value} metric={stat.metric} />
                                        <Heading.H4 variant='h6' className='mb-16'>
                                            {stat.title}
                                        </Heading.H4>
                                        <Paragraph>{stat.description}</Paragraph>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className='grid grid-cols-2 gap-120 mobile:grid-cols-1'>
                            {compare.map((col, index) => (
                                <div key={index} className={cx({ 'opacity-50 mobile:hidden': index === 0 })}>
                                    <Heading.H3 variant='h5'>{col.heading}</Heading.H3>
                                    {col.stats.map((stat, index) => (
                                        <div
                                            key={index}
                                            className={cx(
                                                'flex gap-40 border-b border-snow-200 py-40 mobile:grid-cols-1 mobile:gap-16'
                                            )}
                                        >
                                            <Stat variant='h1' value={stat.value} metric={stat.metric} />
                                            <div>
                                                <Heading.H4 variant='h6' className='mb-16'>
                                                    {stat.title}
                                                </Heading.H4>
                                                <Paragraph>{stat.description}</Paragraph>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </Section>
    )
}
