import { gql } from '@apollo/client'
import { useCustomMutation } from '../index'
import { GET_USER } from './useUser'

const LOGOUT_MUTATION = gql`
    mutation Logout {
        logout
    }
`
const useLogout = () => {
    return useCustomMutation(LOGOUT_MUTATION, {
        update: (cache) =>
            cache.writeQuery({
                query: GET_USER,
                data: { user: null }
            })
    })
}

export default useLogout
