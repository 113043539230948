import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { ElementPrimitive, Text } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

const FieldRoot = forwardRef<
    ElementRef<typeof ElementPrimitive.Div>,
    ComponentPropsWithoutRef<typeof ElementPrimitive.Div>
>((props, ref) => {
    const { className, children, ...restProps } = props
    return (
        <ElementPrimitive.Div ref={ref} className={cx('space-y-8', className)} {...restProps} asChild>
            <label>{children}</label>
        </ElementPrimitive.Div>
    )
})

const FieldLabel = forwardRef<
    ElementRef<typeof ElementPrimitive.Div>,
    ComponentPropsWithoutRef<typeof ElementPrimitive.Div> & {
        required?: boolean
    }
>((props, ref) => {
    const { className, required, children, ...restProps } = props
    return (
        <ElementPrimitive.Div ref={ref} className={cx('', className)} {...restProps} asChild>
            <label>
                {children} {required && `*`}
            </label>
        </ElementPrimitive.Div>
    )
})

const FieldError = forwardRef<
    ElementRef<typeof ElementPrimitive.Div>,
    ComponentPropsWithoutRef<typeof ElementPrimitive.Div>
>((props, ref) => {
    const { className, children, ...restProps } = props
    return (
        <ElementPrimitive.Div ref={ref} className={cx('text-error', className)} {...restProps} asChild>
            <Text variant='c1'>{children}</Text>
        </ElementPrimitive.Div>
    )
})

const FieldUnit = forwardRef<
    ElementRef<typeof ElementPrimitive.Div>,
    ComponentPropsWithoutRef<typeof ElementPrimitive.Div>
>((props, ref) => {
    const { className, children, ...restProps } = props
    return (
        <ElementPrimitive.Div
            ref={ref}
            className={cx('absolute right-16 top-20 flex justify-center text-snow-300', className)}
            {...restProps}
            asChild
        >
            <Text variant='b3'>{children}</Text>
        </ElementPrimitive.Div>
    )
})

export { FieldRoot as Root, FieldLabel as Label, FieldError as Error, FieldUnit as Unit }
