import { Card, Text, Heading, Paragraph, Image } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'
import { CMSImage } from 'content/content.types'

export default function ImageCard({
    title,
    description,
    image,
    bullet,
    className
}: {
    title: string
    description: string
    image: CMSImage
    bullet?: string
    className?: string
}) {
    return (
        <Card scheme='smoke' className={cx('tusk-bg-mammoth-4 flex flex-col justify-between', className)}>
            <div className='mb-24 flex gap-16 mobile:flex-col'>
                {bullet && (
                    <div>
                        <Text
                            variant='h6'
                            className='flex h-40 w-40 items-center justify-center rounded-full bg-night text-snow'
                        >
                            {bullet}
                        </Text>
                    </div>
                )}
                <div>
                    <Heading.H3 className='mb-8'>{title}</Heading.H3>
                    <Paragraph>{description}</Paragraph>
                </div>
            </div>
            <Image src={image.src} alt={image.alt} className='rounded-xl shadow' />
        </Card>
    )
}
