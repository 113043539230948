import { Card, Image, Text, Link } from 'tusk-ui'
import { CustomerStoryData } from 'content/collections/types'

export default function CustomerStoryCard({ customerStory }: { customerStory: CustomerStoryData }) {
    return (
        <Link to={customerStory.url} className='group'>
            <Card className='relative aspect-2/3 overflow-hidden rounded-xl' padding='flush'>
                <div className='absolute flex h-full w-full flex-col justify-center p-48 transition-colors group-hover:justify-start group-hover:bg-night/80'>
                    <Image
                        src={customerStory.customer.logoLight.src}
                        alt={customerStory.customer.logoLight.src}
                        className='mx-auto max-w-[250px] object-contain group-hover:hidden'
                    />
                    <Text variant='h3' className='hidden text-snow group-hover:block'>
                        {customerStory.title}
                    </Text>
                </div>
                <Image
                    className='h-full overflow-hidden object-cover'
                    src={customerStory.cardImage.src}
                    alt={customerStory.cardImage.alt}
                />
            </Card>
            <div className='mt-16 flex justify-between gap-8'>
                <Text variant='l1' className='underline-offset-4 group-hover:underline'>
                    {customerStory.customer.name}
                </Text>
                <Text className='text-snow-300'>{customerStory.customer.industry}</Text>
            </div>
        </Link>
    )
}
