import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ElementPrimitive, Text } from 'tusk-ui'

const paragraphVariants = cva('', {
    variants: {
        variant: {
            h5: '',
            h6: '',
            b1: '',
            b2: '',
            b3: '',
            b4: ''
        }
    },
    defaultVariants: {
        variant: 'b3'
    }
})

export interface ParagraphProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Paragraph>,
        VariantProps<typeof paragraphVariants> {}

const Paragraph = forwardRef<ElementRef<typeof ElementPrimitive.Paragraph>, ParagraphProps>((props, ref) => {
    const { children, className, variant, ...restProps } = props

    return (
        <Text
            asChild
            ref={ref}
            variant={variant}
            className={cx(paragraphVariants({ variant }), className)}
            {...restProps}
        >
            <ElementPrimitive.Paragraph>{children}</ElementPrimitive.Paragraph>
        </Text>
    )
})

export default Paragraph
