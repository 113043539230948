import { forwardRef } from 'react'
import { cx } from 'tusk-ui/utils'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { Card, Text } from 'tusk-ui'
import { CardProps } from '../Card/Card'

const Root = forwardRef<HTMLDivElement, RadioGroup.RadioGroupProps & { value?: string }>(
    ({ className, value, ...props }, ref) => {
        return (
            <RadioGroup.Root
                ref={ref}
                className={cx('mb-16 flex flex-col gap-16', className)}
                value={value}
                {...props}
            />
        )
    }
)

const RadioCard = forwardRef<HTMLDivElement, CardProps & { onClick: () => void }>(
    ({ className, onClick, ...props }, ref) => {
        return (
            <Card
                ref={ref}
                className={cx(
                    'flex min-h-[52px] cursor-pointer items-center rounded-md border-2 border-night-100 bg-snow px-16 py-8 hover:border-night',
                    className
                )}
                onClick={onClick}
                {...props}
            />
        )
    }
)

const Item = forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement> & { value: string; id: string }>(
    ({ className, value, id, ...props }, ref) => {
        return (
            <RadioGroup.Item
                ref={ref}
                className={cx('min-h-24 min-w-24 rounded-full border-2 border-night bg-snow', className)}
                value={value}
                id={id}
                {...props}
            />
        )
    }
)

const Indicator = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLSpanElement>>(({ className, ...props }, ref) => {
    return (
        <RadioGroup.Indicator
            ref={ref}
            data-state='checked'
            className={cx(
                "relative flex h-full w-full items-center justify-center after:block after:h-16 after:w-16 after:rounded-full after:bg-night after:content-['']",
                className
            )}
            {...props}
        />
    )
})

const Label = forwardRef<HTMLLabelElement, React.HTMLAttributes<HTMLSpanElement> & { htmlFor: string; value: string }>(
    ({ className, htmlFor, value, ...props }, ref) => {
        return (
            <label
                ref={ref}
                {...props}
                className={cx('text-white pointer-events-none cursor-pointer pl-12 leading-none', className)}
                htmlFor={htmlFor}
            >
                <Text>{value}</Text>
            </label>
        )
    }
)

export { Root, RadioCard as Card, Item, Indicator, Label }
