import { User } from './types'

export enum Feature {
    MeasureLifestyle = 'MEASURE_LIFESTYLE',
    MeasureScope3 = 'MEASURE_SCOPE3',
    OffsetLifestyle = 'OFFSET_LIFESTYLE',
    OffsetScope3 = 'OFFSET_SCOPE3'
}

const can = (user: User) => ({
    access: (feature: Feature) => user?.features?.includes(feature)
})

export const canFeatures = (features: Feature[]) => ({
    access: (feature: Feature) => features?.includes(feature)
})

export default can
