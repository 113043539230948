import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cx } from 'tusk-ui/utils'
import { ElementPrimitive } from 'tusk-ui'
import { textVariants } from '../Text/Text'
import { Close, Plus } from '../Icon'
import IconButton from '../Button/IconButton'

const chipVariants = cva(
    'group/chip inline-flex items-center gap-4 rounded-full focus:outline-none focus:ring-1 focus:ring-night focus:ring-offset-[3px]',
    {
        variants: {
            size: {
                sm: cx(textVariants({ variant: 'l3' }), 'py-4 px-12'),
                md: cx(textVariants({ variant: 'h7' }), 'py-4 px-16')
            },
            actionable: {
                true: 'bg-snow border-night border pr-4',
                false: 'bg-snow-100 hover:bg-night-100 active:bg-night-100'
            }
        },
        defaultVariants: {
            size: 'md',
            actionable: false
        }
    }
)

export type ActionType = 'add' | 'remove'

interface ChipProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Button>,
        VariantProps<typeof chipVariants> {
    actionType?: ActionType
    onAction?: (actionType: ActionType) => void
}

const Chip = forwardRef<ElementRef<'button'>, ChipProps>((props, ref) => {
    const { className, size, actionable, actionType, onAction, children, ...restProps } = props

    const onClick = () => {
        if (onAction && actionType) {
            onAction(actionType)
        }
    }

    return (
        <ElementPrimitive.Button
            ref={ref}
            className={cx(chipVariants({ size, actionable }), className)}
            onClick={onClick}
            disabled={!actionable}
            {...restProps}
        >
            {children}
            {actionable && (
                <IconButton
                    size={size}
                    icon={(props) => (actionType === 'add' ? <Plus {...props} /> : <Close {...props} />)}
                    tabIndex={-1}
                    as='span'
                />
            )}
        </ElementPrimitive.Button>
    )
})

export default Chip
