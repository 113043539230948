import { BoxProps } from './components/core/Layout/Box'
import { Feature } from './accessControl'
import { ProjectLocationEntity, ProjectMechanismEntity, ProjectTechnologyEntity } from './gql/PROJECT_FRAGMENT'
import { QuickActionState } from './components/molecules/QuickActions/QuickActionStep'
import Theme from './theme'
import { GLOBAL_TAG_HANDLES } from './constants'

export type { BoxProps }

export type ThemeColors = keyof typeof Theme.Colors

export type BUCKET = 'WELL_BELOW' | 'BELOW' | 'AVG' | 'ABOVE' | 'WELL_ABOVE'
export type PERCENTAGE = '_50' | '_75' | '_100'
export type CYCLE = 'MONTHLY' | 'YEARLY'
export type ROLE = 'owner' | 'admin' | 'member'

export type Interval = 'year' | 'month'
export type Currency = 'cad' | 'usd'

type CardBrand = 'amex' | 'cartes_bancaires' | 'diners_club' | 'discover' | 'jcb' | 'mastercard' | 'visa' | 'unionpay'
type ARTICLE_CATEGORY = 'Offset' | 'Reduce' | 'Measure' | 'Reduce' | 'Educate'

// Doconomy
export type DoconomySector =
    | 'transport'
    | 'lifestyle'
    | 'diet'
    | 'home'
    | 'publicservices'
    | 'intro'
    | 'personal'
    | 'summary'
export type DoconomyAnswerType = 'input' | 'range' | 'boolean' | 'list' | 'button' | 'search'
export type DoconomyUnit =
    | 'km'
    | 'm'
    | 'liter'
    | 'm2'
    | 'm3'
    | 'mi.'
    | 'ft.'
    | 'gal.'
    | 'ft2'
    | 'kWh'
    | 'hours'
    | 'minutes'
    | 'days'
    | 'weeks'
    | 'months'
    | 'percent'
    | 'currency'

export type JSX_TYPES = React.ReactNode

export type PropsWithChildren = {
    children: JSX_TYPES
}

export type PropsWithOptionalChildren = {
    children?: JSX_TYPES
}

export type PropsWithMobile = {
    tiny?: boolean
    mobileSmall?: boolean
    mobile?: boolean
    tablet?: boolean
    desktop?: boolean
    smallLaptop?: boolean
    laptop?: boolean
    largeLaptop?: boolean
}

export interface PropsWithDevice extends PropsWithMobile {
    height?: number
    width?: number
    touchDevice?: boolean
}

export type PropsWithStyle = {
    style?: React.CSSProperties
}

export type UserCore = {
    id: string
    email: string
    firstName: string
    lastName: string
    imageUrl?: string
}

export enum AuthProvider {
    Email = 'email',
    Google = 'google',
    Azure = 'azure',
    Slack = 'slack'
}

export enum Language {
    en = 'en',
    fr = 'fr'
}

export enum OnboardingStep {
    AccountSettings = 'accountSettings',
    Personalization = 'personalization',
    FrequencySettings = 'frequencySettings',
    Completed = 'completed'
}

export interface User extends UserCore {
    username: string
    password: string
    bucket: BUCKET
    contry: string
    province?: string
    cmsJwt: string
    code: number
    stripe?: StripeDetails
    org: Organisation
    role: ROLE
    team?: string
    signup_date: string
    features: Feature[]
    percentage: number
    footprint: number
    workFootprint?: number
    offset: number
    provider: AuthProvider
    language: Language
    onboardingStep?: OnboardingStep
}

export type StripeDetails = {
    stripePaymentMethodId: string
    stripeCustomerId: string
    stripeSubscriptionId: string
    cardDetails: StripeCardDetails
    cycle: CYCLE
    productId: string
    priceId: string
    giftExpiry: string
}

export type StripeCardDetails = {
    brand: string
    exp_month: number
    exp_year: number
    last4: string
    billing_details: {
        address: Address
    }
}
type ImageCore = {
    id?: string
    attributes: {
        url: string
        alternativeText: string
    }
}

export type Image = {
    data: ImageCore
}
export type Images = {
    data: ImageCore[]
}

export type Brand = {
    id: string
    attributes: {
        Site_Brand_Name: string
        Site_Description?: string
        Site_Discount?: number
        Site_Limit?: string
        Site_Applies_To?: string
        Site_Pill?: string
        Site_Link?: string
        Site_Image?: Image
        Site_Logo?: Image
        Category?: string
        Card_Subheading?: string
        Impact_Description?: string
        Brand_Values?: {
            data: BrandValue[]
        }
        Brand_Redemption_Types?: {
            data: BrandRedemptionType[]
        }
        Location?: string
        Gift_Card_Amounts?: string
        handle: string
    }
}

export type Project = {
    id: string
    attributes: {
        Heading: string
        SEO_URL?: string
        Country?: string
        Category?: string
        Hero_Image?: Image
        Verification_Logo?: Image
        Section_Description?: string
        Section_Image?: Image
        Page_Body?: string
        Image?: Images
        Tag_Line?: string
        Next_Project?: NextProject
        Card_Image?: Image
        URL?: string
        Project_Location?: {
            data?: ProjectLocationEntity
        }
        Project_Mechanism?: {
            data?: ProjectMechanismEntity
        }
        Project_Technology?: {
            data?: ProjectTechnologyEntity
        }
        handle: string
    }
}
export type NextProject = {
    data: {
        id: string
        attributes: {
            Heading: string
            SEO_URL: string
            Country: string
            Category: string
            Section_Image: Image
            handle: string
        }
    }
}

export type Cta = {
    Destination: string
    Display_Text: string
    isNew?: boolean
}
export interface NavLink extends Cta {
    SubLinks?: Cta[]
}

export type HeaderProps = {
    Heading: string
    Sub_Heading?: string
    CTA_Button?: Cta
    anon?: boolean
}

export type Place = {
    description: string
    placeId: string
}

export type Impact = {
    tonnes: number
    trees: number
    cars: number
    coal: number
}

export type UserImpact = {
    today: Impact
    inFiveYears: Impact
}

export type ScreenSize = {
    height: number
    width: number
}

export type CTA_Section = {
    Heading?: string
    Call_To_Action?: string
    Destination?: string
    Background_Color?: ThemeColors
}

export type Article = {
    id: string
    attributes: {
        Header_Image: Image
        Heading: string
        Sub_Heading: string
        Author: string
        SEO_URL: string
        Background_Color: ThemeColors
        Heading_Color: ThemeColors
        Body_One: string
        Body_Two: string
        Embeded_CTA: CTA_Section
        Card_Image: Image
        Category: ARTICLE_CATEGORY
        CTA_Section: CTA_Section
        More_Articles_Heading: string
        Time_To_Read: string
        Short_Description: string
        More_Articles: { data: Article[] }
        SEO_Title: string
        SEO_Description: string
        SEO_Image: Image
    }
}

export type OrganisationHead = {
    id: string
    name: string
    logoUrl: string
    size: number
    percentOff: number
    memberFee: number
}

export enum BillingType {
    Invoice = 'INVOICE',
    Charge = 'CHARGE'
}

export enum CommunicationProvider {
    Slack = 'slack',
    Azure = 'azure',
    Email = 'email'
}

export enum OrgAuthProvider {
    Azure = 'azure',
    Email = 'email',
    Google = 'google'
}

export interface Organisation extends OrganisationHead {
    paymentMethodId: string
    cardDetails: StripeCardDetails
    employees: OrganisationEmployees[]
    site_logo: Image
    memberFee: number
    billingType: BillingType
    logo: OrgLogo
    taxId: string
    address: Address
    features: Feature[]
    communicationProvider: CommunicationProvider
    slackTeamId?: string
    slackChannelId?: string
    challengeState?: QuickActionState
    teamsTeamId?: string
    teamsGroupId?: string
    teamsTenantId?: string
    language?: Language
    authProvider?: OrgAuthProvider
}
export type TeamType = Pick<Organisation, 'id' | 'name'>
export type Address = {
    line1: string
    city: string
    country: string
    state: string
}

type OrgLogo = {
    url: string
    file?: File
}

type OrganisationEmployees = {
    id: string
    email: string
}

export type OrganisationTeam = {
    id: string
    Name: string
}

export type EmployerCMS = {
    id: string
    Name: string
    Logo: Image
}

export type BrandValue = {
    id: string
    attributes: {
        Name: string
        Name_Fr: string
        Color: string
        Icon: Image
    }
}

export type BrandCategory = {
    id: string
    attributes: {
        Name: string
        Name_Fr: string
    }
}

export type BrandRedemptionType = {
    id: string
    attributes: {
        Name: string
        Name_Fr: string
    }
}

export type BrandTag = {
    id: string
    attributes: {
        Name: string
        Name_Fr: string
    }
}

export type OrgFootprint = {
    total: number
    avg: number
}

export type TopRedemptions = {
    count: number
    saved: number
    brand: Brand
}

export type OrgEnrollment = {
    joined: number
    pending: number
    invited: number
}

export type OrgPayments = {
    id: string
    breakdown_link: string
    subtotal: number
    taxPercentage: number
    taxPrice: number
    taxType: string
    currency: string
    created_at: string
    amount: number
    stripeFee: number
    plan_percent: number
    card: OrgCards
}

export type OrgPaymentBreakdown = {
    fileName: string
    csvData: string
}

export type OrgCards = {
    paymentMethodId: string
    last4: string
    brand: CardBrand
    exp_month: number
    exp_year: number
    billing_details: {
        address: Address
    }
}

export type Testimonial = {
    Heading: string
    Body: string
    Reviewer: string
}

export type Review = {
    id: string
    attributes: {
        Heading: string
        Body: string
        Name: string
        Job_Title: string
        Link: string
        Location: string
        Avatar: Image
        employer: {
            data: {
                attributes: {
                    Name: string
                }
            }
        }
        review_platform: {
            data: {
                attributes: {
                    Icon: Image
                    Logo: Image
                }
            }
        }
    }
}

export type Coupon = {
    id: string
    amount_off: number
    currency: Currency
}

export type SubscriptionType = {
    id: string
    currency: Currency
    interval: Interval
    footprint: number
    price: number
    productId: string
    priceId: string
    percent: number
    couponId: string
    coupon: Coupon
}

export type ProjectAndOffset = {
    tonnes: number
    project: Project
}

export enum StatusCode {
    Success = 'SUCCESS',
    Fail = 'FAIL'
}

export enum ChallengeStatus {
    Completed = 'COMPLETED',
    InProgress = 'IN_PROGRESS',
    Outstanding = 'OUTSTANDING'
}

export type ChallengeCategory = {
    id: string
    name: string
    emoji: string
}

export type ChallengeStepCore = {
    completedAt?: Date
    description: string
    heading: string
    id: string
    reduction: number
    saving: number
    points: number
}

export type ModalChallengeStep = ChallengeStepCore & {
    type: ChallengeStepType.Modal
    modalMarkup?: string
    linkText?: string
}

export type LinkChallengeStep = ChallengeStepCore & {
    type: ChallengeStepType.Link
    linkText?: string
    linkUrl: string
}

export enum StandaloneQuestionType {
    Check = 'CHECK',
    Radio = 'RADIO'
}

export type StandaloneQuestionOption = {
    id: string
    isUserAnswer?: boolean
    label: string
}
export type StandaloneQuestion = {
    id: string
    label: string
    options: Array<StandaloneQuestionOption>
    type: StandaloneQuestionType
}

export type QuestionChallengeStep = ChallengeStepCore & {
    type: ChallengeStepType.Question
    question: StandaloneQuestion
}

export enum FileType {
    Image = 'IMAGE',
    Pdf = 'PDF'
}

export type FileT = {
    type: FileType
    key: string
    url: string
}

export type UploadChallengeStep = ChallengeStepCore & {
    type: ChallengeStepType.Upload
    comment?: string
    files: Array<FileT>
}

export type FinalChallengeStep = ChallengeStepCore & {
    type: ChallengeStepType.Final
}

export enum ChallengeStepType {
    Modal = 'MODAL',
    Question = 'QUESTION',
    Upload = 'UPLOAD',
    Final = 'FINAL',
    Link = 'LINK'
}

export type ChallengeStep =
    | ModalChallengeStep
    | LinkChallengeStep
    | QuestionChallengeStep
    | UploadChallengeStep
    | FinalChallengeStep

export type ChallengeSummary = {
    id: string
    heading: string
    description: string
    actionTitle?: string
    category: ChallengeCategory
    cardImage?: Image
    rowImage?: Image
    numSteps: number
    points: number
    status: ChallengeStatus
}

export type Challenge = ChallengeSummary & {
    desktopHeroImage?: Image
    mobileHeroImage?: Image
    steps: ChallengeStep[]
    status: ChallengeStatus
}

export type ColleagueCompletion = Pick<UserCore, 'firstName' | 'lastName' | 'imageUrl'> & {
    id: string
    completedAt: Date
    experience: string
    advice: string
}

export type Quarter = 'Total' | 'Q1' | 'Q2' | 'Q3' | 'Q4'

export type OrgTeam = {
    id: string
    label: string
}

export enum RewardTypes {
    GIFT_CARD = 'GIFT_CARD',
    CARBON_REMOVAL = 'CARBON_REMOVAL'
}

export type Reward = {
    points: number
    reward: {
        type: RewardTypes
        value: number
    }
}

export enum EngagementTypes {
    CarbonRemovalRedemption = 'carbon_removal_redemption',
    GiftCardRedemption = 'gift_card_redemption',
    UserReferral = 'user_referral',
    ChatAppClick = 'chat_app_click',
    UseDiscountCode = 'use_discount_code',
    RecalculatePersonal = 'recalculate_personal',
    CalculatePersonalInitial = 'calculate_personal_initial',
    CalculateQuarterly = 'calculate_quarterly',
    FeedbackSubmission = 'feedback_submission',
    SiteFeedbackSubmission = 'site_feedback_submission',
    TopOfLeaderboard = 'top_of_leaderboard',
    Signup = 'signup',
    StaySubscribed = 'stay_subscribed',
    QuizCorrectAnswer = 'quiz_correct_answer',
    QuizWrongAnswer = 'quiz_wrong_answer'
}

export type OrgQuickActionsStates = {
    viewedInstructions: QuickActionState
    connectChatApp: QuickActionState
    inviteEmployees: {
        state: QuickActionState
        joined: number
        workforceSize: number
    }
    setupChallenge: QuickActionState
}

export type SEO = {
    Title?: string
    Description?: string
    Image?: Image
}

export type TopicPreferences = { userId: string } & {
    [K in (typeof GLOBAL_TAG_HANDLES)[number]]: boolean
}

export type Tag = {
    id: string
    label: string
    emoji: string
    description: string
}
