import { Image, Container, Heading, Text, Section } from 'tusk-ui'
import { CustomerStoryData } from 'content/collections/types'

export default function CustomerStoryHero({ customerStory }: { customerStory: CustomerStoryData }) {
    return (
        <Section py='none' scheme='night' className='overflow-hidden'>
            <Container className='flex gap-120 tablet:flex-col tablet:gap-64'>
                <div className='w-3/5 py-64 tablet:w-full'>
                    <Image
                        className='mb-40 w-[150px]'
                        src={customerStory.customer.logoLight.src}
                        alt={customerStory.customer.logoLight.alt}
                    />
                    <Heading.H1 className='mb-56'>{customerStory.title}</Heading.H1>
                    <div className='grid grid-cols-2 gap-x-40 gap-y-32'>
                        {customerStory.infoStats.map((info, index) => (
                            <div key={index}>
                                <Text variant='b4'>{info.title}</Text>
                                <Text variant='h7'>{info.description}</Text>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='relative w-2/5 tablet:w-full'>
                    <Image
                        className='absolute h-full w-[700px] max-w-none rounded-none object-cover tablet:relative tablet:mb-64 tablet:aspect-3/2 tablet:w-full tablet:rounded-xl'
                        src={customerStory.heroImage.src}
                        alt={customerStory.heroImage.alt}
                    />
                </div>
            </Container>
        </Section>
    )
}
