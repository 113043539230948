import { CMSPageContent, CMSContent } from 'content/content.types'
import i18next from 'i18next'
import { fallbackLng, supportedLngs } from 'locales'
import { useEffect, useMemo, useState } from 'react'
import useLanguage from './useLanguage'
import { getAwsRum } from 'setupAwsRum'

export type UseContentResponseError = {
    message: string
}

export type UseContentResponseLoading = boolean

export type UseContentResponse<T = CMSContent> = {
    content: T | null
    loading: UseContentResponseLoading
    error: UseContentResponseError | null
}

const otherLocales = supportedLngs.filter((language) => language !== fallbackLng)

const getFallbackPath = (path: string) => {
    if (path.match(new RegExp(`^(pages|global|collections)/(${otherLocales.join('|')})/.*`))) {
        return path.replace(new RegExp(`^(pages|global|collections)/[^/]+/`), `$1/${fallbackLng}/`)
    }
    return null
}

const loadContent = async (path: string) => {
    try {
        const module = await import(`../../content/${path}`)
        return module.default
    } catch (err) {
        const fallbackPath = getFallbackPath(path)
        if (fallbackPath) {
            const module = await import(`../../content/${fallbackPath}`)
            return module.default
        } else {
            throw err
        }
    }
}

const useContent = <T = CMSContent>(path: string): UseContentResponse<T> => {
    const [loading, setLoading] = useState<UseContentResponse['loading']>(true)
    const [content, setContent] = useState<UseContentResponse<T>['content']>(null)
    const [error, setError] = useState<UseContentResponse['error']>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setContent(await loadContent(path))
                if (error) setError(null)
            } catch (err) {
                setError({ message: `Failed to retrieve content from path: ${path}` })
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [path])

    return { content, loading, error }
}

export const usePageContent = <T = CMSPageContent>(path: string) => {
    const language = useLanguage()
    return useContent<T>(`pages/${language}/${path}/content`)
}

export const useCollectionContent = <T extends CMSContent = CMSPageContent>(collection: string, name: string) => {
    const language = useLanguage()
    return useContent<T>(`collections/${language}/${collection}/${name}/content`)
}

export const useGlobalContent = <T = CMSContent>(path: string) => {
    const language = useLanguage()
    return useContent<T>(`global/${language}/${path}/content`)
}

type EntityType = 'projects' | 'brands' | 'points'
export interface EntityCollectionContent {
    attributes: {
        handle: string
    }
}

export const useEntityContent = <T>(entityType: EntityType, handles?: string[]) => {
    const [entitiesWithContent, setEntitiesWithContent] = useState<{ [handle: string]: T }>({})
    const [loading, setLoading] = useState<UseContentResponse['loading']>(true)
    const [error, setError] = useState<UseContentResponse['error']>(null)

    useEffect(() => {
        if (!handles || !handles.length) return
        const fetchData = async () => {
            setLoading(true)
            const invalidHandles: string[] = []
            const results = (
                await Promise.all(
                    handles.map(async (h) => {
                        try {
                            return [h, await loadContent(`collections/${i18next.language}/${entityType}/${h}/content`)]
                        } catch (err) {
                            invalidHandles.push(h)
                            getAwsRum()?.recordError(
                                new Error(`Unable to load entity content with type "${entityType}" for handle "${h}"`)
                            )
                        }
                    })
                )
            ).filter(Boolean) as [string, T][]

            if (invalidHandles.length) {
                setError({
                    message: `Failed to retrieve ${entityType} content with handles: ${invalidHandles.join(', ')}`
                })
            } else {
                setError(null)
            }

            setEntitiesWithContent(Object.fromEntries(results))
            setLoading(false)
        }
        fetchData()
    }, [handles])
    return { data: entitiesWithContent, loading, error }
}

export const useEntitiesWithContent = <T extends EntityCollectionContent>(entityType: EntityType, entities?: T[]) => {
    const handles = useMemo(() => entities?.map((e) => e.attributes.handle), [entities])
    const { data, loading, error } = useEntityContent<T>(entityType, handles)
    return {
        data: entities?.map((e) => ({ ...e, attributes: { ...e.attributes, ...data[e.attributes.handle] } })) as T[],
        loading,
        error
    }
}
