import { VariantProps, cva } from 'class-variance-authority'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { ElementPrimitive } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

export const inputVariants = cva(
    'focus-ring flex w-full  px-16 py-12 text-night placeholder:font-primary placeholder:text-snow-300 focus:ring-0 focus:ring-offset-0 mobile:max-w-full',
    {
        variants: {
            variant: {
                input: [
                    'rounded-t-sm border border-b-2 border-snow-300 border-b-night-200 bg-snow hover:border-night focus:border-gum focus:border-b-gum-300'
                ],
                text: []
            }
        }
    }
)
export type InputVariantProps = VariantProps<typeof inputVariants>

const Input = forwardRef<
    ElementRef<typeof ElementPrimitive.Base>,
    ComponentPropsWithoutRef<typeof ElementPrimitive.Base> & {
        invalid?: boolean
    } & InputVariantProps
>((props, ref) => {
    const { className, children, invalid, variant = 'input', ...restProps } = props
    return (
        <ElementPrimitive.Base
            ref={ref}
            className={cx(
                inputVariants({ variant }),
                'disabled:cursor-not-allowed disabled:opacity-50',
                { 'border-b-error': invalid },
                className
            )}
            {...restProps}
        >
            {children}
        </ElementPrimitive.Base>
    )
})

export default Input
