import { getLocale } from 'locales'

const provinces_ca = [
    {
        code: 'AB',
        name: {
            en: 'Alberta',
            fr: 'Alberta'
        }
    },
    {
        code: 'BC',
        name: {
            en: 'British Columbia',
            fr: 'Colombie-Britannique'
        }
    },
    {
        code: 'MB',
        name: {
            en: 'Manitoba',
            fr: 'Manitoba'
        }
    },
    {
        code: 'NB',
        name: {
            en: 'New Brunswick',
            fr: 'Nouveau-Brunswick'
        }
    },
    {
        code: 'NL',
        name: {
            en: 'Newfoundland and Labrador',
            fr: 'Terre-Neuve-et-Labrador'
        }
    },
    {
        code: 'NS',
        name: {
            en: 'Nova Scotia',
            fr: 'Nouvelle-Écosse'
        }
    },
    {
        code: 'NT',
        name: {
            en: 'Northwest Territories',
            fr: 'Territoires du Nord-Ouest'
        }
    },
    {
        code: 'NU',
        name: {
            en: 'Nunavut',
            fr: 'Nunavut'
        }
    },
    {
        code: 'ON',
        name: {
            en: 'Ontario',
            fr: 'Ontario'
        }
    },
    {
        code: 'PE',
        name: {
            en: 'Prince Edward Island',
            fr: 'Île-du-Prince-Édouard'
        }
    },
    {
        code: 'QC',
        name: {
            en: 'Quebec',
            fr: 'Québec'
        }
    },
    {
        code: 'SK',
        name: {
            en: 'Saskatchewan',
            fr: 'Saskatchewan'
        }
    },
    {
        code: 'YT',
        name: {
            en: 'Yukon',
            fr: 'Yukon'
        }
    }
]

const provinces_us = [
    {
        code: 'AL',
        name: {
            en: 'Alabama',
            fr: 'Alabama'
        }
    },
    {
        code: 'AK',
        name: {
            en: 'Alaska',
            fr: 'Alaska'
        }
    },
    {
        code: 'AZ',
        name: {
            en: 'Arizona',
            fr: 'Arizona'
        }
    },
    {
        code: 'AR',
        name: {
            en: 'Arkansas',
            fr: 'Arkansas'
        }
    },
    {
        code: 'CA',
        name: {
            en: 'California',
            fr: 'Californie'
        }
    },
    {
        code: 'CO',
        name: {
            en: 'Colorado',
            fr: 'Colorado'
        }
    },
    {
        code: 'CT',
        name: {
            en: 'Connecticut',
            fr: 'Connecticut'
        }
    },
    {
        code: 'DE',
        name: {
            en: 'Delaware',
            fr: 'Delaware'
        }
    },
    {
        code: 'DC',
        name: {
            en: 'District Of Columbia',
            fr: 'District de Columbia'
        }
    },
    {
        code: 'FL',
        name: {
            en: 'Florida',
            fr: 'Floride'
        }
    },
    {
        code: 'GA',
        name: {
            en: 'Georgia',
            fr: 'Géorgie'
        }
    },
    {
        code: 'HI',
        name: {
            en: 'Hawaii',
            fr: 'Hawaï'
        }
    },
    {
        code: 'ID',
        name: {
            en: 'Idaho',
            fr: 'Idaho'
        }
    },
    {
        code: 'IL',
        name: {
            en: 'Illinois',
            fr: 'Illinois'
        }
    },
    {
        code: 'IN',
        name: {
            en: 'Indiana',
            fr: 'Indiana'
        }
    },
    {
        code: 'IA',
        name: {
            en: 'Iowa',
            fr: 'Iowa'
        }
    },
    {
        code: 'KS',
        name: {
            en: 'Kansas',
            fr: 'Kansas'
        }
    },
    {
        code: 'KY',
        name: {
            en: 'Kentucky',
            fr: 'Kentucky'
        }
    },
    {
        code: 'LA',
        name: {
            en: 'Louisiana',
            fr: 'Louisiane'
        }
    },
    {
        code: 'ME',
        name: {
            en: 'Maine',
            fr: 'Maine'
        }
    },
    {
        code: 'MD',
        name: {
            en: 'Maryland',
            fr: 'Maryland'
        }
    },
    {
        code: 'MA',
        name: {
            en: 'Massachusetts',
            fr: 'Massachusetts'
        }
    },
    {
        code: 'MI',
        name: {
            en: 'Michigan',
            fr: 'Michigan'
        }
    },
    {
        code: 'MN',
        name: {
            en: 'Minnesota',
            fr: 'Minnesota'
        }
    },
    {
        code: 'MS',
        name: {
            en: 'Mississippi',
            fr: 'Mississippi'
        }
    },
    {
        code: 'MO',
        name: {
            en: 'Missouri',
            fr: 'Missouri'
        }
    },
    {
        code: 'MT',
        name: {
            en: 'Montana',
            fr: 'Montana'
        }
    },
    {
        code: 'NE',
        name: {
            en: 'Nebraska',
            fr: 'Nebraska'
        }
    },
    {
        code: 'NV',
        name: {
            en: 'Nevada',
            fr: 'Nevada'
        }
    },
    {
        code: 'NH',
        name: {
            en: 'New Hampshire',
            fr: 'New Hampshire'
        }
    },
    {
        code: 'NJ',
        name: {
            en: 'New Jersey',
            fr: 'New Jersey'
        }
    },
    {
        code: 'NM',
        name: {
            en: 'New Mexico',
            fr: 'Nouveau-Mexique'
        }
    },
    {
        code: 'NY',
        name: {
            en: 'New York',
            fr: 'New York'
        }
    },
    {
        code: 'NC',
        name: {
            en: 'North Carolina',
            fr: 'Caroline du Nord'
        }
    },
    {
        code: 'ND',
        name: {
            en: 'North Dakota',
            fr: 'Dakota du Nord'
        }
    },
    {
        code: 'OH',
        name: {
            en: 'Ohio',
            fr: 'Ohio'
        }
    },
    {
        code: 'OK',
        name: {
            en: 'Oklahoma',
            fr: 'Oklahoma'
        }
    },
    {
        code: 'OR',
        name: {
            en: 'Oregon',
            fr: 'Oregon'
        }
    },
    {
        code: 'PA',
        name: {
            en: 'Pennsylvania',
            fr: 'Pennsylvanie'
        }
    },
    {
        code: 'RI',
        name: {
            en: 'Rhode Island',
            fr: 'Rhode Island'
        }
    },
    {
        code: 'SC',
        name: {
            en: 'South Carolina',
            fr: 'Caroline du Sud'
        }
    },
    {
        code: 'SD',
        name: {
            en: 'South Dakota',
            fr: 'Dakota du Sud'
        }
    },
    {
        code: 'TN',
        name: {
            en: 'Tennessee',
            fr: 'Tennessee'
        }
    },
    {
        code: 'TX',
        name: {
            en: 'Texas',
            fr: 'Texas'
        }
    },
    {
        code: 'UT',
        name: {
            en: 'Utah',
            fr: 'Utah'
        }
    },
    {
        code: 'VT',
        name: {
            en: 'Vermont',
            fr: 'Vermont'
        }
    },
    {
        code: 'VA',
        name: {
            en: 'Virginia',
            fr: 'Virginie'
        }
    },
    {
        code: 'WA',
        name: {
            en: 'Washington',
            fr: 'Washington'
        }
    },
    {
        code: 'WV',
        name: {
            en: 'West Virginia',
            fr: 'Virginie-Occidentale'
        }
    },
    {
        code: 'WI',
        name: {
            en: 'Wisconsin',
            fr: 'Wisconsin'
        }
    },
    {
        code: 'WY',
        name: {
            en: 'Wyoming',
            fr: 'Wyoming'
        }
    }
]

export const COUNTRIES_REQUIRING_PROVINCE = ['CA', 'US']

export const getProvinces = (country?: string) => {
    const locale = getLocale()
    switch (country) {
        case 'CA':
            return provinces_ca.map((p) => ({ value: p.code, name: p.name[locale] }))
        case 'US':
            return provinces_us.map((p) => ({ value: p.code, name: p.name[locale] }))
        default:
            return
    }
}
