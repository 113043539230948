import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { ElementPrimitive, Button, Spinner } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

interface FormRootProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Base>,
        ComponentPropsWithoutRef<'form'> {}
const FormRoot = forwardRef<ElementRef<typeof ElementPrimitive.Base>, FormRootProps>((props, ref) => {
    const { className, children, ...restProps } = props
    return (
        <ElementPrimitive.Base ref={ref} className={cx('', className)} {...restProps}>
            <form>{children}</form>
        </ElementPrimitive.Base>
    )
})

interface FormSubmitProps
    extends ComponentPropsWithoutRef<typeof ElementPrimitive.Base>,
        ComponentPropsWithoutRef<typeof Button> {
    loading?: boolean
    loadingText?: string
}

const FormSubmit = forwardRef<ElementRef<typeof ElementPrimitive.Base>, FormSubmitProps>((props, ref) => {
    const { className, loading, loadingText, children, onClick, ...restProps } = props
    return (
        <ElementPrimitive.Base ref={ref} className={cx('', className)} {...restProps}>
            <Button
                type='submit'
                disabled={loading}
                onClick={(e) => {
                    e.preventDefault()
                    if (onClick) {
                        onClick(e)
                    }
                }}
            >
                {loading && <Spinner />}
                {loading && loadingText ? loadingText : children}
            </Button>
        </ElementPrimitive.Base>
    )
})

export { FormRoot as Root, FormSubmit as Submit }
