import { PropsWithChildren } from '../types'
import { DeviceProvider } from './DeviceContext'
import { PopupProvider } from './PopupContext'
import { NavigationProvider } from './NavigationContext'
import { OrgOnboardingProvider } from './OrgOnboardingProvider'
import { ReferringBrandProvider } from './ReferringBrandContext'

export const ContextProvider = (props: PropsWithChildren) => {
    return (
        <DeviceProvider>
            <PopupProvider>
                <NavigationProvider>
                    <OrgOnboardingProvider>
                        <ReferringBrandProvider>{props.children}</ReferringBrandProvider>
                    </OrgOnboardingProvider>
                </NavigationProvider>
            </PopupProvider>
        </DeviceProvider>
    )
}
