import { Locations } from 'Locations'
import CalculatePopup from 'components/popups/OnboardingPopups/CalculatePopup'
import ExperiencePersonalizedPopup from 'components/popups/OnboardingPopups/ExperiencePersonalizedPopup'
import PersonalizeYourExperiencePopup from 'components/popups/OnboardingPopups/PersonalizeYourExperiencePopup'
import { useMatch } from 'react-router-dom'

export default () => {
    const isOnboardingPage = Boolean(useMatch(Locations.Onboarding()))
    const isCalculatorPage = Boolean(useMatch(Locations.Calculate()))

    return (
        <>
            {!isOnboardingPage && <PersonalizeYourExperiencePopup />}
            {!isCalculatorPage && <CalculatePopup />}
            <ExperiencePersonalizedPopup />
        </>
    )
}
