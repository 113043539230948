import { Section, Container, Heading, Image, Text, TextLink, Stat } from 'tusk-ui'
import { CMSImage, CMSLink, CMSSection } from 'content/content.types'

interface CustomerStoryShowcaseProps extends CMSSection {
    stats: {
        value: number
        metric: string
        description: string
    }[]
    moreLink: CMSLink
    logoImage: CMSImage
    image: CMSImage
}

export default function CustomerStoryShowcase({
    heading,
    stats,
    moreLink,
    logoImage,
    image,
    ...sectionProps
}: CustomerStoryShowcaseProps) {
    return (
        <Section scheme='night' {...sectionProps}>
            <Container>
                <div className='grid grid-cols-2 gap-120 mobile:grid-cols-1'>
                    <div className='basis-2/3'>
                        <div className='min-h-[400px] space-y-40'>
                            <Image src={logoImage.src} alt={logoImage.alt} className='w-[140px]' />
                            <Heading.H2 variant='h3' className='mb-24'>
                                {heading}
                            </Heading.H2>
                            <div className='flex gap-40'>
                                {stats.map((stat, index) => (
                                    <div key={index}>
                                        <Stat variant='d3' value={stat.value} metric={stat.metric} />
                                        <Text variant='b4'>{stat.description}</Text>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <TextLink variant='l1' linkVariant='arrow' to={moreLink.href}>
                                    {moreLink.text}
                                </TextLink>
                            </div>
                        </div>
                    </div>
                    <div className='basis-1/3'>
                        <Image className='aspect-1/1 rounded-xl object-cover' src={image.src} alt={image.alt} />
                    </div>
                </div>
            </Container>
        </Section>
    )
}
