import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import Input, { InputVariantProps } from './Input'
import './SelectInput.css'
import { Checkmark, InputError, TriangleDown } from 'tusk-ui/components/Icon'
import * as Radix from '@radix-ui/react-select'
import clsx from 'clsx'
import Text from 'tusk-ui/components/Text/Text'
import { useGlobalContent } from 'mammoth/hooks/useContent'
import { cx } from 'tusk-ui/utils'
import Ripple from 'material-ripple-effects'

export interface SelectInputRootProps
    extends ComponentPropsWithoutRef<typeof Input>,
        ComponentPropsWithoutRef<'select'> {}

const SelectInputRoot = forwardRef<HTMLDivElement, Radix.SelectProps & { className?: string }>(
    ({ className, ...props }, ref) => {
        return (
            <div ref={ref} className={className}>
                <Radix.Root {...props} />
            </div>
        )
    }
)

const SelectInputTrigger = forwardRef<
    HTMLButtonElement,
    Radix.SelectTriggerProps &
        ComponentPropsWithoutRef<typeof Input> & { placeholder?: string; closing?: boolean } & InputVariantProps
>(({ className, invalid, placeholder, closing, variant, ...props }, ref) => {
    const { content } = useGlobalContent<{ placeholder: string }>('select')
    return (
        <Input invalid={invalid} variant={variant}>
            <Radix.Trigger
                ref={ref}
                className={cx(
                    'group relative flex justify-between data-[state=open]:border-gum data-[state=open]:border-b-gum-300',
                    className
                )}
                {...props}
            >
                <div className='overflow-hidden *:truncate'>
                    <Radix.Value
                        placeholder={<Text className='text-snow-300'>{placeholder ?? content?.placeholder}</Text>}
                    />
                </div>
                <Radix.Icon className={cx('ml-8 self-center', !closing && 'group-data-[state=open]:rotate-180')}>
                    <TriangleDown size='xs' />
                </Radix.Icon>
                {invalid && (
                    <div className='absolute right-40 top-0 flex h-full items-center'>
                        <InputError className='w-20' />
                    </div>
                )}
            </Radix.Trigger>
        </Input>
    )
})

const SelectInputDropdown = forwardRef<HTMLDivElement, Radix.SelectContentProps & { closing?: boolean }>(
    ({ className, children, closing, ...props }, ref) => {
        return (
            <Radix.Portal>
                <Radix.Content
                    // Prevent bug on mobile with button under popper firing https://github.com/radix-ui/primitives/issues/1658
                    ref={(ref) => ref?.addEventListener('touchend', (e) => e.preventDefault())}
                    className={cx(
                        'z-[99999] max-h-[320px] w-[var(--radix-select-trigger-width)] min-w-[320px] overflow-auto rounded-xl bg-snow shadow',
                        'opacity-1 transition-opacity duration-200 ease-in-out',
                        closing && 'opacity-0',
                        className
                    )}
                    position='popper'
                    sideOffset={8}
                    {...props}
                >
                    <Radix.Viewport ref={ref} className='py-16'>
                        {children}
                    </Radix.Viewport>
                </Radix.Content>
            </Radix.Portal>
        )
    }
)

const SelectInputOption = forwardRef<HTMLDivElement, Radix.SelectItemProps & React.RefAttributes<HTMLDivElement>>(
    ({ children, value, ...props }, ref) => {
        const ripple = new Ripple()

        return (
            <Radix.Item
                {...props}
                value={value}
                ref={ref}
                className='group relative flex justify-between overflow-hidden px-16 py-12 outline-none hover:bg-snow-100 focus:bg-snow-100 active:bg-snow-100 data-[state=checked]:bg-snow-100'
                onMouseDown={(e) => ripple.create(e, 'dark')}
            >
                <Radix.ItemText>
                    <Text
                        className={clsx(
                            props.disabled && 'text-night-100',
                            'overflow-hidden text-ellipsis whitespace-inherit'
                        )}
                    >
                        {children}
                    </Text>
                </Radix.ItemText>
                <Radix.ItemIndicator>
                    <Checkmark />
                </Radix.ItemIndicator>
            </Radix.Item>
        )
    }
)

export {
    SelectInputRoot as Root,
    SelectInputTrigger as Trigger,
    SelectInputDropdown as Dropdown,
    SelectInputOption as Option
}
