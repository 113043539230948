import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { ColorScheme, Container, ElementPrimitive, Icon } from 'tusk-ui'
import { cx } from 'tusk-ui/utils'

type BannerRootProps = ComponentPropsWithoutRef<typeof ColorScheme>

const BannerRoot = forwardRef<ElementRef<typeof ElementPrimitive.Div>, BannerRootProps>((props, ref) => {
    const { className, scheme = 'night', id, children } = props
    return (
        <ColorScheme ref={ref} id={id} scheme={scheme} asChild>
            <header role='banner'>
                <Container width='lg' className={cx('flex h-full', className)}>
                    {children}
                </Container>
            </header>
        </ColorScheme>
    )
})

type BannerContentProps = ComponentPropsWithoutRef<typeof ElementPrimitive.Div>

const BannerContent = forwardRef<ElementRef<typeof ElementPrimitive.Div>, BannerContentProps>((props, ref) => {
    const { className, ...restProps } = props
    return <ElementPrimitive.Div ref={ref} className={cx('', className)} {...restProps} />
})

type BannerCloseProps = ComponentPropsWithoutRef<typeof ElementPrimitive.Button>

const BannerClose = forwardRef<ElementRef<typeof ElementPrimitive.Button>, BannerCloseProps>((props, ref) => {
    const { className, ...restProps } = props
    return (
        <ElementPrimitive.Button ref={ref} className={cx('group', className)} aria-label='Close banner' {...restProps}>
            <Icon.Close size='sm' className='text-snow' />
        </ElementPrimitive.Button>
    )
})

type BannerBadgeProps = ComponentPropsWithoutRef<typeof ElementPrimitive.Div>

const BannerBadge = forwardRef<ElementRef<typeof ElementPrimitive.Div>, BannerBadgeProps>((props, ref) => {
    const { className, children, ...restProps } = props
    return (
        <ElementPrimitive.Div ref={ref} className={cx('', className)} {...restProps}>
            {children}
        </ElementPrimitive.Div>
    )
})

export { BannerRoot as Root, BannerContent as Content, BannerClose as Close, BannerBadge as Badge }
