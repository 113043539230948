import { gql } from '@apollo/client'

const IMAGE_FRAGMENT = gql`
    fragment ImageFields on UploadFileEntityResponse {
        data {
            attributes {
                url
                alternativeText
            }
        }
    }
`
export const IMAGE_COLLECTION_FRAGMENT = gql`
    fragment ImageCollectionFields on UploadFileRelationResponseCollection {
        data {
            attributes {
                url
                alternativeText
            }
        }
    }
`
export default IMAGE_FRAGMENT
